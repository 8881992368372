import React from 'react'

const styles = {
  modalDimmer: {
    zIndex: 5,
    position: 'fixed',
    height: '100%',
    width: '100%',
    background: 'rgba(0, 0, 0, 0.3)',
    top: 0,
    left: 0,
  },
}

const ModalDimmer = ({ close, style }) => (
  <div style={{ ...styles.modalDimmer, ...style }} onClick={close} />
)

export default ModalDimmer
