import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Stars from '../Stars'
import PriceTag from './components/PriceTag'
import AddTag from './components/AddTag'
import OnAuctionTag from './components/OnAuctionTag'
import FighterText from './components/FighterText'
import FighterStats from './components/FighterStats'
import CooldownMenu from '../cooldowns/CooldownMenu'
import { generationToStars, generationToRank, getLevel } from '../../utils/fighter'
import { styles, stylesXs } from './styles'

class FighterCard extends Component {
  getStars() {
    return generationToStars(this.props.generation)
  }

  getContent() {
    const {
      displayPrice,
      color = {},
      timeTillBattleCooldown,
      timeTillPrizeCooldown,
      image,
      strength,
      vitality,
      dexterity,
      luck,
      showStats,
      starSize,
      autoHeight,
      cardSizeXs,
      addAction,
      showPrice,
      experience,
      onAuction,
    } = this.props

    const CARD = autoHeight ? { ...styles.heightAuto } : { ...styles.minHeight }
    const STYLES = cardSizeXs ? { ...stylesXs } : { ...styles }

    const { level } = getLevel(experience)

    return (
      <div
        style={{
          ...CARD,
          ...STYLES.card,
          backgroundColor: color.secondary,
        }}
      >
        <div style={{ opacity: onAuction && addAction ? 0.4 : 1 }}>
          <div style={STYLES.starContainer}>
            <Stars amount={this.getStars()} color={color.star} filled={2} size={starSize || 13} />
          </div>
          <img
            alt="⚔️"
            style={STYLES.fighterImg}
            src={image}
            ref={img => (this.img = img)}
            onError={() => (this.img.src = require('../../images/question-mark.png'))}
          />
          <CooldownMenu
            color={color.star}
            timeTillBattleCooldown={timeTillBattleCooldown}
            timeTillPrizeCooldown={timeTillPrizeCooldown}
          />
          {showStats && (
            <FighterStats
              colors={[color.star, color.primary]}
              stats={[
                { symbol: 'STR', amount: strength },
                { symbol: 'VIT', amount: vitality },
                { symbol: 'DEX', amount: dexterity },
                { symbol: 'LUK', amount: luck },
              ]}
            />
          )}
          {showStats && (
            <FighterStats
              colors={[color.star, color.primary]}
              right
              stats={[{ symbol: 'LVL', amount: level }]}
            />
          )}
          {showPrice && (
            <div>
              {!addAction && (
                <span>
                  {displayPrice ? <PriceTag color={color.star} price={displayPrice} /> : null}
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }

  render() {
    const {
      fighterId,
      experience,
      generation,
      color = {},
      disabled,
      fadeInDelay,
      noAnimation,
      cardSizeXs,
      showGenLevel,
      className,
      addAction,
      onClickAction,
      onAuction,
      enableLink,
      // showPrice,
    } = this.props

    const STYLES = cardSizeXs ? { ...stylesXs } : { ...styles }
    const { level } = getLevel(experience)
    const cardClass = cardSizeXs ? 'card-xs' : 'card-default'
    const animationClass = noAnimation ? '' : 'fadeInUp'

    return (
      <div
        className={`${className} ${animationClass}`}
        style={{
          display: 'flex',
          justifyContent: 'center',
          animationDelay: fadeInDelay + 's',
        }}
      >
        <div
          className={cardClass}
          style={{ ...STYLES.cardContainer, cursor: addAction ? 'pointer' : null }}
          onClick={addAction && !onAuction ? () => onClickAction(fighterId) : null}
        >
          {!enableLink && (disabled || addAction) ? (
            this.getContent()
          ) : (
            <Link to={`/fighter/${fighterId}`} style={styles.link}>
              {this.getContent()}
            </Link>
          )}

          {addAction && !onAuction && <AddTag color={color.star} />}
          {onAuction && addAction && <OnAuctionTag color={color.star} />}

          <FighterText
            name={`Fighter #${fighterId}`}
            rank={generationToRank(generation)}
            generation={generation}
            level={level}
            showGenLevel={showGenLevel}
          />
        </div>
      </div>
    )
  }
}

FighterCard.propTypes = {
  fighterId: PropTypes.number,
  displayPrice: PropTypes.string,
  experience: PropTypes.number,
  generation: PropTypes.number.isRequired,
  color: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  image: PropTypes.string.isRequired,
  strength: PropTypes.number.isRequired,
  vitality: PropTypes.number.isRequired,
  dexterity: PropTypes.number.isRequired,
  luck: PropTypes.number.isRequired,
  fadeInDelay: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  noAnimation: PropTypes.bool,
  showStats: PropTypes.bool,
  starSize: PropTypes.number,
  autoHeight: PropTypes.bool,
  cardSizeXs: PropTypes.bool,
  showGenLevel: PropTypes.bool,
  className: PropTypes.string,
  showPrice: PropTypes.bool,
}

FighterCard.defaultProps = {
  fighterId: null,
  fadeInDelay: null,
  showStats: true,
  className: '',
  noAnimation: false,
  showPrice: true,
}

export default FighterCard
