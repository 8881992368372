import React from 'react'
import { AuctionFormInput } from './'

const AuctionForm = ({
  startPrice,
  onChangeStartPrice,
  endPrice,
  onChangeEndPrice,
  duration,
  onChangeDuration,
}) => (
  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
    <AuctionFormInput label="Duration" value={duration} onChange={onChangeDuration} placeholder="hour(s)" />
    <AuctionFormInput label="Start Price" value={startPrice} onChange={onChangeStartPrice} placeholder="ETH" />
    <AuctionFormInput label="End Price" value={endPrice} onChange={onChangeEndPrice} placeholder="ETH" />
  </div>
)

export default AuctionForm
