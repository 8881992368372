import React from 'react'
import FighterSubtitle from './FighterSubtitle'
import PropTypes from 'prop-types'
import Progress from '../Progress'

const styles = {
  text: {
    display: 'inline-block',
    margin: 0,
    fontSize: 13,
  },
}

const Stat = ({ stat, amount, color }) => (
  <div className="noselect stat-row">
    <p className="stat-label" style={{ ...styles.text, float: 'left' }}>
      {stat}
    </p>
    <p className="stat-label" style={{ ...styles.text, float: 'right', marginBottom: 1 }}>
      {amount}
    </p>
    <Progress color={color} progress={amount} maxAmount={30} />
  </div>
)

const Stats = ({ stats, color, statsClass, statsLabelText }) => (
  <div style={{ width: '100%' }} className={`fadeInRight ${statsClass}`}>
    <div className="text-center">
      <FighterSubtitle color={color}>{statsLabelText}</FighterSubtitle>
    </div>
    {stats.map(props => <Stat {...props} color={color} key={props.stat} />)}
  </div>
)

Stat.propTypes = {
  stat: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  statsLabelText: PropTypes.string,
}

Stats.propTypes = {
  stats: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired,
  statsClass: PropTypes.string,
}
Stats.defaultProps = {
  statsClass: '',
  statsLabelText: 'STATS',
}

export default Stats
