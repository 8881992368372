import React, { Component } from 'react'
// import CaptainCooldown from '../../components/cooldowns/CaptainCooldown'
// import ExhaustedCooldown from '../../components/cooldowns/ExhaustedCooldown'
// import PrizeCooldown from '../../components/cooldowns/PrizeCooldown'

class ErrorPage extends Component {
  render() {
    return (
      <div className="container">
        <h1>404</h1>
        <p>Page not found.</p>
      </div>
    )
  }
}

export default ErrorPage
