import React from 'react'
import PropTypes from 'prop-types'
import './style.css'

const styles = {
  container: {
    background: '#eee',
    borderRadius: 20,
    width: '100%',
    height: 4,
    overflow: 'hidden',
  },
}

const Progress = ({ color, progress, maxAmount }) => {
  let multiplyer
  if (maxAmount) {
    multiplyer = 100 / maxAmount
  }
  return (
    <div className="propgress-line" style={styles.container}>
      <div
        style={{
          height: '100%',
          width: maxAmount ? multiplyer * progress + '%' : progress + '%',
          background: color,
        }}
      />
    </div>
  )
}

Progress.propTypes = {
  progress: PropTypes.number,
  exp: PropTypes.number,
  maxAmount: PropTypes.number,
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

Progress.defaultProps = {
  progress: 0,
  maxAmount: 0,
  exp: 0,
  color: null,
}

export default Progress
