import { tabs as tabsTypes } from '../actions/types'

const INIT_TABS_STATE = {
  activeTabId: {
    id: 0,
  },
}

const actions = {
  [tabsTypes.setActiveTabId]: (state, payload) => ({
    ...state,
    activeTabId: payload.id,
  }),
}

const tabs = (state = INIT_TABS_STATE, action) => {
  if (actions[action.type]) {
    return actions[action.type](state, action.payload)
  }
  return state
}

export default tabs
