import React from 'react'
import AddIcon from './AddIcon'

const styles = {
  addTag: {
    padding: '3px 10px',
    width: '50%',
    borderRadius: 5,
    position: 'absolute',
    bottom: 56,
    left: '50%',
    transform: 'translateX(-50%)',
    background: '#fff',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    cursor: 'pointer',
  },
  text: {
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 3,
    marginTop: 3,
    fontSize: 14,
    fontWeight: '500',
    color: '#757575',
    width: '80%',
    userSelect: 'none',
  },
}

const AddTag = ({ onClick, color }) => (
  <div style={styles.addTag} onClick={onClick}>
    <AddIcon color={color} />
    <span style={{ ...styles.text, color: color }}>ADD</span>
  </div>
)

export default AddTag
