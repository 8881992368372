import React from 'react'

const styles = {
  container: {
    margin: '60px 0px',
    textAlign: 'center',
  },
  document: {
    display: 'inline-block',
    width: '70%',
  },
  title: {
    margin: 0,
    color: '#030f23',
  },
  date: {
    color: '#9B9B9B',
    fontSize: '14px',
  },
  seperator: {
    display: 'inline-block',
    height: '2px',
    width: '40px',
    background: '#2196f3',
  },
}

const Document = ({ title, date, children }) => (
  <div style={styles.container}>
    <div style={styles.document}>
      <h1 style={styles.title}>{title}</h1>
      <div style={styles.seperator} />
      <div style={{ textAlign: 'left', color: '#041633' }}>
        {date && <p style={styles.date}>Last Updated: {date}</p>}
        {children}
      </div>
    </div>
  </div>
)

export default Document
