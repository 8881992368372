const cooldownList = [
  {
    id: 0,
    title: '1 minute',
    amount: 60000,
  },
  {
    id: 1,
    title: '30 minutes',
    amount: 1800000,
  },
  {
    id: 2,
    title: '2 hours',
    amount: 7200000,
  },
  {
    id: 3,
    title: '6 hours',
    amount: 21600000,
  },
  {
    id: 4,
    title: '12 hours',
    amount: 43200000,
  },
  {
    id: 5,
    title: '1 day',
    amount: 86400000,
  },
  {
    id: 6,
    title: '3 days',
    amount: 259200000,
  },
]

export default cooldownList
