const levels = [
  0,
  100,
  300,
  600,
  1000,
  1500,
  2100,
  2800,
  3600,
  4500,
  5500,
  6600,
  7800,
  9100,
  10500,
  12000,
  13600,
  15300,
  17100,
  19000,
  21000,
  23100,
  25300,
  27600,
  30000,
  32500,
  35100,
  37800,
  40600,
  43500,
  46500,
  49600,
  52800,
  56100,
  59500,
  63000,
  66600,
  70300,
  74100,
  78000,
  82000,
  86100,
  90300,
  94600,
  99000,
  103500,
  108100,
  112800,
  117600,
  122500,
  127500,
  132600,
  137800,
  143100,
  148500,
  154000,
  159600,
  165300,
  171100,
  177000,
  183000,
  189100,
  195300,
  201600,
  208000,
  214500,
  221100,
  227800,
  234600,
  241500,
  248500,
  255600,
  262800,
  270100,
  277500,
  285000,
  292600,
  300300,
  308100,
  316000,
  324000,
  332100,
  340300,
  348600,
  357000,
  365500,
  374100,
  382800,
  391600,
  400500,
  409500,
  418600,
  427800,
  437100,
  446500,
  456000,
  465600,
  475300,
  485100,
  495000,
]

const generationToStars = generation => {
  if (generation === 0) return 5
  if (generation >= 1 && generation <= 2) return 4
  if (generation >= 3 && generation <= 8) return 3
  if (generation >= 9 && generation <= 15) return 2
  return 1
}

// 0 - General
// 1 - 1st Colonel
// 2 - 2nd Colonel
// 3 - 1st Major
// 4 - 2nd Major
// 5 - 3rd Major
// 6 - 4th Major
// 7 - 5th Major
// 8 - 6th Major
// 9 - 1st Captain
// 15 - 7th Captain
// 16 - Sergent(1)
// 17 - Sergent(2)
// 18 - ...

const ranks = [
  'General',
  '1st Colonel',
  '2nd Colonel',
  '1st Major',
  '2nd Major',
  '3rd Major',
  '4th Major',
  '5th Major',
  '6th Major',
  '1st Captain',
  '2nd Captain',
  '3rd Captain',
  '4th Captain',
  '5th Captain',
  '6th Captain',
  '7th Captain',
  '1st Sergeant',
  '2nd Sergeant',
  '3rd Sergeant',
  '4th Sergeant',
  '5th Sergeant',
  '6th Sergeant',
  '7th Sergeant',
  '8th Sergeant',
  '9th Sergeant',
  '10th Sergeant',
]

const generationToRank = generation => {
  return (ranks[generation] || '10th Sergeant') + ` (Gen ${generation})`
}

// const generationToRank = generation => {
//   if (generation === 0) return 'General'
//   if (generation >= 1 && generation <= 2) return 'Colonel'
//   if (generation >= 3 && generation <= 8) return 'Major'
//   if (generation >= 9 && generation <= 15) return 'Captain'
//   return 'Sergeant'
// }

const getLevel = exp => {
  let level, maxExp, minExp
  if (exp === 0) {
    return { level: 1, maxExp: levels[1], minExp: 0 }
  } else {
    levels.some((l, i) => {
      level = i
      if (exp > l) {
        minExp = l
        return false
      }
      maxExp = l
      return true
    })
    return { level, maxExp, minExp }
  }
}

const genesToRace = genes => {
  const len = genes.length

  // father race gene:
  const raceString = genes.substring(len - 6, len - 4)

  return parseInt(raceString, 10)
}

const getTimeTillCooldown = (coolDownBlock, currentBlock) => (coolDownBlock - currentBlock) * 15000

const isOwner = (fighter, currentUser) => {
  return fighter.owner && currentUser && fighter.owner.toLowerCase() === currentUser.toLowerCase()
}

// intentionally not using export format here so it's easier to use in tests
module.exports = {
  generationToStars,
  generationToRank,
  getLevel,
  genesToRace,
  getTimeTillCooldown,
  isOwner,
}
