import React from 'react'

const styles = {
  container: {
    width: '100%',
    paddingTop: 32,
    paddingBottom: 32,
    paddingLeft: 40,
    paddingRight: 40,
    borderRadius: 8,
    backgroundColor: '#fff',
  },
  fighter: {
    position: 'absolute',
    top: '-60px',
    left: '-30px',
    zIndex: -1,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#041633',
  },
  titlePrefix: {
    color: '#2196f3',
  },
  body: {
    fontSize: 16,
    lineHeight: 1.5,
    color: '#030f23',
    paddingBottom: 16,
  },
}

const FAQContainer = ({ xs, content, id }) => (
  <div>
    <div style={{ ...styles.container, border: xs ? null : 'solid 1px #eeeeee' }}>
      {content.map(({ title, body }, i) => (
        <div key={title} id={`faq-${id}-${i}`}>
          <div style={styles.title}>
            <span style={styles.titlePrefix}>Q. </span>
            {title}
          </div>
          <p style={styles.body}>{body}</p>
        </div>
      ))}
    </div>
    <img
      alt="faq"
      style={{
        ...styles.fighter,
        display: xs ? 'none' : 'block',
      }}
      src={require('./images/faq-fighter.svg')}
    />
  </div>
)

export default FAQContainer
