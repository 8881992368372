import { combineReducers } from 'redux'
import app from './app'
import fights from './fights'
import market from './market'
import tabs from './tabs'

const reducer = combineReducers({
  app,
  fights,
  market,
  tabs,
})

export default reducer
