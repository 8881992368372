import React from 'react'
import PropTypes from 'prop-types'
import './style.css'

const BattleStat = ({ label, value, marginTop, symbolAfter }) => (
  <div className="battle-stat">
    <div className="battle-stat-label" style={{ marginTop: marginTop }}>
      {label}
    </div>
    <div className="battle-stat-value">{`${value}${symbolAfter}`}</div>
  </div>
)

BattleStat.propTpes = {
  label: PropTypes.string,
  value: PropTypes.string,
  clear: PropTypes.number.isRequired,
  symbolAfter: PropTypes.number,
}

BattleStat.defaultProps = {
  label: 'Default label',
  value: '0',
  symbolAfter: '',
}

export default BattleStat
