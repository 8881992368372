import React from 'react'
import { Row, Col } from 'react-bootstrap'
import TeamsList from '../../components/TeamsList'
import RefreshIcon from '../../components/Header/components/Icons/RefreshIcon'
import BounceLoader from '../../components/loaders/BounceLoader'
import Pager from '../../components/Pager'
import './styles.css'

const styles = {
  header: {
    fontSize: 16,
    fontWeight: '500',
    textAlign: 'left',
    color: '#030f23',
    userSelect: 'none',
  },
}

const TeamsSection = ({
  title,
  recievedTeams,
  defaultLoad,
  loadingTeams,
  teams,
  onRefresh,
  handleFightClick,
  leaveArena,
  totalTeamsFound,
  onChangePage,
  pages,
  activePage,
  collapsible,
  open,
  toggleCollapsible,
  emptyMessage,
}) =>
  recievedTeams ? (
    <div>
      <Row style={{ marginTop: 32 }}>
        <Col sm={12}>
          <div className="flex justify-between">
            <span
              onClick={toggleCollapsible}
              style={{
                ...styles.header,
                cursor: collapsible ? 'pointer' : '',
              }}
            >
              {title}
            </span>
            <div>
              {collapsible &&
                open && (
                  <button className="btn btn-clear btn-xs btn-muted" onClick={toggleCollapsible}>
                    <div className="flex items-center justify-center">
                      <span className="btn-text icon-left">Collapse</span>
                    </div>
                  </button>
                )}
              {collapsible &&
                !open && (
                  <button className="btn btn-clear btn-xs btn-muted" onClick={toggleCollapsible}>
                    <div className="flex items-center justify-center">
                      <span className="btn-text icon-left">Expand</span>
                    </div>
                  </button>
                )}
              {!collapsible || (collapsible && open) ? (
                <button className="btn btn-clear btn-xs btn-muted" onClick={onRefresh}>
                  <div className="flex items-center justify-center">
                    <RefreshIcon color={'#d2d2d2'} width={12} height={12} />
                    <span className="btn-text icon-left">Refresh</span>
                  </div>
                </button>
              ) : null}
            </div>
          </div>
          <div className={`${collapsible ? 'collapsible' : ''} ${open ? '' : 'closed'}`}>
            {!loadingTeams ? (
              <div>
                <TeamsList
                  teams={teams}
                  onClickFight={handleFightClick}
                  removeTeam={leaveArena}
                  emptyMessage={emptyMessage}
                />
                {totalTeamsFound && pages ? (
                  <Row style={{ marginTop: 35 }}>
                    <Pager pages={pages} activePage={activePage} onChangePage={onChangePage} />
                  </Row>
                ) : null}
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 20,
                  marginBottom: 18,
                }}
              >
                <BounceLoader size={60} />
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  ) : (
    <div>
      {defaultLoad ? (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}>
          <BounceLoader size={60} />
        </div>
      ) : null}
    </div>
  )

export default TeamsSection
