import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Chart from 'chart.js'

class BuyChart extends PureComponent {
  componentDidMount() {
    const {
      backgroundColor,
      secondaryColor,
      startingPrice,
      endingPrice,
      currentPrice,
      startedAt,
      endAt,
      currentTime,
    } = this.props

    const ctx = document.getElementById('buyChart').getContext('2d')

    const max = Math.max(startingPrice, endingPrice) * 1.2

    new Chart(ctx, {
      type: 'line',
      data: {
        // labels: [`Start ${startedAt}`, currentTime.toString(), `End ${endAt}`],
        datasets: [
          {
            // label: 'x',
            data: [
              { x: startedAt, y: startingPrice },
              {
                x: currentPrice.toString() === endingPrice.toString() ? endAt : currentTime,
                y: currentPrice,
              },
              { x: endAt, y: endingPrice },
            ],
            backgroundColor: [secondaryColor],
            borderColor: [backgroundColor],
            borderWidth: 1,
          },
        ],
      },
      options: {
        elements: {
          line: {
            tension: 0,
          },
        },
        legend: {
          display: false,
        },
        bezierCurve: false,
        tooltips: {
          enabled: false,
        },
        scales: {
          xAxes: [
            {
              type: 'linear',
              // position: 'bottom',
              display: false,
              gridLines: {
                display: true,
              },
            },
          ],
          yAxes: [
            {
              display: false,
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
                max,
              },
            },
          ],
        },
      },
    })
  }

  render() {
    const { width, height } = this.props

    return (
      <div>
        <canvas id="buyChart" width={width} height={height} />
      </div>
    )
  }
}

BuyChart.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  secondaryColor: PropTypes.string.isRequired,
  startingPrice: PropTypes.number.isRequired,
  endingPrice: PropTypes.number.isRequired,
  currentPrice: PropTypes.object.isRequired,
  startedAt: PropTypes.number.isRequired,
  endAt: PropTypes.number.isRequired,
  currentTime: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}

BuyChart.defaultProps = {
  width: 400,
  height: 120,
}

export default BuyChart
