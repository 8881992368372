import _ from 'lodash'

// notes
// window.dataLayer is for google
// fbq is for facebook

const CURRENCY = 'USD'

const toUSD = amount => {
  const valueUSD = (amount / Math.pow(10, 15)).toString()

  return valueUSD
}

export const trackClickPurchase = (fighterId, amount) => {
  const usdAmount = toUSD(amount)

  const options = {
    // converting from wei to eth
    value: usdAmount,
    currency: CURRENCY, // we get errors if we do ETH
    content_ids: [fighterId],
  }

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    ...options,
    event: 'clickPurchase',
  })

  window.fbq && window.fbq('track', 'AddToCart', options)
}

export const trackPurchase = ({ fighterId, amount, web3Receipt }) => {
  try {
    const value = toUSD(amount)

    const transactionHash = web3Receipt.transactionHash
    const userId = web3Receipt.from
    const sellerId = _.get(web3Receipt, ['logs', 1, 'address'])

    // for fb (this is the structure they use)
    const options = {
      // converting from wei to eth
      value,
      currency: CURRENCY, // we get errors if we do ETH
      content_ids: [fighterId],
    }

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      ...options,
      event: 'purchase',
      conversionValue: value,
      fighterId,

      transactionId: transactionHash,
      // 'transactionAffiliation': 'OPTIONAL',
      transactionTotal: value,
      transactionTax: 0,
      transactionShipping: 0,
      transactionProducts: [
        {
          sku: fighterId,
          name: `Fighter ${fighterId}`,
          category: 'Fighter',
          price: value,
          quantity: 1,
        },
      ],
      userId,
      sellerId,
    })

    window.fbq && window.fbq('track', 'Purchase', options)
  } catch (e) {
    console.error('ERROR:', e)
  }
}

export const trackCancelAuction = fighterId => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'cancelAuction',
    fighterId,
  })
}

// userId is address
export const trackUser = userId => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'userIdSet',
    userId,
  })

  window.fbq &&
    window.fbq('track', 'Lead', {
      userId,
    })
}
