import React from 'react'
import PropTypes from 'prop-types'

const Hider = ({ hide, children, className }) => (
  <div
    className={`tootip-wrapper ${className}`}
    style={{ display: hide ? 'none' : 'block', width: '100%', height: '100%' }}
  >
    {children}
  </div>
)

Hider.propTypes = {
  className: PropTypes.string,
}

Hider.defaultProps = {
  className: '',
}

export default Hider
