import React, { Component } from 'react'
import getWeb3 from '../../utils/getWeb3'

const styles = {
  container: {
    height: 600,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingLeft: 30,
    paddingRight: 30,
  },
  circle: {
    width: 160,
    height: 160,
    borderRadius: 100,
    backgroundColor: '#f7f9e0',
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    fontSize: 22,
    fontWeight: 'bold',
    color: '#030f23',
    marginTop: 20,
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 16,
    color: '#757575',
    paddingTop: 20,
    paddingBottom: 20,
  },
  image: {
    maxWidth: 280,
    marginBottom: 25,
  },
  // image: {
  //   width: 120,
  //   height: 120,
  //   objectFit: 'contain',
  // },
  download: {
    textDecoration: 'none',
    fontSize: 16,
  },
}

class LockedPage extends Component {
  componentDidMount() {
    this.timer = setInterval(this.checkForUnlock, 300)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  checkForUnlock = () =>
    getWeb3()
      .then(
        web3 =>
          new Promise((resolve, reject) =>
            web3.eth.getAccounts((error, accounts) => (error ? reject(error) : resolve(accounts)))
          )
      )
      .then(accounts => {
        if (accounts[0]) {
          const from = this.props.location.state.from || '/market'
          this.props.history.push(from)
        }
      })

  render() {
    return (
      <div style={styles.container}>
        <a
          style={styles.download}
          href="https://metamask.io"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img style={styles.image} src="/mm-logo.svg" alt="metamask" />
        </a>

        <a
          style={styles.download}
          href="https://trustwalletapp.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img style={styles.image} src="/download-trust-wallet.png" alt="trust-wallet" />
        </a>

        <div style={styles.title}>This page needs MetaMask or Trust Wallet to run.</div>
        <div style={styles.subtitle}>
          Please install MetaMask or Trust Wallet to access this page.
          <br />
          If you already have MetaMask installed try unlocking it.
        </div>
      </div>
    )
  }
}

export default LockedPage
