import React from 'react'
import PropTypes from 'prop-types'

const GiftIcon = ({ color, height, width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 486.392 486.392"
  >
    <g>
      <g>
        <path
          fill={color}
          d="M458.957,108.661c4.242-9.672,6.491-20.197,6.491-31.071c0-20.748-8.111-40.247-22.831-54.9 C427.928,8.057,408.403,0,387.636,0s-40.293,8.057-54.983,22.687l-53.38,53.163l-23.271,23.176l-23.274-23.181l-53.374-53.16 C164.657,8.055,145.13,0,124.368,0c-20.762,0-40.29,8.055-54.986,22.684C54.659,37.342,46.551,56.841,46.551,77.589 c0,10.877,2.248,21.401,6.49,31.071c-12.26,0.664-22.005,10.784-22.005,23.208V217.2h23.272h23.272h155.147v-62.059H256h23.272 V217.2h155.147h23.272h23.272v-85.331C480.964,119.445,471.219,109.323,458.957,108.661z M125.071,108.597h-0.828 c-8.338-0.025-16.154-3.246-22.02-9.089c-5.885-5.858-9.126-13.644-9.126-21.919c0-8.276,3.239-16.061,9.124-21.918 c5.913-5.886,13.777-9.127,22.147-9.127c8.372,0,16.238,3.243,22.146,9.123l53.143,52.93H125.071z M409.776,99.511 c-5.863,5.841-13.679,9.062-22.017,9.085h-0.828h-74.578l53.146-52.928c5.908-5.885,13.769-9.124,22.14-9.124 c8.369,0,16.23,3.239,22.143,9.127c5.886,5.858,9.127,13.642,9.127,21.918C418.908,85.865,415.667,93.65,409.776,99.511z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          fill={color}
          d="M426.662,263.744H312.183l0.02,0.02l37.826,37.826c9.089,9.089,9.089,23.821,0,32.911 c-4.544,4.543-10.5,6.816-16.456,6.816s-11.912-2.273-16.455-6.817l-37.847-37.845v168.8v23.272V512h147.39 c12.852,0,23.272-10.42,23.272-23.272V263.744H426.662z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          fill={color}
          d="M194.881,334.501c-4.544,4.544-10.5,6.817-16.455,6.817c-5.956,0-11.912-2.273-16.455-6.817 c-9.089-9.089-9.089-23.821,0-32.911l37.826-37.825l0.02-0.02H85.338H62.066v224.984c0,12.852,10.42,23.272,23.272,23.272h147.39 v-23.272v-23.272v-168.8L194.881,334.501z"
        />
      </g>
    </g>
  </svg>
)

GiftIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
}

GiftIcon.defaultProps = {
  width: 15,
  height: 15,
  color: '#000',
}

export default GiftIcon
