import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import HeaderLogo from '../../images/img-logo-footer.svg'

const styles = {
  linkContent: {
    fontSize: 14,
    fontWeight: 500,
    color: '#9b9b9b',
    cursor: 'pointer',
  },
  link: {
    textDecoration: 'none',
    display: 'inline-block',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    height: 120,
  },
  xsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 80,
  },
}

const LandingHeader = () => (
  <Row>
    <Col sm={10} smOffset={1} xsHidden style={styles.container}>
      <img style={{ height: 72 }} src={HeaderLogo} alt="CryptoFighters" />
      <div style={{ position: 'absolute', right: 0 }}>
        <Link to="/my-fighters" style={styles.link}>
          <div style={{ ...styles.linkContent, paddingRight: 48 }}>MY FIGHTERS</div>
        </Link>
        <Link to="/market" style={styles.link}>
          <div style={styles.linkContent}>MARKETPLACE</div>
        </Link>
      </div>
    </Col>

    <Col xs={10} xsOffset={1} smHidden mdHidden lgHidden style={styles.xsContainer}>
      <img style={{ height: 72 }} src={HeaderLogo} alt="CryptoFighters" />
    </Col>
  </Row>
)

export default LandingHeader
