import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, Row, Col } from 'react-bootstrap'
import _ from 'lodash'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import {
  addFighterToTeam,
  removeFighterFromTeam,
  setTeam,
  setTeams,
  enterTeamInArena,
  removeTeamFromArena,
  removingTeamFromArena,
  setArenaPage,
  resetTempTeam,
  setActiveTabId,
} from '../../actions'
import api from '../../utils/api'
import getWeb3 from '../../utils/getWeb3'
import { /*createTeam, battle */ deleteTeam } from '../../utils/web3Battles'
import { getTimeTillCooldown } from '../../utils/fighter'
import { FIGHTER_COLORS } from '../../consts'
import MainAction from '../../components/buttons/MainAction'
import BattleStats from '../../components/BattleStats'
import FighterTeam from '../../components/FighterTeam'
import FighterTeamModal from '../../components/FighterTeamModal'
import TeamsSection from '../../components/TeamsSection'

import Tabs from '../../components/Tabs'
import tabsHeader from '../commonTabs'
import Banner from '../../components/Banner'

const WAIT_FOR_NEXT_REFRESH = 5000 // 5 seconds

const styles = {
  header: {
    fontSize: 16,
    fontWeight: '500',
    textAlign: 'left',
    color: '#030f23',
  },
  headerArena: {
    textAlign: 'center',
    paddingTop: 2,
    fontSize: 24,
    fontWeight: 'bold',
    color: '#030f23',
  },
  headerDivider: {
    width: 40,
    height: 2,
    backgroundColor: '#2196f3',
    marginTop: 16,
  },
}

class Arena extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: null,
      stats: {
        wins: 0,
        losses: 0,
        winRate: 0,
      },
      myFighters: [],
      teamModalOpen: false,
      recievedFighters: false,
      recievedMyTeams: false,
      recievedOpponents: false,
      enteringArena: false,
      submitIsFight: false,
      teamsInArena: null,
      totalTeamsFound: null,
      loadingTeams: false,
      loadingMyTeams: false,
      nextMyTeamsRefresh: 0,
      nextOpponentsRefresh: 0,
      myTeamsCollapsed: false,
      fightingTeam: {},
    }
  }

  componentDidMount() {
    getWeb3()
      .then(
        web3 =>
          new Promise((resolve, reject) =>
            web3.eth.getAccounts((error, accounts) =>
              error
                ? reject(error)
                : web3.eth.getBlockNumber((error, blockNumber) =>
                    error ? reject(error) : resolve({ user: accounts[0], blockNumber })
                  )
            )
          )
      )
      .then(({ user, blockNumber }) =>
        user == null ? this.redirectToLocked() : this.setState({ user, blockNumber })
      )
      .then(() => {
        this.getBattleStats()
        this.getBattleHistory()
        this.getMyFighters().then(this.getMyTeams)
      })

    this.getTeams()

    this.props.setActiveTabId(0)
  }

  redirectToLocked = () =>
    this.props.history.push('/locked', { from: this.props.location.pathname })

  openToast(success, message) {
    if (success) {
      toast.success(message, { className: 'toast-green' })
    } else {
      toast.error(message, { className: 'toast-error' })
    }
  }

  getBattleStats = () =>
    api.getBattleStats(this.state.user).then(data => {
      const battlesFought = data.wins + data.losses

      const winRate = battlesFought ? parseInt((data.wins * 100) / battlesFought, 10) : 0

      data &&
        this.setState({
          stats: {
            wins: data.wins,
            losses: data.losses,
            winRate,
          },
        })
    })

  getBattleHistory = () =>
    Promise.resolve()
      .then(() => this.setState({ loadingBattleHistory: true }))
      .then(() => api.getBattleHistory(this.state.user))
      .then(result => {
        const { history: battleHistory } = result

        return this.setState({
          battleHistory: battleHistory.map(b => ({
            ...b,
            attackerFighters: b.attackerFighters.map(f => ({
              ...f,
              isOwner: f.owner === this.state.user,
              id: f.fighterId,
              color: FIGHTER_COLORS[f.color],
            })),
            defenderFighters: b.defenderFighters.map(f => ({
              ...f,
              isOwner: f.owner === this.state.user,
              id: f.fighterId,
              color: FIGHTER_COLORS[f.color],
            })),
          })),
        })
      })
      .catch(e => console.log('ERROR', e))
      .then(() => this.setState({ loadingBattleHistory: false }))

  getMyFighters = () =>
    api.getMyFighters(this.state.user).then(fightersArr => {
      const myFighters = fightersArr.map((f, i) => ({
        ...f,
        isOwner: f.owner === this.state.user,
        id: f.fighterId,
        color: FIGHTER_COLORS[f.color],
        timeTillBattleCooldown: getTimeTillCooldown(
          f.battleCooldownEndTime,
          this.state.blockNumber
        ),
        timeTillPrizeCooldown: getTimeTillCooldown(f.prizeCooldownEndTime, this.state.blockNumber),
      }))
      this.setState({ myFighters, recievedFighters: true })
    })

  getTeams = page =>
    Promise.resolve()
      .then(() => this.setState({ loadingTeams: true }))
      .then(() => api.getTeamsInArena(page))
      .then(({ teams, teamsFound }) => {
        this.setState({
          totalTeamsFound: teamsFound,
          teamsInArena: _.sortBy(
            teams.map(team => ({
              ...team,
              fighters: team.fighters.map(f => ({
                ...f,
                color: FIGHTER_COLORS[f.color],
              })),
              isOwner: team.owner === this.state.user,
              removing: false,
            })),
            'isOwner'
          ),
          loadingTeams: false,
        })
      })
      .then(() => this.setState({ loadingTeams: false, recievedOpponents: true }))

  getMyTeams = () =>
    Promise.resolve()
      .then(() => this.setState({ loadingMyTeams: true }))
      .then(() => api.getUsersTeams(this.state.user))
      .then(teams => {
        if (teams && teams.length) {
          const formattedTeams = teams.map(team => ({
            ...team,
            fighters: team.fighterIds.map(fId =>
              _.find(this.state.myFighters, ({ fighterId }) => fighterId === fId)
            ),
            isOwner: team.owner === this.state.user,
            removing: false,
          }))
          this.props.setTeams(formattedTeams)
        }
      })
      .then(() =>
        this.setState({
          loadingMyTeams: false,
          recievedMyTeams: true,
          myTeamsCollapsed: !this.props.myTeams.length,
        })
      )

  refreshMyTeams = async force => {
    const now = Date.now()
    if (force || now > this.state.nextMyTeamsRefresh) {
      await this.getMyTeams()
      this.setState({ nextMyTeamsRefresh: now + WAIT_FOR_NEXT_REFRESH })
    }
  }

  refreshOpponents = async force => {
    const now = Date.now()
    if (force || now > this.state.nextOpponentsRefresh) {
      await this.getTeams()
      this.setState({ nextOpponentsRefresh: now + WAIT_FOR_NEXT_REFRESH })
    }
  }

  toggleTeamModal = on =>
    this.setState({
      teamModalOpen: on != null ? !!on : !this.state.teamModalOpen,
      submitIsFight: false,
    })

  addFighter = id => this.props.addFighterToTeam(id)

  removeFighter = id => this.props.removeFighterFromTeam(id)

  enterArena = async () => {
    alert('Entering new fighters into the arena is currently disabled.')
    return

    // uncomment this code to renable battles
    // this code works fine
    // this.setState({ enteringArena: true, teamModalOpen: false })
    // try {
    //   await createTeam(this.props.teamFighters)
    //   this.props.enterTeamInArena()
    //   this.openToast(true, 'Your team is entering the arena. Check back soon...')
    //   this.refreshMyTeams(true)
    // } catch (e) {
    //   this.openToast(false, 'There was a problem entering the arena. Please try again...')
    // }
    // this.setState({ enteringArena: false })
  }

  leaveArena = async teamId => {
    this.setState({
      teamsInArena: this.state.teamsInArena.map(team => {
        if (team.teamId === teamId) {
          return {
            ...team,
            removing: true,
          }
        }
        return team
      }),
    })
    try {
      await deleteTeam(teamId)
      this.props.removeTeamFromArena(teamId)
      this.openToast(true, 'Your team is exiting the arena...')
      this.refreshMyTeams(true)
    } catch (e) {
      this.setState({
        teamsInArena: this.state.teamsInArena.map(team => {
          if (team.teamId === teamId) {
            return {
              ...team,
              removing: false,
            }
          }
          return team
        }),
      })
      this.openToast(false, 'There was a problem exiting the arena. Please try again...')
    }
  }

  handleFightClick = async opponentTeamId => {
    alert('Battles are currently disabled.')
    return

    // this works fine. we just decided to disable battles for now
    // let teamToFight
    // const myTeam = this.props.teamFighters
    // if (this.state.teamModalOpen && this.state.submitIsFight) {
    //   teamToFight = this.state.teamToFight
    //   this.setState({
    //     teamModalOpen: false,
    //     submitIsFight: false,
    //     teamToFight: null,
    //     fightingTeam: { ...this.state.fightingTeam, [teamToFight]: true },
    //   })
    //   try {
    //     const receipt = await battle(myTeam, teamToFight).then(({ success, error, receipt }) =>
    //       success ? Promise.resolve(receipt) : Promise.reject(error)
    //     )
    //     this.props.resetTempTeam()
    //     this.openToast(true, 'Your team has initiated a fight!')
    //     this.refreshMyTeams(true)
    //     this.refreshOpponents(true)
    //     this.props.history.push(`/arena/results/${receipt.transactionHash}`)
    //   } catch (e) {
    //     this.openToast(
    //       false,
    //       'There was a problem initiating a fight with this opponent. Please try again...'
    //     )
    //   }
    // } else {
    //   this.setState({ teamModalOpen: true, submitIsFight: true, teamToFight: opponentTeamId })
    // }
    // this.setState({ fightingTeam: { ...this.state.fightingTeam, [teamToFight]: false } })
  }

  onChangePage = page => {
    this.props.setArenaPage(page)
    this.setState({ teamsInArena: null })
    this.getTeams(page)
  }

  getPages() {
    const pages = []
    const totalPages = Math.ceil(this.state.totalTeamsFound / 12)
    for (let i = 0; i < totalPages; i += 1) {
      pages.push(i)
    }
    return pages
  }

  render() {
    const {
      user,
      stats,
      recievedMyTeams,
      recievedOpponents,
      loadingMyTeams,
      enteringArena,
      myTeamsCollapsed,
      loadingTeams,
      teamsInArena,
      fightingTeam,
      totalTeamsFound,
      teamModalOpen,
      myFighters,
      submitIsFight,
    } = this.state

    const otherTeams =
      teamsInArena &&
      teamsInArena
        .filter(team => {
          const r =
            !_.find(this.props.myTeams, ({ teamId }) => teamId === team.teamId) &&
            team.fighters.every(({ owner }) => owner === team.owner)

          return r
        })
        .map(team => ({ ...team, fighting: fightingTeam[team.teamId] }))

    return (
      <div>
        <Banner>At the request of the community we've disabled the arena.</Banner>
        <Grid>
          <Tabs address={user} className="tabs-center" tabsHeader={tabsHeader} />
          <BattleStats stats={stats} />
          <Row
            style={{
              marginTop: 56,
            }}
          >
            <Col sm={12}>
              <div style={styles.header}>Arrange your team</div>
            </Col>
          </Row>
          <FighterTeam
            team={this.props.teamFighters.map(id =>
              _.find(this.state.myFighters, f => f.id === id)
            )}
            onClickEmpty={() => this.toggleTeamModal()}
            onClickPopulated={() => this.toggleTeamModal()}
            submittingTeam={enteringArena}
          />
          <Row
            style={{
              marginTop: 16,
            }}
          >
            <Col xs={12}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingRight: 24,
                }}
              >
                <MainAction loading={enteringArena} onClick={() => this.toggleTeamModal()}>
                  ENTER ARENA
                </MainAction>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 32 }}>
            <Col
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div style={styles.headerArena}>Arena</div>
              <div style={styles.headerDivider} />
            </Col>
          </Row>
          <TeamsSection
            title="My Teams"
            recievedTeams={recievedMyTeams || recievedOpponents}
            loadingTeams={loadingMyTeams}
            teams={this.props.myTeams}
            onRefresh={() => this.refreshMyTeams()}
            handleFightClick={this.handleFightClick}
            leaveArena={this.leaveArena}
            collapsible
            open={!myTeamsCollapsed}
            toggleCollapsible={() => this.setState({ myTeamsCollapsed: !myTeamsCollapsed })}
            emptyMessage={
              <div>
                <p style={{ fontSize: 22, textAlign: 'center', paddingTop: 20, marginBottom: 12 }}>
                  You have no teams in the arena{' '}
                  <span role="img" aria-label="Crying face">
                    😭
                  </span>
                </p>
                <p style={{ fontSize: 22, textAlign: 'center', marginTop: 12 }}>
                  Use the section above to add a team
                </p>
              </div>
            }
          />
          <TeamsSection
            title="Who will you fight?"
            recievedTeams={recievedOpponents || recievedMyTeams}
            loadingTeams={loadingTeams}
            teams={otherTeams}
            onRefresh={() => this.refreshOpponents()}
            handleFightClick={this.handleFightClick}
            leaveArena={this.leaveArena}
            defaultLoad
            totalTeamsFound={totalTeamsFound}
            onChangePage={this.onChangePage}
            pages={this.getPages()}
            activePage={this.props.page}
            emptyMessage={
              <div>
                <p style={{ fontSize: 22, textAlign: 'center', paddingTop: 50, marginBottom: 12 }}>
                  There are no teams currently in the arena{' '}
                  <span role="img" aria-label="Crying face">
                    😭
                  </span>
                </p>
                <p style={{ fontSize: 22, textAlign: 'center', marginTop: 12 }}>
                  Enter one of your teams into the arena to battle!
                </p>
              </div>
            }
          />
        </Grid>
        <FighterTeamModal
          open={teamModalOpen}
          onClose={() => this.toggleTeamModal()}
          team={this.props.teamFighters}
          fighters={_.sortBy(
            myFighters.filter(
              ({ fighterId }) => this.props.myFightersInTeams.indexOf(fighterId) === -1
            ),
            'onAuction'
          )}
          addFighter={this.addFighter}
          removeFighter={this.removeFighter}
          submitTeam={this.enterArena}
          onClickFight={this.handleFightClick}
          submitIsFight={submitIsFight}
        />
      </div>
    )
  }
}

Arena.propsTypes = {
  setActiveTabId: PropTypes.func.isRequired,
}

const mapStateToProps = ({ app, fights, tabs }) => ({
  page: app.arenaPage,
  teamFighters: fights.tempTeam.fighterIds,
  teamLeader: fights.tempTeam.leaderId,
  teamInArena: fights.tempTeam.inArena,
  teamSubmitted: fights.tempTeam.submitted,
  currentTeamId: fights.tempTeam.teamId,
  myFightersInTeams: Object.keys(fights.teams).reduce(
    (arr, teamId) => arr.concat(fights.teams[teamId].fighterIds),
    []
  ),
  myTeams: Object.keys(fights.teams).map(teamId => fights.teams[teamId]),
})

export default connect(mapStateToProps, {
  addFighterToTeam,
  removeFighterFromTeam,
  setTeam,
  setTeams,
  enterTeamInArena,
  removeTeamFromArena,
  removingTeamFromArena,
  setArenaPage,
  resetTempTeam,
  setActiveTabId,
})(Arena)
