import React, { Component } from 'react'
import _ from 'lodash'
import Hider from '../Hider'
import DownArrow from './components/DownArrow'
import './css/dropdown.css'

const styles = {
  dropdown: {
    background: '#fff',
    border: '1px solid #eee',
    cursor: 'pointer',
    borderRadius: 26,
    padding: '4px 12px',
    verticalAlign: 'top',
    display: 'inline-block',
  },
  option: {
    cursor: 'pointer',
    padding: 10,
    fontWeight: 'bold',
  },
  selectedOption: {
    display: 'inline-block',
    paddingRight: 5,
  },
  sortBy: {
    display: 'inline-block',
    color: '#9B9B9B',
    fontSize: 12,
    margin: '20px 10px',
  },
}

class Dropdown extends Component {
  constructor() {
    super()
    this.state = {
      showOptions: false,
    }
  }

  toggleOptions = () => this.setState({ showOptions: !this.state.showOptions })

  getOptions = () => {
    let arr = []

    for (let i = 0; i < this.props.sortMethods.length; i++) {
      const { value, label } = this.props.sortMethods[i]
      arr.push(
        <div key={value} onClick={() => this.props.onChangeSort(value)} style={styles.option}>
          {label}
        </div>
      )
    }

    return arr
  }

  getCurrentOption = () => {
    const sortMethod = _.find(this.props.sortMethods, s => s.value === this.props.sort)
    return sortMethod.label
  }

  render() {
    return (
      <div>
        <div style={styles.sortBy} className="hidden-xs">
          <p style={{ margin: 0 }}>SORT BY</p>
        </div>
        <div
          style={{ ...styles.dropdown }}
          className="dropdown-wrapper"
          onClick={this.toggleOptions}
        >
          <div style={{ ...styles.option, ...styles.selectedOption }}>
            {this.getCurrentOption()}
          </div>
          <DownArrow />
          <Hider hide={!this.state.showOptions}>
            <div style={{ width: '100%', height: '1px', background: '#eee' }} />
            {this.getOptions()}
          </Hider>
        </div>
      </div>
    )
  }
}

export default Dropdown
