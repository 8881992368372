export const styles = {
  cardContainer: {
    width: '100%',
    maxWidth: 400,
    marginTop: 15,
    marginBottom: 15,
    // overflow: 'hidden',
    textAlign: 'center',
  },
  card: {
    width: '100%',
    position: 'relative',
    display: 'inline-block',
    paddingTop: '20px',
    borderRadius: 14,
  },
  minHeight: {
    minHeight: 320,
  },
  heightAuto: {
    minHeight: 'auto',
  },
  fontSize: {
    fontSize: 16,
  },
  fighterImg: {
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '110%',
  },
  link: {
    textDecoration: 'none',
  },
  starContainer: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
  },
}

export const stylesXs = {
  cardContainer: {
    width: '100%',
    maxWidth: 400,
    marginTop: 0,
    marginBottom: 0,
    // overflow: 'hidden',
    textAlign: 'center',
  },
  card: {
    width: '100%',
    position: 'relative',
    display: 'inline-block',
    paddingTop: 30,
    borderRadius: 8,
  },
  minHeight: {
    minHeight: 320,
  },
  heightAuto: {
    minHeight: 'auto',
  },
  fontSize: {
    fontSize: 14,
  },
  fighterImg: {
    position: 'relative',
    width: '90%',
  },
  link: {
    textDecoration: 'none',
  },
  starContainer: {
    position: 'absolute',
    top: 15,
    left: 0,
    right: 0,
  },
}
