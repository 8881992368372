import React from 'react'
import FighterCard from '../../components/FighterCard'
import RenderLevelSection from './RenderLevelSection'

const TooltipContent = props => {
  const { fighters } = props
  return (
    <div className="tooltip-content">
      <div className="fighters-wrapper fighters-wrapper-flex">
        {fighters.map(
          (fighter, i) =>
            fighter ? (
              <div className="fighter-item-wrapper" key={`fighter-${i}`}>
                <FighterCard
                  showStats={false}
                  showPrice={false}
                  autoHeight
                  starSize={10}
                  cardSizeXs
                  showName={fighter.attr[i]}
                  showGenLevel={false}
                  noAnimation
                  {...fighter}
                />
                <div className="fight-result-statistic-block">
                  <RenderLevelSection fighter={fighter} />
                </div>
              </div>
            ) : null
        )}
      </div>
    </div>
  )
}

export default TooltipContent
