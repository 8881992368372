import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import api from '../../utils/api'
import getWeb3 from '../../utils/getWeb3'
import { FIGHTER_COLORS } from '../../consts'
import MainAction from '../../components/buttons/MainAction'
import BattleIcon from '../../components/Header/components/Icons/BattleIcon'
import GiftIcon from '../../components/Header/components/Icons/GiftIcon'
import FightResults from '../../components/FightResults'
import RewardModal from '../../components/RewardModal'
import BounceLoader from '../../components/loaders/BounceLoader'

class ArenaResults extends Component {
  static propTypes = {}

  constructor(props) {
    super(props)
    this.state = {
      battleWon: true,
      prizeClaimed: false,
      loadingData: true,
      prizeModalOpen: false,
      notFound: false,
    }
  }

  componentDidMount() {
    getWeb3()
      .then(
        web3 =>
          new Promise((resolve, reject) =>
            web3.eth.getAccounts((error, accounts) => (error ? reject(error) : resolve(accounts)))
          )
      )
      .then(accounts => this.setState({ user: accounts[0] }))
      .then(this.getBattleResult)
  }

  componentWillUnmount() {
    if (this.obliterateTimer) {
      clearInterval(this.resultInterval)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.notFound && !this.state.notFound && this.obliterateTimer) {
      clearInterval(this.resultInterval)
    }
  }

  waitForData() {
    this.setState({ endTimerAt: Date.now() + 300000, refreshing: true })
    this.resultInterval = setInterval(() => {
      if (this.state.endTimerAt > Date.now()) {
        this.getBattleResult()
      } else {
        this.setState({ refreshing: false })
        clearInterval(this.resultInterval)
      }
    }, 15000)
  }

  getBattleResult = () => {
    const txHash = window.location.pathname.split('/')[3]
    api
      .getBattleResult(txHash)
      .then(async result => {
        const {
          winnerAddress,
          loserAddress,
          attackerWon,
          attackerFighters,
          defenderFighters,
          prizeFighter,
          attackerXpGained,
          defenderXpGained,
        } = result
        const isWinner = this.state.user === winnerAddress
        const isLoser = this.state.user === loserAddress
        const userBattled = isWinner || isLoser
        let fighters, expGained
        if (userBattled && isWinner) {
          fighters = attackerWon ? attackerFighters : defenderFighters
          expGained = attackerWon ? attackerXpGained : defenderXpGained
        } else if (userBattled && !isWinner) {
          fighters = attackerWon ? defenderFighters : attackerFighters
          expGained = attackerWon ? defenderXpGained : attackerXpGained
        } else {
          // user didnt battle. show winner
          fighters = attackerWon ? attackerFighters : defenderFighters
          expGained = attackerWon ? attackerXpGained : defenderXpGained
        }

        this.setState({
          isWinner,
          isLoser,
          userBattled,
          winnerAddress,
          fighters: fighters.map(f => ({
            ...f,
            id: f.fighterId,
            color: FIGHTER_COLORS[f.color],
            expGained,
          })),
          prizeFighter: prizeFighter
            ? {
                ...prizeFighter,
                id: prizeFighter.fighterId,
                color: FIGHTER_COLORS[prizeFighter.color],
              }
            : null,
          loadingData: false,
          notFound: false,
        })
      })
      .catch(e => {
        this.setState({ notFound: true })
        if (!this.resultInterval) {
          this.waitForData()
        }
      })
  }

  toggle = () => {
    this.setState({ prizeClaimed: true, prizeModalOpen: true })
  }

  render() {
    let title
    if (this.state.userBattled) {
      title = this.state.isWinner ? 'You won!' : 'You lost.'
    } else {
      title = `${this.state.winnerAddress} won`
    }

    if (this.state.notFound) {
      return (
        <div
          style={{
            display: 'flex',
            height: 400,
            marginBottom: 100,
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <h1 className="title">Awaiting Results</h1>
          <i className="subtitle-line" style={{ marginBottom: 15 }} />
          <p
            style={{
              marginTop: 70,
              marginBottom: 80,
              paddingLeft: 20,
              paddingRight: 20,
              fontSize: '18',
              maxWidth: 500,
              textAlign: 'center',
            }}
          >
            You're battle results are being mined by the blockchain. Check back soon to see if you
            won!
          </p>
          {this.state.refreshing ? (
            <BounceLoader size={60} />
          ) : (
            <MainAction wide onClick={() => this.props.history.push('/arena')}>
              <div className="flex items-center justify-center">
                <BattleIcon width={20} height={20} color={'#fff'} />
                <span className="btn-text icon-left">BACK TO ARENA</span>
              </div>
            </MainAction>
          )}
        </div>
      )
    }

    return (
      <div className="page-arena-results">
        {this.state.loadingData ? (
          <div
            style={{ display: 'flex', justifyContent: 'center', marginTop: 200, marginBottom: 200 }}
          >
            <BounceLoader size={60} />
          </div>
        ) : (
          <div>
            <Row style={{ marginTop: 32 }}>
              <Col xs={12}>
                <div className="section-header text-center">
                  <h1 className="title">{title}</h1>
                  <i className="subtitle-line" style={{ marginBottom: 15 }} />
                  {this.state.prizeClaimed || this.state.isLoser || !this.state.prizeFighter ? (
                    <MainAction wide onClick={() => this.props.history.push('/arena')}>
                      <div className="flex items-center justify-center">
                        <BattleIcon width={20} height={20} color={'#fff'} />
                        <span className="btn-text icon-left">BACK TO ARENA</span>
                      </div>
                    </MainAction>
                  ) : (
                    <MainAction color={'#ffc107'} wide victory onClick={this.toggle}>
                      <div className="flex items-center justify-center">
                        <GiftIcon width={20} height={20} color={'#fff'} />
                        <span className="btn-text icon-left">VIEW PRIZE</span>
                      </div>
                    </MainAction>
                  )}
                </div>
              </Col>
              <Col xs={12}>
                <div className="section-body">
                  <div className="container container-xs">
                    <FightResults fighters={this.state.fighters} />
                  </div>
                </div>
              </Col>
            </Row>
            {this.state.prizeFighter && (
              <RewardModal
                open={this.state.prizeModalOpen}
                close={() => this.setState({ prizeModalOpen: false })}
                fighter={this.state.prizeFighter}
              />
            )}
          </div>
        )}
      </div>
    )
  }
}

export default ArenaResults
