import FighterCoreContract from '../contracts/FighterCore.json'
import getWeb3 from './getWeb3'

// should be a way to clean this up even more.
// bit of copy and paste and not sure we need to do the whole get3.then(...) every time.
// once should be enough

let globalFighterCoreInstance, globalWeb3

const getFighterCoreInstance = async () => {
  if (!globalWeb3 || !globalFighterCoreInstance) {
    try {
      const web3 = await getWeb3()
      const fighterCoreInstance = new web3.eth.Contract(
        FighterCoreContract,
        process.env.REACT_APP_FIGHTER_CORE_ADDRESS
      )
      window.fighterCoreInstance = fighterCoreInstance
      globalFighterCoreInstance = fighterCoreInstance
      globalWeb3 = web3
      return { fighterCoreInstance, web3 }
    } catch (e) {
      console.error('ERROR:', e)
      throw e
    }
  } else {
    return {
      fighterCoreInstance: globalFighterCoreInstance,
      web3: globalWeb3,
    }
  }
}

const getNumberOfFighters = async () => {
  try {
    const { fighterCoreInstance } = await getFighterCoreInstance()
    const numberOfFighters = await fighterCoreInstance.methods.totalSupply().call()
    return numberOfFighters
  } catch (e) {
    console.error('ERROR', e)
    throw e
  }
}

const getFighterOwner = async id => {
  try {
    const { fighterCoreInstance, web3 } = await getFighterCoreInstance()
    const owner = await fighterCoreInstance.fighterIndexToOwner(id).call()
    const accounts = await new Promise((resolve, reject) =>
      web3.eth.getAccounts((error, accounts) => (error ? reject(error) : resolve(accounts)))
    )
    return {
      owner,
      isOwner: accounts[0] === owner,
    }
  } catch (e) {
    console.error('ERROR', e)
    throw e
  }
}

const getFighter = async id => {
  try {
    const { fighterCoreInstance } = await getFighterCoreInstance()
    const result = await fighterCoreInstance.methods.getFighter(id).call()
    const { owner, isOwner } = await getFighterOwner(id)
    return {
      ...result,
      id,
      owner,
      isOwner,
    }
  } catch (e) {
    console.error('ERROR:', e)
    throw e
  }
}

const getFighters = async ids => {
  const fighters = []

  await Promise.all(
    ids.map(async i => {
      const fighter = await getFighter(i)
      fighters.push(fighter)
    })
  )

  return fighters
}

// let fighters; getAllFighters().then(x => fighters = x);
const getAllFighters = async () => {
  const fighters = []
  const supply = []
  const totalSupply = await getNumberOfFighters()

  for (let i = 1; i < totalSupply; i++) {
    supply.push(i)
  }

  await Promise.all(
    supply.map(async i => {
      const fighter = await getFighter(i)
      fighters.push(fighter)
    })
  )

  return fighters
}

export {
  getNumberOfFighters,
  getFighterCoreInstance,
  getFighter,
  getFighters,
  getAllFighters,
  getFighterOwner,
}

window.getNumberOfFighters = getNumberOfFighters
window.getFighterCoreInstance = getFighterCoreInstance
window.getFighter = getFighter
window.getFighters = getFighters
window.getAllFighters = getAllFighters
window.getFighterOwner = getFighterOwner
