import SaleClockAuctionContract from '../contracts/SaleClockAuction.json'
import FighterCoreContract from '../contracts/FighterCore.json'
import getWeb3 from './getWeb3'
import { getNumberOfFighters } from './web3Fighters'
import { trackPurchase, trackCancelAuction } from './analytics'

// should be a way to clean this up even more.
// bit of copy and paste and not sure we need to do the whole get3.then(...) every time.
// once should be enough

let globalClockAuctionInstance, globalWeb3

const getClockAuctionInstance = async () => {
  if (!globalClockAuctionInstance || !globalWeb3) {
    try {
      const web3 = await getWeb3()
      const clockAuctionInstance = new web3.eth.Contract(
        SaleClockAuctionContract,
        process.env.REACT_APP_FIGHTER_SALE_ADDRESS
      )
      window.clockAuctionInstance = clockAuctionInstance
      globalClockAuctionInstance = clockAuctionInstance
      globalWeb3 = web3
      return { clockAuctionInstance, web3 }
    } catch (e) {
      console.error('ERROR:', e)
      throw e
    }
  } else {
    return {
      clockAuctionInstance: globalClockAuctionInstance,
      web3: globalWeb3,
    }
  }
}

const createAuction = async (fighterId, startingPrice, endingPrice, duration) => {
  try {
    const { web3 } = await getClockAuctionInstance()
    const fighterCoreInstance = new web3.eth.Contract(
      FighterCoreContract,
      process.env.REACT_APP_FIGHTER_CORE_ADDRESS
    )
    const currentUser = await new Promise((resolve, reject) =>
      web3.eth.getAccounts((error, accounts) => (error ? reject(error) : resolve(accounts[0])))
    )

    // const { receipt } =
    await fighterCoreInstance.methods
      .createSaleAuction(
        fighterId,
        web3.utils.toWei(startingPrice.toString(), 'ether'),
        web3.utils.toWei(endingPrice.toString(), 'ether'),
        duration * 60 * 60
      )
      .send({ from: currentUser })

    return true
    // stopped working after web3 update
    // return receipt && receipt.status === '0x1'
  } catch (e) {
    // TODO
    console.log(
      'This is probably happening because the fighter is not on auction which is fine. We can check the error to be sure.'
    )

    console.error('ERROR:', e)
    throw e
  }
}

const cancelAuction = async id => {
  try {
    const { clockAuctionInstance, web3 } = await getClockAuctionInstance()
    const accounts = await new Promise((resolve, reject) =>
      web3.eth.getAccounts((error, accounts) => (error ? reject(error) : resolve(accounts)))
    )
    // const { receipt } =
    await clockAuctionInstance.methods.cancelAuction(id).send({ from: accounts[0] })

    trackCancelAuction(id)

    return true
    // stopped working after web3 update
    // return receipt && receipt.status === '0x1'
  } catch (e) {
    console.error('ERROR:', e)
    throw e
  }
}

const bid = async (id, amount) => {
  try {
    const { clockAuctionInstance, web3 } = await getClockAuctionInstance()
    const accounts = await new Promise((resolve, reject) =>
      web3.eth.getAccounts((error, accounts) => (error ? reject(error) : resolve(accounts)))
    )
    const { receipt } = await clockAuctionInstance.methods
      .bid(id)
      .send({ from: accounts[0], value: amount })

    trackPurchase({ fighterId: id, amount, web3Receipt: receipt })

    return true
    // stopped working after web3 update
    // return receipt && receipt.status === '0x1'
  } catch (e) {
    console.error('ERROR:', e)
    throw e
  }
}

const getAuction = async id => {
  try {
    const { clockAuctionInstance } = await getClockAuctionInstance()
    const result = await clockAuctionInstance.methods.getAuction(id).call()
    if (result) {
      return { ...result, id }
    }
  } catch (e) {
    console.error('ERROR:', e)
    return
  }
}

const getCurrentAuctionPrice = async id => {
  try {
    const { clockAuctionInstance } = await getClockAuctionInstance()
    const currentPrice = await clockAuctionInstance.methods.getCurrentPrice(id).call()

    return currentPrice
  } catch (e) {
    console.error('ERROR:', e)
    return
  }
}

// let fighters; getAllFighters().then(x => fighters = x);
const getAllAuctions = async () => {
  const auctions = []
  const supply = []
  const totalSupply = await getNumberOfFighters()

  for (let i = 1; i < totalSupply; i++) {
    supply.push(i)
  }

  await Promise.all(
    supply.map(async i => {
      const auction = await getAuction(i)
      if (auction) {
        auctions.push(auction)
      }
    })
  )

  return auctions.reduce((obj, a) => {
    if (a) {
      obj[a.id] = {
        ...a,
        startingPrice: globalWeb3.utils.fromWei(a.startingPrice, 'ether'),
      }
    }
    return obj
  })
}

export { createAuction, getAuction, getCurrentAuctionPrice, getAllAuctions, bid, cancelAuction }

window.createAuction = createAuction
window.getAuction = getAuction
window.getCurrentAuctionPrice = getCurrentAuctionPrice
window.getAllAuctions = getAllAuctions
window.cancelAuction = cancelAuction
window.bid = bid
