import React from 'react'

const styles = {
  parent: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
  child: {
    fontWeight: 'bold',
    color: '#2195f3',
    backgroundColor: '#fff',
    width: 80,
    padding: 4,
    fontSize: 10,
    position: 'relative',
    margin: 'auto',
    borderRadius: 2,
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1)',
  },
}

const ComingSoon = ({ xs }) => (
  <div style={styles.parent}>
    <div style={{ ...styles.child, top: xs ? 60 : 70 }}>COMING SOON</div>
  </div>
)

export default ComingSoon
