import React from 'react'
import Hider from '../../components/Hider'
import PropTypes from 'prop-types'
import './style.css'

const styles = {
  inner: {
    top: '20px',
    textAlign: 'left',
    borderRadius: '10px',
    padding: '14px',
    boxShadow: '0 2px 4px 2px rgba(0, 0, 0, 0.1)',
    position: 'absolute',
    zIndex: 999,
    background: '#fff',
  },
  text: {
    margin: '0px',
    color: '#9b9b9b',
  },
  title: {
    margin: '0px 0px 3px 0px',
    fontWeight: 600,
    color: '#030f23',
  },
}

const Tooltip = ({ hide, title, children, style, className, wrapClassName }) => (
  <Hider hide={hide} className={wrapClassName}>
    <div style={{ ...styles.inner, ...style }} className={`tooltip ${className ? className : ''}`}>
      <p style={styles.title}>{title}</p>
      <div style={styles.text}>{children}</div>
    </div>
  </Hider>
)

Tooltip.propTypes = {
  className: PropTypes.string,
  wrapClassName: PropTypes.string,
  title: PropTypes.string,
  // children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

Tooltip.defaultProps = {
  className: '',
  wrapClassName: '',
  title: '',
  children: [],
}

export default Tooltip
