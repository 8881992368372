import React from 'react'
import moment from 'moment'
import Stars from '../Stars'
import { generationToStars } from '../../utils/fighter'
import ExhaustedCooldown from '../cooldowns/ExhaustedCooldown'
import PrizeCooldown from '../cooldowns/PrizeCooldown'
import { LeaderBadge } from './'

const styles = {
  container: {
    width: '100%',
    height: 240,
    borderRadius: 8,
    marginTop: 8,
    marginBottom: 8,
    display: 'flex',
    flexDirection: 'column',
    userSelect: 'none',
  },
  text: {
    fontSize: 14,
    lineHeight: 1.14,
    color: '#9b9b9b',
    paddingTop: 12,
  },
  starContainer: {
    top: '24px',
    position: 'relative',
  },
  fighterImg: {
    height: '85%',
  },
  removeContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  removeButton: {
    width: 30,
    height: 30,
    borderRadius: 100,
    backgroundColor: '#fff',
    boxShadow: '0 2px 4px 0 #00000033',
    position: 'absolute',
    top: -10,
    left: -10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

const RemoveButton = ({ onClick, color, first }) => (
  <div style={styles.removeButton} onClick={onClick}>
    <div style={{ transform: 'rotate(-45deg)' }}>
      <svg
        fill={color}
        height="20"
        width="20"
        viewBox="0 0 24 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    </div>
  </div>
)

const PopulatedMember = ({
  fighter,
  noLeftSpace,
  noRightSpace,
  first,
  onClick,
  small,
  edit,
  removeFighter,
  disabled,
}) => (
  <div
    style={{
      width: '100%',
      marginLeft: noLeftSpace ? 0 : 12,
      marginRight: noRightSpace ? 0 : 12,
    }}
  >
    <div
      style={{
        ...styles.container,
        position: 'relative',
        cursor: disabled ? '' : 'pointer',
        background: fighter.color.secondary,
      }}
    >
      {edit ? (
        <div style={styles.removeContainer}>
          <RemoveButton
            color={fighter.color.star}
            onClick={disabled ? null : () => removeFighter(fighter.fighterId)}
          />
        </div>
      ) : (
        <div />
      )}
      <div style={styles.contentContainer} onClick={disabled ? null : onClick}>
        <div style={styles.starContainer}>
          <Stars
            amount={generationToStars(fighter.generation)}
            color={fighter.color.star}
            filled={2}
            size={13}
          />
        </div>
        <img
          alt="⚔️"
          style={{
            height: small ? null : '82%',
            width: small ? '100%' : null,
            maxWidth: small ? 195 : null,
            position: small ? 'relative' : 'absolute',
            top: '37px',
          }}
          src={fighter.image}
          onError={() => (this.img.src = require('../../images/question-mark.png'))}
        />
      </div>
      {first ? (
        <div style={{ position: 'absolute', right: '5px', top: '5px' }}>
          <ExhaustedCooldown
            show={fighter.timeTillBattleCooldown > 0}
            timeLeft={moment(Date.now() + fighter.timeTillBattleCooldown).fromNow(true)}
            color={fighter.color.star}
          />
          <div style={{ height: '5px' }} />
          <PrizeCooldown
            show={fighter.timeTillPrizeCooldown > 0}
            timeLeft={moment(Date.now() + fighter.timeTillPrizeCooldown).fromNow(true)}
            color={fighter.color.star}
          />
        </div>
      ) : (
        <div style={{ position: 'absolute', right: '5px', top: '5px' }}>
          <ExhaustedCooldown
            show={fighter.timeTillBattleCooldown > 0}
            timeLeft={moment(Date.now() + fighter.timeTillBattleCooldown).fromNow(true)}
            color={fighter.color.star}
          />
        </div>
      )}
    </div>
    {first && <LeaderBadge color={fighter.color.star} populated />}
  </div>
)

export default PopulatedMember
