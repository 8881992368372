import React, { Component } from 'react'
import api from '../../utils/api'
import Leaderboard from './Leaderboard'

export default class LeaderboardContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      users: null,
      loading: true,
    }
  }

  componentDidMount() {
    api
      .getLeaderboard()
      .then(data => this.setState({ users: data.leaderboard, loading: false }))
      .catch(error => console.error(`Error getting leaderboard`))
  }

  render() {
    return <Leaderboard users={this.state.users} loading={this.state.loading} />
  }
}
