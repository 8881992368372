import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Stars from '../Stars'
import InfoIcon from '../InfoIcon'
import RankTooltip from '../RankTooltip'
import ExhaustedCooldown from '../cooldowns/ExhaustedCooldown'
import PrizeCooldown from '../cooldowns/PrizeCooldown'
import CircleChart from '../CircleChart'
import './styles.css'

const styles = {
  fighterContainer: {
    position: 'relative',
    // width: 440,
    // height: 440,
    borderRadius: '50%',
    display: 'inline-block',
  },
  starContainer: {
    position: 'absolute',
    left: '50%',
    top: 40,
    display: 'block',
    transform: 'translateX(-50%)',
  },
  fighterImg: {
    height: '80%',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  fighterName: {
    margin: 0,
    fontWeight: 'bold',
  },
}

const FighterDisplay = ({
  experience,
  maxExperience,
  minExperience,
  dna,
  generation,
  color,
  img,
  name,
  stars,
  rank,
  isShowName,
  isShowTooltip,
  isShowFighter,
  isShowRank,
  timeTillBattleCooldown,
  timeTillPrizeCooldown,
}) => (
  <div>
    <div style={{ marginBottom: '20px' }}>
      {isShowName && <h1 style={styles.fighterName}>{name}</h1>}

      {isShowTooltip && (
        <div style={{ display: 'inline-block' }}>
          <InfoIcon />
          <div className="rankToolTip">
            <RankTooltip rank={rank} generation={generation} fighterPage />
          </div>
        </div>
      )}

      {isShowRank && (
        <p style={{ display: 'inline-block', color: '#9B9B9B', margin: 0, marginLeft: '5px' }}>
          {rank}
        </p>
      )}
    </div>

    {isShowFighter && (
      <div
        style={{
          ...styles.fighterContainer,
          background: color.secondary,
          opacity: 0,
        }}
        className="fadeInUp fighter-container"
      >
        <div
          style={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            top: '-10px',
            zIndex: 10,
          }}
        >
          <div style={{ display: 'inline-block', margin: '0px 5px' }}>
            <ExhaustedCooldown
              show={timeTillBattleCooldown > 0}
              timeLeft={moment(Date.now() + timeTillBattleCooldown).fromNow(true)}
              color={color.star}
            />
          </div>
          <div style={{ display: 'inline-block', margin: '0px 5px' }}>
            <PrizeCooldown
              show={timeTillPrizeCooldown > 0}
              timeLeft={moment(Date.now() + timeTillPrizeCooldown).fromNow(true)}
              color={color.star}
            />
          </div>
        </div>

        <div style={styles.starContainer}>
          <Stars amount={stars} color={color.star} size={15} />
        </div>

        <img src={img} alt="Fighter" className="noselect" style={styles.fighterImg} />

        <CircleChart
          className="chart-absolute"
          currentExperience={experience}
          nextLevelExperience={maxExperience}
          previousLevelExperience={minExperience}
          borderColor={color.primary}
          width={400}
          height={400}
        />
      </div>
    )}
  </div>
)

FighterDisplay.propTypes = {
  dna: PropTypes.string,
  generation: PropTypes.number,
  color: PropTypes.object.isRequired,
  img: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  stars: PropTypes.number.isRequired,
  rank: PropTypes.string.isRequired,
  isShowName: PropTypes.bool,
  isShowTooltip: PropTypes.bool,
  isShowFighter: PropTypes.bool,
  isShowRank: PropTypes.bool,
}

FighterDisplay.defaultProps = {
  isShowName: true,
  isShowTooltip: true,
  isShowFighter: true,
  isShowRank: true,
}

export default FighterDisplay
