import BattleCoreContract from '../contracts/BattleCore.json'
import getWeb3 from './getWeb3'

// should be a way to clean this up even more.
// bit of copy and paste and not sure we need to do the whole get3.then(...) every time.
// once should be enough

let globalBattleCoreInstance, globalWeb3

const getBattleCoreInstance = async () => {
  if (!globalBattleCoreInstance || !globalWeb3) {
    try {
      const web3 = await getWeb3()
      const battleCoreInstance = new web3.eth.Contract(
        BattleCoreContract,
        process.env.REACT_APP_FIGHTER_BATTLE_ADDRESS
      )
      window.battleCoreInstance = battleCoreInstance
      globalBattleCoreInstance = battleCoreInstance
      globalWeb3 = web3
      return { battleCoreInstance, web3 }
    } catch (e) {
      console.error('ERROR:', e)
      throw e
    }
  } else {
    return {
      battleCoreInstance: globalBattleCoreInstance,
      web3: globalWeb3,
    }
  }
}

const createTeam = async fighterIds => {
  if (fighterIds.length > 0 && fighterIds.length <= 5) {
    try {
      const { web3, battleCoreInstance } = await getBattleCoreInstance()
      const currentUser = await new Promise((resolve, reject) =>
        web3.eth.getAccounts((error, accounts) => (error ? reject(error) : resolve(accounts[0])))
      )
      const { receipt } = await battleCoreInstance.methods.createTeam(fighterIds).send({
        from: currentUser,
      })
      return { success: true, receipt }
      // return { success: receipt && receipt.status === '0x1' }
    } catch (e) {
      console.error('ERROR:', e)
      throw e
    }
  } else {
    return {
      success: false,
      error: fighterIds.length === 0 ? 'NO_FIGHTERS' : 'TOO_MANY_FIGHTERS',
    }
  }
}

const deleteTeam = async teamId => {
  try {
    const { web3, battleCoreInstance } = await getBattleCoreInstance()
    const currentUser = await new Promise((resolve, reject) =>
      web3.eth.getAccounts((error, accounts) => (error ? reject(error) : resolve(accounts[0])))
    )
    const { receipt } = await battleCoreInstance.methods.deleteTeam(teamId).send({
      from: currentUser,
    })
    return { success: true, receipt }
    // return { success: receipt && receipt.status === '0x1' }
  } catch (e) {
    console.error('ERROR:', e)
    throw e
  }
}

const battle = async (fighterIds, opponentTeamId) => {
  if (fighterIds.length > 0 && fighterIds.length <= 5) {
    try {
      const { web3, battleCoreInstance } = await getBattleCoreInstance()
      const currentUser = await new Promise((resolve, reject) =>
        web3.eth.getAccounts((error, accounts) => (error ? reject(error) : resolve(accounts[0])))
      )
      const { receipt } = await battleCoreInstance.methods.battle(fighterIds, opponentTeamId).send({
        from: currentUser,
      })
      return { success: true, receipt }
      // return { success: receipt && receipt.status === '0x1', receipt }
    } catch (e) {
      console.error('ERROR:', e)
      throw e
    }
  } else {
    return {
      success: false,
      error: fighterIds.length === 0 ? 'NO_FIGHTERS' : 'TOO_MANY_FIGHTERS',
    }
  }
}

export { createTeam, deleteTeam, battle }

window.createTeam = createTeam
window.deleteTeam = deleteTeam
window.battle = battle
