import React, { Component } from 'react'
import Hero from '../../components/Hero'
import LandingContent from '../../components/LandingContent'
import { HERO, FIRST_CONTENT, LAST_CONTENT, CONTENT } from './consts'

class HomePage extends Component {
  goToMarket = () => this.props.history.push('/market')

  goToGettingStarted = () => this.props.history.push('/getting-started')

  render() {
    return (
      <div className="App">
        <Hero
          title={HERO.title}
          subtitle={HERO.subtitle}
          mainAction={{ label: HERO.mainAction, onClick: this.goToMarket }}
          image={require('../../images/landing/hero.png')}
        />
        <div style={{ marginTop: 55 }}>
          <LandingContent
            dark
            textAlign="center"
            header={FIRST_CONTENT.header}
            body={FIRST_CONTENT.body}
            buttons={[{ ...FIRST_CONTENT.button, onClick: this.goToGettingStarted }]}
          />
          {CONTENT.map(({ header, body, image }, i) => (
            <LandingContent
              key={header.main}
              textAlign={i % 2 ? 'right' : 'left'}
              header={header}
              body={body}
              image={image}
            />
          ))}
          <LandingContent
            textAlign="center"
            header={LAST_CONTENT.header}
            buttons={[
              { ...LAST_CONTENT.button, onClick: this.goToMarket },
              { ...FIRST_CONTENT.button, onClick: this.goToGettingStarted },
            ]}
          />
        </div>
      </div>
    )
  }
}

export default HomePage
