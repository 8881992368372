import React from 'react'

const CrownIcon = ({ color, size = 15 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 10 12">
    <g fill="none" fillRule="evenodd" transform="translate(-1)">
      <path d="M0 0h11.52v11.52H0z" />
      <path
        fill={color}
        fillRule="nonzero"
        d="M9.12 9.12H2.4c-.53 0-.96.432-.96.96v.48c0 .53.43.96.96.96h6.72c.53 0 .96-.43.96-.96v-.48c0-.528-.43-.96-.96-.96zM10.38 3.466a.48.48 0 0 0-.515-.055L7.383 4.653 6.17 2.633c-.173-.289-.65-.289-.823 0l-1.21 2.02L1.654 3.41a.479.479 0 0 0-.681.546l.96 3.84a.481.481 0 0 0 .466.363h6.72a.48.48 0 0 0 .466-.363l.96-3.84a.48.48 0 0 0-.166-.49z"
      />
      <circle cx="5.76" cy=".96" r="1" fill={color} fillRule="nonzero" />
    </g>
  </svg>
)

CrownIcon.defaultProps = {
  color: '#000',
}

export default CrownIcon
