import React from 'react'
import PropTypes from 'prop-types'
// import styled from 'styled-components'
import ClipLoader from '../loaders/ClipLoader'

import './styles.css'

// const Button = styled.div`
//   cursor: pointer;
//   width: ${props => (props.wide ? 'auto !important' : '140px')};
//   height: 48px;
//   border-radius: 2px;
//   text-align: center;
//   background-color: ${props => props.color || '#2196f3'};
//   color: ${props => props.textColor || '#fff'};
//
//   ${props =>
//     props.wide &&
//     `
//     display: inline-block;
//   `} ${props =>
//       props.victory &&
//       `
//     background-color: var(--warning);
//     background: linear-gradient(to right bottom, #ffd131 50%, #ffc107 50%);
//   `};
// `
//
// const Text = styled.span`
//   user-select: none;
//   width: 46px;
//   height: 7px;
//   font-size: 14px;
//   font-weight: 500;
//   line-height: 3.3;
//   text-align: left;
// `
//
// const LoadingButton = Button.extend`
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `
//
// const ChildWrapper = styled.div`
//   width: 100%;
//   height: 100%;
//
//   &:hover {
//     background-color: rgba(0, 0, 0, 0.1);
//   }
//
//   ${props =>
//     props.wide &&
//     `
//     padding-left: 32px;
//     padding-right: 32px;
//   `} ${props =>
//       props.loading &&
//       `
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   `};
// `
//
// const MainActionStyled = ({ style, color, onClick, children, loading, textColor, wide, victory }) =>
//   console.log(
//     'MAIN ACTION BUTTON',
//     { style, color, onClick, children, loading, textColor, wide, victory },
//     { color: color || '#2196f3' }
//   ) || loading ? (
//     <LoadingButton style={style} color={color} textColor={textColor} wide={wide} victory={victory}>
//       <ChildWrapper loading wide={wide}>
//         <ClipLoader color="#fff" size={25} />
//       </ChildWrapper>
//     </LoadingButton>
//   ) : (
//     <Button
//       style={style}
//       color={color}
//       textColor={textColor}
//       onClick={onClick}
//       wide={wide}
//       victory={victory}
//     >
//       <ChildWrapper wide={wide}>
//         <Text>{children}</Text>
//       </ChildWrapper>
//     </Button>
//   )

const MainAction = ({
  style,
  color,
  onClick,
  children,
  loading,
  textColor,
  wide,
  victory,
  cantAfford,
}) =>
  loading ? (
    <div
      className={`main-action-button ${wide && 'wide'} ${victory && 'victory'}`}
      style={{ color: textColor || '#fff', backgroundColor: color || '#2196f3' }}
    >
      <div className={`main-action-child-wrapper ${loading && 'loading'} ${wide && 'wide'}`}>
        <ClipLoader color="#fff" size={25} />
      </div>
    </div>
  ) : (
    <button
      className={`main-action-button ${wide && 'wide'} ${victory && 'victory'}`}
      style={{ ...style, color: textColor || '#fff', backgroundColor: color || '#2196f3' }}
      onClick={onClick}
    >
      <div className={`main-action-child-wrapper ${wide && 'wide'}`}>
        <div className="main-action-button-text">{children}</div>
      </div>
    </button>
  )

MainAction.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  textColor: PropTypes.string,
}

MainAction.defaultProps = {
  onClick: null,
  loading: false,
}

export default MainAction
