const app = {
  initializeApp: 'INITIALIZE_APP',
  setArenaPage: 'SET_ARENA_PAGE',
}

const fights = {
  addFighterToTeam: 'ADD_FIGHTER_TO_TEAM',
  removeFighterFromTeam: 'REMOVE_FIGHTER_FROM_TEAM',
  setTeam: 'SET_TEAM',
  setTeams: 'SET_TEAMS',
  enterTeamInArena: 'ENTER_TEAM_IN_ARENA',
  removeTeamFromArena: 'REMOVE_TEAM_FROM_ARENA',
  removingTeamFromArena: 'REMOVING_TEAM_FROM_ARENA',
  resetTempTeam: 'RESET_TEMP_TEAM',
}

const market = {
  setMarketPage: 'SET_MARKET_PAGE',
  setMarketSort: 'SET_MARKET_SORT',
  setMarketGen: 'SET_MARKET_GEN',
}

const tabs = {
  setActiveTabId: 'SET_ACTIVE_TAB_ID',
}

export { app, fights, market, tabs }
