import React from 'react'
import Hider from '../Hider'
import ModalDimmer from './ModalDimmer'
import CloseModal from './CloseModal'

const styles = {
  modalContainer: {
    zIndex: 1000,
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
}

const updateBodyOverflow = overflow => {
  document.body.style.overflow = overflow
}

const Modal = ({ hide, children, close, hideX }) => {
  if (!hide) {
    updateBodyOverflow('hidden')
  }
  if (hide) {
    updateBodyOverflow('auto')
  }

  return (
    <Hider hide={hide}>
      <ModalDimmer
        close={() => {
          updateBodyOverflow('auto')
          close()
        }}
      />
      <div style={styles.modalContainer}>
        <CloseModal
          close={() => {
            updateBodyOverflow('auto')
            close()
          }}
          hide={hideX}
        />
        {children}
      </div>
    </Hider>
  )
}

export default Modal
