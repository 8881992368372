import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './style.css'

const styles = {
  container: {
    position: 'absolute',
  },
  inner: {
    textAlign: 'left',
    width: 280,
    borderRadius: 10,
    padding: '1px 30px',
    boxShadow: '0 2px 4px 2px rgba(0, 0, 0, 0.1)',
    position: 'absolute',
    zIndex: 999,
    background: '#fff',
    left: -82,
  },
  text: {
    margin: '12px 0px',
  },
}

class RankTooltip extends Component {
  ranks = [
    { genText: 'Gen 0', name: 'General', maxGen: 0 },
    { genText: 'Gen 1 - Gen 2', name: 'Colonel', maxGen: 2 },
    { genText: 'Gen 3 - Gen 8', name: 'Major', maxGen: 8 },
    { genText: 'Gen 9 - Gen 15', name: 'Captain', maxGen: 15 },
    { genText: 'Gen 16+', name: 'Sergeant', maxGen: 1000 },
  ]

  constructor(props) {
    super(props)

    this.state = {
      ranks: this.ranks,
    }
  }

  getSelectedRank(generation) {
    for (let i = 0; i < 4; i++) {
      if (generation <= this.ranks[i].maxGen) return i
    }

    return 4

    // if (rank <= this.ranks[0].maxGen) return 0
    // if (rank <= this.ranks[1].maxGen) return 1
    // if (rank <= this.ranks[2].maxGen) return 2
    // if (rank <= this.ranks[3].maxGen) return 3
  }

  componentWillMount() {
    let ranks = this.state.ranks
    ranks[this.getSelectedRank(this.props.generation)].selected = true
    this.setState({ ranks })
  }

  render() {
    const {
      children,
      className,
      fighterPage,
      tooltipSize,
      tooltipPosition,
      tooltipResponsive,
      noEvents,
      tooltipArrow,
      arrowPossition,
    } = this.props
    const isResponsive = tooltipResponsive ? 'tooltip-responsive' : ''
    const isHovered = noEvents ? 'no-events' : ''
    const isArrow = tooltipArrow ? `arrow arrow-${arrowPossition || 'center'}` : ''
    const tooltipClasses = `tooltip-wrapper ${tooltipSize} ${tooltipPosition} ${isResponsive} ${isHovered} ${isArrow}`
    return (
      <div style={styles.container} className={className}>
        {!children ? (
          <div
            style={
              fighterPage
                ? { ...styles.inner, left: null, right: '0px' }
                : { ...styles.inner, top: -188 }
            }
          >
            {this.state.ranks.map((rank, i) => {
              return (
                <p style={styles.text} key={i}>
                  <span
                    style={{
                      color: rank.selected ? '#2196f3' : '#000',
                      fontWeight: 700,
                    }}
                  >
                    {rank.genText}
                  </span>
                  <span
                    style={{
                      color: rank.selected ? '#2196f3' : '#9B9B9B',
                      marginLeft: '10px',
                    }}
                  >
                    {rank.name}
                  </span>
                </p>
              )
            })}
          </div>
        ) : (
          <div className={tooltipClasses}>{children}</div>
        )}
      </div>
    )
  }
}

RankTooltip.propTypes = {
  children: PropTypes.element,
  tooltipSize: PropTypes.string,
  tooltipResponsive: PropTypes.string,
  className: PropTypes.string,
  fighterPage: PropTypes.bool,
  toolTipResponsive: PropTypes.bool,
  noEvents: PropTypes.bool,
  isArrow: PropTypes.bool,
}

RankTooltip.defaultProps = {
  toolTipResponsive: false,
  isArrow: false,
  noEvents: false,
  tooltipSize: '',
  tooltipResponsive: 'top',
}

export default RankTooltip
