import { market as marketTypes } from './types'

const setMarketPage = page => ({
  type: marketTypes.setMarketPage,
  payload: { page },
})

const setMarketSort = sort => ({
  type: marketTypes.setMarketSort,
  payload: { sort },
})

const setMarketGen = tab => ({
  type: marketTypes.setMarketGen,
  payload: { tab },
})

export { setMarketPage, setMarketSort, setMarketGen }
