import React from 'react'
import { Link } from 'react-router-dom'
import Modal from '../Modal'
import FighterCard from '../FighterCard'
import Button from '../Button'
import './style.css'

const styles = {
  rewardModal: {
    background: '#fff',
    padding: '25px 45px',
    borderRadius: '8px',
    textAlign: 'center',
  },
  fighterCardContainer: {
    padding: '0px 40px',
    width: '300px',
  },
  title: {
    fontWeight: 700,
    fontSize: '20px',
    margin: '0px',
  },
  line: {
    height: '3px',
    width: '40px',
    marginTop: '10px',
    display: 'inline-block',
  },
}

const RewardModal = ({ fighter, close, open }) => (
  <Modal hide={!open} close={close}>
    <div style={styles.rewardModal}>
      <p style={styles.title}>New Fighter!</p>
      <div style={{ ...styles.line, background: fighter.color.primary }} />
      <div style={styles.fighterCardContainer}>
        <FighterCard cardStyle={{ minHeight: '0px' }} disabled noAnimation {...fighter} />
      </div>
      <Link
        to={`/fighter/` + fighter.id}
        className="link"
        onClick={() => this.setState({ hide: true })}
      >
        <Button style={{ background: fighter.color.primary, textAlign: 'center' }}>
          VIEW FIGHTER
        </Button>
      </Link>
    </div>
  </Modal>
)

export default RewardModal
