import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Grid, Row, Col } from 'react-bootstrap'
import getWeb3 from '../../utils/getWeb3'
import api from '../../utils/api'
import { setActiveTabId } from '../../actions'
import BattleStats from '../../components/BattleStats'
import Tabs from '../../components/Tabs'
import tabsHeader from '../commonTabs'

const styles = {
  header: {
    height: 16,
    fontSize: 14,
    lineHeight: 1.14,
    textAlign: 'center',
    color: '#9b9b9b',
    flexBasis: 0,
    marginTop: 8,
    marginBottom: 8,
  },
  items: {
    flexBasis: 0,
    alignSelf: 'center',
  },
}

class Leaderboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: null,
      stats: {
        wins: '0',
        losses: '0',
        winRate: '0',
      },
    }
  }

  componentDidMount() {
    getWeb3()
      .then(
        web3 =>
          new Promise((resolve, reject) =>
            web3.eth.getAccounts((error, accounts) =>
              error
                ? reject(error)
                : web3.eth.getBlockNumber((error, blockNumber) =>
                    error ? reject(error) : resolve({ user: accounts[0], blockNumber })
                  )
            )
          )
      )
      .then(({ user, blockNumber }) =>
        user == null ? this.redirectToLocked() : this.setState({ user, blockNumber })
      )
      .then(() => {
        this.getBattleStats()
      })
    this.props.setActiveTabId(2)
  }

  redirectToLocked = () =>
    this.props.history.push('/locked', { from: this.props.location.pathname })

  getBattleStats = () =>
    api.getBattleStats(this.state.user).then(data => {
      const battlesFought = data.wins + data.losses

      const winRate = battlesFought ? parseInt((data.wins * 100) / battlesFought, 10) : 0

      data &&
        this.setState({
          stats: {
            wins: data.wins,
            losses: data.losses,
            winRate,
          },
        })
    })

  renderSwords(style) {
    return (
      <img
        alt="swords"
        src={require('../../images/leaderboard/swords.png')}
        style={{
          width: 20,
          height: 20,
          ...style,
        }}
      />
    )
  }

  renderTopUser({ rank, address, wins, backgroundColor, imageSrc }) {
    return (
      <div
        className="leaderboard-top-user"
        style={{
          width: 315,
          height: 136,
          borderRadius: 8,
          backgroundColor,
          boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
          flexGrow: 1,
          marginLeft: 12,
          marginRight: 12,
          position: 'relative',
          display: 'flex',
        }}
      >
        <div style={{ position: 'absolute' }}>
          <span>
            {this.renderSwords({
              marginLeft: 20,
              marginTop: 16,
            })}{' '}
          </span>
          <span
            style={{
              fontSize: 16,
              fontWeight: 'bold',
              color: '#030f23',
            }}
          >
            {rank}
          </span>
        </div>

        <div
          style={{
            height: 40,
            borderRadius: 26.5,
            backgroundColor: '#ffffff',
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
            marginLeft: 30,
            marginRight: 34,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'flex',
            alignSelf: 'center',
            position: 'relative',
            zIndex: 10,
          }}
        >
          <span
            style={{
              fontSize: 16,
              fontWeight: 500,
              lineHeight: 1.0,
              color: '#000000',
              paddingLeft: 24,
              paddingRight: 24,
              alignSelf: 'center',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            <Link
              style={{
                color: '#000000',
                textDecoration: 'none',
              }}
              to={`/my-fighters/${address}`}
            >
              {address}
            </Link>
          </span>
        </div>

        <div
          style={{
            height: 24,
            fontSize: 24,
            fontWeight: 900,
            lineHeight: 1.0,
            textAlign: 'center',
            color: 'rgba(255, 255, 255, 0.3)',
            position: 'absolute',
            bottom: 0,
            marginLeft: 30,
            marginBottom: 8,
          }}
        >
          <span
            style={{
              color: '#ffffff',
            }}
          >
            {wins}
          </span>{' '}
          WINS
        </div>
        <img
          src={imageSrc}
          alt=""
          style={{
            align: 'right',
            width: 184,
            height: 156,
            position: 'absolute',
            bottom: 0,
            right: 0,
          }}
        />
      </div>
    )
  }

  renderTop3(users) {
    return (
      <div
        style={{
          display: 'flex',
          marginBottom: 24,
        }}
      >
        {users[0] &&
          this.renderTopUser({
            rank: 1,
            address: users[0].address,
            wins: users[0].wins,
            backgroundColor: '#f1c500',
            imageSrc: require('../../images/leaderboard/gold-fighter.png'),
          })}
        {users[1] &&
          this.renderTopUser({
            rank: 2,
            address: users[1].address,
            wins: users[1].wins,
            backgroundColor: '#c7c7c9',
            imageSrc: require('../../images/leaderboard/silver-fighter.png'),
          })}
        {users[2] &&
          this.renderTopUser({
            rank: 3,
            address: users[2].address,
            wins: users[2].wins,
            backgroundColor: '#d3a05b',
            imageSrc: require('../../images/leaderboard/bronze-fighter.png'),
          })}
      </div>
    )
  }

  renderLeaderboard(users) {
    return (
      <div style={{ textAlign: 'center' }}>
        <div style={{ display: 'flex' }}>
          <span style={{ ...styles.header, flexGrow: 1 }}>RANK</span>
          <span style={{ ...styles.header, flexGrow: 2 }}>USER</span>
          <span style={{ ...styles.header, flexGrow: 1 }}>WINS</span>
        </div>
        {users.map(({ address, wins, rank }) => {
          return (
            <div
              key={address}
              style={{
                height: 64,
                borderRadius: 8,
                backgroundColor: '#ffffff',
                border: 'solid 1px #eeeeee',
                marginBottom: 4,

                fontSize: 16,
                fontWeight: 500,
                lineHeight: 1.0,
                color: '#000000',
                display: 'flex',
              }}
            >
              <span style={{ ...styles.items, flexGrow: 1 }}>
                {this.renderSwords({
                  marginLeft: 24,
                  marginRight: 8,
                })}
                {rank}
              </span>
              <span
                style={{
                  ...styles.items,
                  flexGrow: 2,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  paddingLeft: 30,
                  paddingRight: 30,
                  borderRight: 'solid #eeeeee 1px',
                  borderLeft: 'solid #eeeeee 1px',
                }}
              >
                <Link
                  style={{
                    color: '#000000',
                    textDecoration: 'none',
                  }}
                  to={`/my-fighters/${address}`}
                >
                  {address}
                </Link>
              </span>
              <span style={{ ...styles.items, flexGrow: 1 }}>{wins}</span>
            </div>
          )
        })}
      </div>
    )
  }

  renderMain() {
    const { users } = this.props

    if (!users.length) return <p>No battles have been fought yet.</p>

    return (
      <div>
        <div className="row">
          <div className="col-xs-12">{this.renderTop3(users.slice(0, 3))}</div>
        </div>

        <div className="row">
          <div className="col-md-8 col-md-offset-2">{this.renderLeaderboard(users.slice(3))}</div>
        </div>
      </div>
    )
  }

  render() {
    const { loading } = this.props
    const { user, stats } = this.state

    return (
      <div className="leaderboards-section">
        <Grid>
          <Tabs address={user} className="tabs-center" tabsHeader={tabsHeader} />
          <BattleStats stats={stats} />
          <Row
            style={{
              marginTop: 56,
            }}
          >
            <Col xs={12}>
              {loading ? <p style={{ textAlign: 'center' }}>Loading...</p> : this.renderMain()}
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

Leaderboard.props = {
  loading: PropTypes.bool.isRequired,
  users: PropTypes.arrayOf(PropTypes.object),
}

Leaderboard.propsTypes = {
  setActiveTabId: PropTypes.func.isRequired,
}

const mapStateToProps = ({ tabs }) => ({
  activeTabId: tabs.activeTabId,
})

export default connect(mapStateToProps, {
  setActiveTabId,
})(Leaderboard)
