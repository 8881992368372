import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import { BattleStat } from './'
import './style.css'

const BattleStats = ({ stats: { wins, losses, winRate }, marginTop }) => (
  <div className="battle-stats-container">
    <Row className="battle-stats-row">
      <Col className="battle-stats-col" xs={6} sm={3}>
        <BattleStat marginTop={marginTop} label="WINS" value={wins} />
      </Col>
      <Col className="battle-stats-col" xs={6} sm={3}>
        <BattleStat marginTop={marginTop} label="LOSSES" value={losses} />
      </Col>
      <Col className="battle-stats-col single-col" xs={6} sm={3}>
        <BattleStat marginTop={marginTop} symbolAfter="%" label="WIN RATE" value={winRate} />
      </Col>
    </Row>
  </div>
)

BattleStats.propTpes = {
  stats: PropTypes.shape({
    wins: PropTypes.string,
    losses: PropTypes.string,
    winRate: PropTypes.number,
    // record: PropTypes.oneOfType(PropTypes.string),
  }).isRequired,
  marginTop: PropTypes.number,
}

BattleStats.defaultProps = {
  stats: {
    wins: 0,
    losses: 0,
    winRate: 0,
    // record: '0',
  },
  marginTop: 56,
}

export default BattleStats
