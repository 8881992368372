import React from 'react'

const ExhaustedCooldownIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 16">
    <g fill={color} fillRule="nonzero">
      <path d="M8 0C3.589 0 0 3.589 0 8c0 4.412 3.589 8 8 8s8-3.588 8-8c0-4.411-3.589-8-8-8zm0 14.4A6.407 6.407 0 0 1 1.6 8c0-3.529 2.871-6.4 6.4-6.4 3.529 0 6.4 2.871 6.4 6.4 0 3.529-2.871 6.4-6.4 6.4z" />
      <path d="M4.044 6.307l2.263-2.263.849.849-2.263 2.263z" />
      <path d="M7.156 6.307L4.893 4.044l-.849.849 2.263 2.263zM8.844 6.307l2.263-2.263.849.849-2.263 2.263z" />
      <path d="M11.956 6.307L9.693 4.044l-.849.849 2.263 2.263zM8 8a2.403 2.403 0 0 0-2.4 2.4c0 1.323 1.077 2.4 2.4 2.4s2.4-1.077 2.4-2.4S9.323 8 8 8zm0 3.2a.8.8 0 0 1 0-1.6.8.8 0 0 1 0 1.6z" />
    </g>
  </svg>
)

export default ExhaustedCooldownIcon
