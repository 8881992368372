import React from 'react'

const styles = {
  button: {
    padding: '13px 25px',
    margin: 15,
    borderRadius: 2,
    color: '#fff',
  },
}

const Button = ({ onClick, style, children }) => (
  <div
    style={{
      ...styles.button,
      ...style,
    }}
    onClick={onClick}
  >
    {children}
  </div>
)

export default Button
