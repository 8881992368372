import React from 'react'
import PropTypes from 'prop-types'
import Progress from '../Progress'
import FighterSubtitle from './FighterSubtitle'

const styles = {
  level: {
    margin: 0,
    marginBottom: 10,
    fontWeight: 100,
  },
  text: {
    color: '#9B9B9B',
    fontSize: 12,
  },
}

const Level = ({
  level,
  exp,
  maxExp,
  minExp,
  expGained,
  color,
  showTextLevel,
  showBattleResultExp,
  showExp,
}) => {
  const fill = (exp - minExp) / (maxExp - minExp) * 100

  return (
    <div style={{ width: '100%' }} className="fadeInRight">
      {showTextLevel && <FighterSubtitle color={color}>LEVEL</FighterSubtitle>}
      <h1 className="level-title" style={styles.level}>
        {level}
      </h1>
      <Progress progress={fill} color={color} />
      {showExp && (
        <p style={styles.text}>
          {showBattleResultExp && exp > 0 && <span>{maxExp - exp}xp to next level!</span>}
          {!showBattleResultExp && <span>+{expGained} exp</span>}
        </p>
      )}
    </div>
  )
}

Level.propTypes = {
  level: PropTypes.number.isRequired,
  exp: PropTypes.number,
  maxExp: PropTypes.number,
  minExp: PropTypes.number,
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  showTextLevel: PropTypes.bool,
  showBattleResultExp: PropTypes.bool,
}

Level.defaultProps = {
  color: null,
  exp: 0,
  maxExp: 0,
  minExp: 0,
  showTextLevel: true,
  showBattleResultExp: true,
  showExp: true,
}

export default Level
