import React from 'react'

const styles = {
  input: {
    marginTop: 8,
    height: 50,
    borderRadius: 30,
    backgroundColor: '#fff',
    border: 'solid 0.5px #eee',
    padding: 20,
    color: '#9b9b9b',
  },
  label: {
    fontSize: 14,
    letterSpacing: 0.1,
  },
  placeholderText: {
    position: 'relative',
    top: 38,
    left: 70,
    color: '#bdbdbd',
    pointerEvents: 'none',
  },
}

const AuctionFormInput = ({ label, value, onChange, placeholder }) => (
  <div style={{ flexDirection: 'column', display: 'flex' }}>
    <span style={styles.label}>{label}</span>
    <input style={styles.input} value={value} onChange={onChange} type="number" />
    {placeholder ? (
      <div style={{ position: 'absolute' }}>
        <div style={styles.placeholderText}>{placeholder}</div>
      </div>
    ) : null}
  </div>
)

export default AuctionFormInput
