import React from 'react'

const ProfileIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 14 18">
    <g fill="none" fillRule="evenodd">
      <path d="M-3-2h20v20H-3z" />
      <path
        fill={color}
        fillRule="nonzero"
        d="M7 11.333c-3.926 0-6.667 2.056-6.667 5V18h13.334v-1.667c0-2.944-2.741-5-6.667-5zM6.167 15.5a.833.833 0 1 1 0-1.667.833.833 0 0 1 0 1.667zm2.5 0a.833.833 0 1 1 0-1.667.833.833 0 0 1 0 1.667zM12 4.667h-.075A5.004 5.004 0 0 0 7 .5a5.004 5.004 0 0 0-4.925 4.167H2c-.92 0-1.667.746-1.667 1.666v.834c0 .92.747 1.666 1.667 1.666 0 .919.747 1.667 1.667 1.667h6.666c.92 0 1.667-.748 1.667-1.667.92 0 1.667-.746 1.667-1.666v-.834c0-.92-.747-1.666-1.667-1.666zM10.333 8H3.667V4.667h6.666V8z"
      />
    </g>
  </svg>
)

export default ProfileIcon
