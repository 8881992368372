import React from 'react'
import { FIGHTER_COLORS } from '../../consts'

const Banner = props => {
  return (
    <div
      style={{
        padding: '20px',
        background: FIGHTER_COLORS[3].secondary,
        color: FIGHTER_COLORS[3].primary,
        fontSize: '1rem',
        textAlign: 'center',
      }}
    >
      {props.children}
    </div>
  )
}

export default Banner
