import React, { Component } from 'react'
import BN from 'bignumber.js'
import { toast } from 'react-toastify'
import { FIGHTER_COLORS } from '../../consts'
import api from '../../utils/api'
import getWeb3, { getLatestBlockTimestamp } from '../../utils/getWeb3'
import { getAuction, getCurrentAuctionPrice, bid, cancelAuction } from '../../utils/web3Auctions'
import ViewFighter from '../../components/ViewFighter'
import BounceLoader from '../../components/loaders/BounceLoader'
import { UPDATE_AUCTION_PRICE_INTERVAL } from '../../consts'
import { trackClickPurchase } from '../../utils/analytics'
import { getTimeTillCooldown, isOwner } from '../../utils/fighter'

class Fighter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fighter: null,
      auction: null,
      bidPlaced: null,
      bidLoading: null,
      currentPrice: null,
      currentPriceEth: null,
      stats: {
        wins: '0',
        losses: '0',
      },
    }

    this.timeoutHandle = null
  }

  getFighterId = () => parseInt(this.props.match.params.id, 10)

  updateAuctionPrice = fighterId =>
    getCurrentAuctionPrice(fighterId).then(async currentPrice =>
      this.setState({
        currentPrice,
      })
    )

  componentDidMount() {
    const fighterId = this.getFighterId()

    getWeb3()
      .then(
        web3 =>
          new Promise((resolve, reject) =>
            web3.eth.getAccounts((error, accounts) =>
              error
                ? reject(error)
                : web3.eth.getBlockNumber((error, blockNumber) =>
                    error ? reject(error) : resolve({ user: accounts[0], blockNumber })
                  )
            )
          )
      )
      .then(({ user, blockNumber }) =>
        this.setState({ currentUser: user, blockNumber, cooldownEndTime: blockNumber })
      )
      .then(() => api.getFighter(fighterId))
      .then(fighter => {
        const newState = {
          fighter: {
            ...fighter,
            genesHex: new BN(fighter.genes).toString(16),
            color: FIGHTER_COLORS[fighter.color],
            isOwner: isOwner(fighter, this.state.currentUser),
            id: fighter.fighterId,
            timeTillBattleCooldown: getTimeTillCooldown(
              fighter.battleCooldownEndTime,
              this.state.blockNumber
            ),
            cooldownEndTime: this.state.cooldownEndTime,
            timeTillPrizeCooldown: getTimeTillCooldown(
              fighter.prizeCooldownEndTime,
              this.state.blockNumber
            ),
          },
        }
        if (fighter.battlesWon != null && fighter.battlesFought != null) {
          const losses = fighter.battlesFought - fighter.battlesWon
          const winRate = !fighter.battlesFought
            ? 0
            : Math.round((fighter.battlesWon / fighter.battlesFought) * 100)

          newState.stats = {
            wins: fighter.battlesWon,
            losses,
            winRate,
          }
        }
        this.setState(newState)
        this.getCurrentTime()
      })
      .then(this.getAuction)
  }

  componentWillUnmount() {
    clearInterval(this.timeoutHandle)
  }

  redirectToLocked = () =>
    this.props.history.push('/locked', { from: this.props.location.pathname })

  getAuction = () => {
    const fighterId = this.getFighterId()

    return getAuction(fighterId).then(auction => {
      if (!auction || auction.seller === '0x') return

      if (!auction.startingPrice && auction.endingPrice && auction.duration) return

      this.setState({
        auction,
        fighter: {
          ...this.state.fighter,
          owner: auction.seller,
          isOwner: auction.seller === this.state.currentUser,
        },
      })

      this.updateAuctionPrice(fighterId)

      this.timeoutHandle = setInterval(() => {
        this.updateAuctionPrice(fighterId)
      }, UPDATE_AUCTION_PRICE_INTERVAL)
    })
  }

  placeBid = () => {
    const fighterId = this.getFighterId()

    trackClickPurchase(fighterId, this.state.currentPrice)

    Promise.resolve()
      .then(() => this.setState({ bidLoading: true }))
      .then(() => bid(fighterId, this.state.currentPrice))
      .then(success => {
        if (success) {
          this.setState({
            bidPlaced: true,
            bidLoading: false,
            fighter: { ...this.state.fighter, owner: this.state.currentUser, isOwner: true },
          })
          toast.success('Congratulations! You are now the owner of this fighter', {
            className: `toast-${this.state.fighter.color.name}`,
          })
        } else {
          this.setState({ bidLoading: false })
          toast.error('There was a problem with your bid. You do not own this fighter...', {
            className: 'toast-error',
          })
        }
      })
      .catch(() => {
        this.setState({ bidLoading: false })
        toast.error('There was a problem with your bid. You do not own this fighter...', {
          className: 'toast-error',
        })
      })
  }

  cancelAuction = () => {
    const fighterId = this.getFighterId()
    return Promise.resolve()
      .then(() => this.setState({ bidLoading: true }))
      .then(() => cancelAuction(fighterId))
      .then(success => {
        if (success) {
          this.setState({
            bidPlaced: true,
            bidLoading: false,
            fighter: { ...this.state.fighter, owner: this.state.currentUser, isOwner: true },
          })
          toast.success('Your fighter is no longer on auction', {
            className: `toast-${this.state.fighter.color.name}`,
          })
        } else {
          this.setState({ bidLoading: false })
          toast.error(
            'There was a problem with your transaction. This fighter is still on auction...',
            {
              className: 'toast-error',
            }
          )
        }
      })
      .catch(() => {
        this.setState({ bidLoading: false })
        toast.error(
          'There was a problem with your transaction. This fighter is still on auction...',
          {
            className: 'toast-error',
          }
        )
      })
  }

  // this could cause an issue in different timezones
  // we really need to get the current time from the blockchain
  //
  getAuctionEnd = auction => {
    if (!auction) return

    const { startedAt, duration } = auction

    const endAt = startedAt + duration

    return endAt

    // return new Date(endAt * 1000)
  }

  getCurrentTime = () => {
    getLatestBlockTimestamp(currentTime => {
      this.setState({ currentTime })
    })
  }

  render() {
    const { fighter, auction, bidPlaced, bidLoading, currentPrice, stats } = this.state

    let fullAuction

    if (auction) {
      fullAuction = {
        ...auction,
        currentTime: this.state.currentTime,
        endAt: this.getAuctionEnd(auction),
      }
    }

    return this.state.fighter ? (
      <div className="container">
        <ViewFighter
          {...fighter}
          auction={fullAuction}
          placeBid={this.state.currentUser == null ? this.redirectToLocked : this.placeBid}
          cancelAuction={this.cancelAuction}
          bidPlaced={bidPlaced}
          bidLoading={bidLoading}
          currentPrice={currentPrice}
          stats={stats}
          disableChart={!this.state.currentUser}
        />
      </div>
    ) : (
      <div
        style={{
          display: 'flex',
          height: 400,
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <BounceLoader size={60} />
        <div style={{ marginTop: 30, color: 'rgb(204, 204, 204)', fontSize: 16 }}>
          Loading Fighter
        </div>
      </div>
    )
  }
}

export default Fighter
