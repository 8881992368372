import React from 'react'

const styles = {
  container: {
    display: 'inline-block',
    position: 'relative',
    top: '-1px',
  },
}

const DownArrow = () => (
  <div style={styles.container}>
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6">
      <path
        fill="#2196F3"
        fillRule="nonzero"
        d="M5 6a.728.728 0 0 1-.527-.228L0 1.1 1.054 0 5 4.12 8.946 0 10 1.1 5.527 5.773A.728.728 0 0 1 5 6z"
      />
    </svg>
  </div>
)

export default DownArrow
