import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import FAQContainer from '../../components/FAQContainer'
import FAQSidebar from '../../components/FAQSidebar'
import { GETTING_STARTED } from './consts'

const styles = {
  container: {
    marginTop: 56,
    marginBottom: 56,
  },
  titleContainer: {
    marginBottom: 24,
    textAlign: 'center',
  },
  title: {
    margin: 0,
    color: '#030f23',
  },
  seperator: {
    display: 'inline-block',
    height: '2px',
    width: '40px',
    background: '#2196f3',
  },
}

class GettingStarted extends Component {
  render() {
    return (
      <div style={styles.container}>
        <div style={styles.titleContainer}>
          <h1 style={styles.title}>Getting Started</h1>
          <div style={styles.seperator} />
        </div>
        <Row>
          <Col sm={3} smOffset={1} xsHidden>
            <FAQSidebar links={GETTING_STARTED} />
          </Col>
          <Col sm={7} smOffset={0} xsHidden>
            <FAQContainer content={GETTING_STARTED} id="getting-started" />
          </Col>
          <Col xs={12} smHidden mdHidden lgHidden>
            <FAQContainer xs content={GETTING_STARTED} id="getting-started" />
          </Col>
        </Row>
      </div>
    )
  }
}

export default GettingStarted
