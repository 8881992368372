import React, { Component } from 'react'
import FighterSubtitle from './FighterSubtitle'
import api from '../../utils/api'

class Genes extends Component {
  constructor() {
    super()

    this.state = {
      rarity: {},
    }
  }

  componentDidMount() {
    api.getRarity().then(result => {
      this.setState({ rarity: result[0] })
    })
  }

  render() {
    const { genes, color } = this.props

    if (!genes) return null

    return (
      <div>
        <FighterSubtitle color={color}>GENES</FighterSubtitle>
        {genes.map((gene, i) => {
          const percent = this.state.rarity[gene]

          return (
            <Gene key={i} color={color}>
              {`${gene} ${percent ? ` (${percent}%)` : ''}`}
            </Gene>
          )
        })}
      </div>
    )
  }
}

class Gene extends Component {
  constructor() {
    super()
    this.state = {
      rare: false,
    }
  }

  rareStyles() {
    if (this.state.rare) {
      let secondaryColor

      if (this.props.color === '#4caf50') {
        secondaryColor = '#3dcb43'
      } else if (this.props.color === '#2196f3') {
        secondaryColor = '#5eadec'
      } else if (this.props.color === '#ffa000') {
        secondaryColor = '#fabc54'
      } else if (this.props.color === '#e91e63') {
        secondaryColor = '#f05f90'
      } else if (this.props.color === '#757575') {
        secondaryColor = '#9d9999'
      } else if (this.props.color === '#6b3fb9') {
        secondaryColor = '#8a4bf7'
      } else if (this.props.color === '#9e9d24') {
        secondaryColor = '#b4b32a'
      }

      return {
        backgroundSize: '200% 100%',
        backgroundPosition: '200% 50%',
        animation: 'gradient 3s linear infinite',
        background:
          'linear-gradient( 45deg, ' +
          this.props.color +
          ' 20%, ' +
          secondaryColor +
          ' 40%, ' +
          secondaryColor +
          ' 60%, ' +
          this.props.color +
          ' 80%)',
      }
    }
  }

  componentWillMount() {
    if (this.props.children.charAt(this.props.children.length - 1) === '+') {
      this.setState({ rare: true })
    }
  }

  render() {
    return (
      <div className="fadeInLeft noselect" style={{ display: 'inline-block' }}>
        <div
          style={{
            margin: 2,
            background: this.props.color,
            padding: '8px 15px',
            width: 'fit-content',
            borderRadius: 50,
            // ...this.rareStyles(),
          }}
        >
          <p style={{ margin: 0, color: '#fff', fontSize: 12 }}>{this.props.children}</p>
        </div>
      </div>
    )
  }
}

export default Genes
