import React from 'react'
import { LeaderBadge } from './'

const styles = {
  container: {
    width: '100%',
    height: 240,
    borderRadius: 8,
    backgroundColor: '#fff',
    border: 'dashed 2px #dddddd',
    marginTop: 8,
    marginBottom: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
  },
  text: {
    fontSize: 14,
    lineHeight: 1.14,
    color: '#9b9b9b',
    paddingTop: 12,
  },
}

const Plus = () => (
  <svg fill="#bdbdbd" height="40" width="40" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
)

const EmptyMember = ({ noLeftSpace, noRightSpace, disabled, onClick, edit, first }) => (
  <div
    style={{
      width: '100%',
      marginLeft: noLeftSpace ? 0 : 12,
      marginRight: noRightSpace ? 0 : 12,
    }}
  >
    <div
      style={{
        ...styles.container,
        position: 'relative',
        cursor: disabled ? '' : 'pointer',
      }}
      onClick={disabled ? null : onClick}
    >
      {!disabled && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {edit ? null : <Plus />}
          <div style={styles.text}>{first ? 'Add your lead fighter' : 'Add a fighter'}</div>
        </div>
      )}
    </div>
    {first && <LeaderBadge />}
  </div>
)

export default EmptyMember
