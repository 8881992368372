import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import SocialLinks from './SocialLinks'
import FooterLogo from '../../images/img-logo-footer.svg'
import './footer.css'

const styles = {
  footer: {
    paddingTop: 30,
    paddingBottom: 60,
    backgroundColor: '#fff',
    textAlign: 'center',
    width: '100%',
    borderTop: '1px solid #eee',
  },
  title: {
    fontSize: 16,
    color: '#030f23',
    margin: '10px 0px',
  },
  text: {
    margin: 0,
    marginBottom: 7,
    color: '#9B9B9B',
    fontSize: 14,
    textDecoration: 'none',
  },
  link: {
    textDecoration: 'none',
  },
  footerLogo: {
    marginBottom: 8,
  },
}

class Footer extends Component {
  renderLink({ link, label }) {
    if (link.startsWith('http') || link.startsWith('mailto')) {
      return (
        <a href={link} style={styles.link} key={link}>
          <p style={styles.text}>{label}</p>
        </a>
      )
    } else {
      return (
        <Link to={link} style={styles.link} key={link}>
          <p style={styles.text}>{label}</p>
        </Link>
      )
    }
  }

  renderFooterSection(title, links) {
    return (
      <div className="footerSection">
        <h5 style={styles.title}>{title}</h5>
        {links.map(({ link, label }) => {
          return this.renderLink({ link, label })
        })}
      </div>
    )
  }

  render() {
    const legal = [
      { link: '/terms-of-service', label: 'Terms of Service' },
      { link: '/privacy-policy', label: 'Privacy Policy' },
    ]

    const info = [
      { link: '/getting-started', label: 'Getting Started' },
      /*{ link: '/white-paper', label: 'White Paper' },*/
      {
        link:
          'https://medium.com/cryptofighters/cryptofighters-battle-on-the-blockchain-c7f821f91637',
        label: 'Bounty Program',
      },
      {
        link: 'https://etherscan.io/address/0x87d598064c736dd0c712d329afcfaa0ccc1921a1',
        label: 'Main Contract (Verified)',
      },
      {
        link: 'https://etherscan.io/address/0x7192bb75777dab47ef6fbf6f6c0e4bcbb2294f38',
        label: 'Sales Contract (Verified)',
      },
      {
        link: 'https://etherscan.io/address/0x9106708eb08a6fca144bcb45f8bcd020f903c0f5',
        label: 'Battle Contract (Verified)',
      },
      { link: '/leaderboard', label: 'Leaderboard' },
    ]

    return (
      <div style={styles.footer}>
        <div style={{ display: 'inline-block', textAlign: 'left' }}>
          <div className="footerSection">
            <img style={styles.footerLogo} src={FooterLogo} alt="CryptoFighters" />
            <p style={styles.text}>© CryptoFighters 2018-{new Date().getFullYear()}</p>
          </div>
          {this.renderFooterSection('Legal', legal)}
          <div className="footerBreak" />
          {this.renderFooterSection('Info', info)}
          <div className="footerSection footerSocialSection">
            <h5 style={styles.title}>Social</h5>
            <SocialLinks />
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
