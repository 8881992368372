const tabsHeader = [
  {
    id: 0,
    link: '/arena',
    text: 'the arena',
    key: 'arena',
  },
  {
    id: 1,
    link: '/arena/history',
    text: 'battle history',
    key: 'battle-history',
  },
  {
    id: 2,
    link: '/leaderboard',
    text: 'leaderboard',
    key: 'leaderboard',
  },
]

export default tabsHeader
