import React from 'react'
import TeamRow from '../TeamRow'

const TeamsList = ({ teams, onClickFight, removeTeam, emptyMessage }) =>
  teams && teams.length ? (
    <div>
      {teams.map(team => (
        <TeamRow
          key={team.teamId}
          {...team}
          onClickFight={() => onClickFight(team.teamId)}
          removeTeam={() => removeTeam(team.teamId)}
        />
      ))}
    </div>
  ) : (
    emptyMessage || null
  )

export default TeamsList
