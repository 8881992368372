import React from 'react'
import { PopulatedMember, EmptyMember } from './'

const FighterTeamMember = ({
  fighter,
  noLeftSpace,
  noRightSpace,
  first,
  onClickEmpty,
  onClickPopulated,
  removeFighter,
  small,
  edit,
  disabled,
}) =>
  fighter ? (
    <PopulatedMember
      fighter={fighter}
      first={first}
      noLeftSpace={noLeftSpace}
      noRightSpace={noRightSpace}
      onClick={onClickPopulated}
      removeFighter={removeFighter}
      small={small}
      edit={edit}
      disabled={disabled}
    />
  ) : (
    <EmptyMember
      first={first}
      noLeftSpace={noLeftSpace}
      noRightSpace={noRightSpace}
      onClick={onClickEmpty}
      edit={edit}
      disabled={disabled}
    />
  )

export default FighterTeamMember
