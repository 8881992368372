import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { BattleIcon, MarketIcon, MyFightersIcon, ProfileIcon } from '../Icons'
import ComingSoon from '../ComingSoon'
import './styles.css'

const styles = {
  container: {
    height: '100%',
    textAlign: 'center',
    display: 'inline-block',
    position: 'relative',
  },
  inner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 100,
  },
  link: {
    width: 110,
    height: '100%',
    textDecoration: 'none',
    color: '#fff',
    fontSize: 12,
  },
  bottomBar: {
    width: '100%',
    height: 4,
    background: '#2196f3',
    position: 'absolute',
    bottom: 0,
  },
}

class HeaderLink extends Component {
  getIcon() {
    if (this.props.icon === 'my-fighters') {
      return <MyFightersIcon color={this.props.active ? '#2196f3' : 'rgba(255, 255, 255, 0.3)'} />
    } else if (this.props.icon === 'market') {
      return <MarketIcon color={this.props.active ? '#2196f3' : 'rgba(255, 255, 255, 0.3)'} />
    } else if (this.props.icon === 'profile') {
      return <ProfileIcon color={this.props.active ? '#2196f3' : 'rgba(255, 255, 255, 0.3)'} />
    } else if (this.props.icon === 'battle') {
      return <BattleIcon color={this.props.active ? '#2196f3' : 'rgba(255, 255, 255, 0.3)'} />
    }
  }

  render() {
    return this.props.disabled ? (
      <a style={styles.link} className="noselect" href="/">
        <div className="header-link-container" style={{ ...styles.container, ...this.props.style }}>
          <div style={styles.inner}>
            {this.getIcon()}
            <p
              style={{
                margin: 0,
                marginTop: 5,
                color: this.props.active ? '#fff' : 'rgba(255, 255, 255, 0.3)',
              }}
            >
              {this.props.text}
            </p>
            {this.props.children}
          </div>
          {this.props.active ? <div style={styles.bottomBar} /> : <div />}
          {this.props.comingSoon ? <ComingSoon xs={this.props.xs} /> : null}
        </div>
      </a>
    ) : (
      <Link to={this.props.to} style={styles.link}>
        <div className="header-link-container" style={{ ...styles.container, ...this.props.style }}>
          <div style={styles.inner}>
            {this.getIcon()}
            <p
              style={{
                margin: 0,
                marginTop: 5,
                color: this.props.active ? '#fff' : 'rgba(255, 255, 255, 0.3)',
              }}
            >
              {this.props.text}
            </p>
            {this.props.children}
          </div>
          {this.props.active ? <div style={styles.bottomBar} /> : <div />}
          {this.props.comingSoon ? <ComingSoon /> : null}
        </div>
      </Link>
    )
  }
}

export default HeaderLink
