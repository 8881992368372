import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Home from './scenes/Home'
import MyFighters from './scenes/MyFighters'
import Fighter from './scenes/Fighter'
import CreateAuction from './scenes/CreateAuction'
import Market from './scenes/Market'
import Locked from './scenes/Locked'
import Arena from './scenes/Arena'
import ArenaResults from './scenes/ArenaResults'
import BattleHistoryContainer from './scenes/BattleHistory/BattleHistoryContainer'
import Leaderboard from './scenes/Leaderboard'
// import ConfirmFight from './scenes/ConfirmFight'
import GettingStarted from './scenes/GettingStarted'
import TermsOfService from './scenes/TermsOfService'
import PrivacyPolicy from './scenes/PrivacyPolicy'
import Error from './scenes/Error'
import LandingHeader from './components/LandingHeader'
import Header from './components/Header'
import Footer from './components/Footer'
import ScrollToTop from './components/ScrollToTop'

class Router extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <ScrollToTop>
            <Route
              exact
              path="/"
              children={({ location }) => (
                <div>
                  {location.pathname.split('/')[1] === '' ? (
                    <LandingHeader />
                  ) : (
                    <Header page={location.pathname.split('/')[1]} />
                  )}
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/my-fighters" component={MyFighters} />
                    <Route exact path="/my-fighters/:addr" component={MyFighters} />
                    <Route exact path="/fighter/:id" component={Fighter} />
                    <Route exact path="/fighter/:id/sell" component={CreateAuction} />
                    <Route exact path="/market" component={Market} />
                    <Route exact path="/locked" component={Locked} />
                    <Route exact path="/arena" component={Arena} />
                    <Route exact path="/arena/results/:id" component={ArenaResults} />
                    <Route
                      exact
                      path="/arena/history/:address"
                      component={BattleHistoryContainer}
                    />
                    <Route exact path="/leaderboard" component={Leaderboard} />
                    {/* <Route exact path="/arena/confirm" component={ConfirmFight} /> */}
                    <Route path="/getting-started" component={GettingStarted} />
                    <Route path="/terms-of-service" component={TermsOfService} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/*" component={Error} />
                  </Switch>
                  <Footer />
                </div>
              )}
            />
          </ScrollToTop>
        </BrowserRouter>
      </div>
    )
  }
}

export default Router
