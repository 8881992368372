import React, { Component } from 'react'
import CrownIcon from '../Header/components/Icons/CrownIcon'
import InfoIcon from '../InfoIcon'
import Tooltip from '../Tooltip'

class LeaderBadge extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showTooltip: false,
    }
  }

  toggleTooltip(bool) {
    this.setState({ showTooltip: bool })
  }

  render() {
    return (
      <div
        style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          bottom: 20,
        }}
      >
        <div
          style={{
            position: 'absolute',
            padding: 3,
            backgroundColor: '#fff',
            width: '75%',
            textAlign: 'center',
            color: this.props.color || '#757575',
            fontWeight: 'bold',
            fontSize: 14,
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: this.props.populated ? '0 1px 2px 0 rgba(0, 0, 0, 0.1)' : null,
            borderRadius: 2,
          }}
        >
          <CrownIcon color={this.props.color || '#bababa'} size={12} />
          <div style={{ paddingLeft: '5%', paddingRight: '10%' }}>LEADER</div>
          <div
            onMouseEnter={() => this.toggleTooltip(true)}
            onMouseLeave={() => this.toggleTooltip(false)}
          >
            <InfoIcon color={this.props.color} />
            <Tooltip
              className="leaderTooltip"
              hide={!this.state.showTooltip}
              title="Lead Fighter"
              style={{ width: '260px', overflow: 'auto' }}
            >
              This fighter will have a chance to pass their genes along to an offspring after
              winning a fight.
            </Tooltip>
          </div>
        </div>
      </div>
    )
  }
}

export default LeaderBadge
