import React, { Component } from 'react'

class Cart extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
        <g fill={this.props.color} fillRule="nonzero">
          <path d="M9.714 0a.579.579 0 0 0-.557.465L8.6 3H1.143a.561.561 0 0 0-.451.23.623.623 0 0 0-.104.515l1.143 4.8A.578.578 0 0 0 2.286 9H8a.58.58 0 0 0 .557-.465L10.17 1.2H12V0H9.714z" />
          <ellipse cx="2.857" cy="10.8" rx="1.143" ry="1.2" />
          <ellipse cx="7.429" cy="10.8" rx="1.143" ry="1.2" />
        </g>
      </svg>
    )
  }
}

export default Cart
