import React, { Component } from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
import ReactModal from 'react-modal'
import _ from 'lodash'
import MainAction from '../buttons/MainAction'
import FighterTeam from '../FighterTeam'
import FighterGallery from '../FighterGallery'
import BattleIcon from '../Header/components/Icons/BattleIcon'
import closeIcon from '../../images/close-icon.svg'

const styles = {
  container: {
    borderRadius: 8,
    padding: 0,
    backgroundColor: '#fff',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  header: {
    textAlign: 'center',
    paddingTop: 2,
    fontSize: 24,
    fontWeight: 'bold',
    color: '#030f23',
  },
  headerDivider: {
    width: 40,
    height: 2,
    backgroundColor: '#2196f3',
    marginTop: 16,
  },
}

class FighterTeamModal extends Component {
  handleAfterOpen() {
    /*
      Function that will be run after the modal has opened.
    */
  }

  render() {
    const {
      open,
      onClose,
      team,
      fighters,
      addFighter,
      removeFighter,
      submitTeam,
      submitIsFight,
      onClickFight,
    } = this.props
    return (
      <ReactModal
        isOpen={open}
        onAfterOpen={this.handleAfterOpen}
        onRequestClose={onClose}
        closeTimeoutMS={0}
        style={{ overlay: styles.overlay, content: styles.container }}
        contentLabel="Pick Your Team"
        // portalClassName="ReactModalPortal"
        // overlayClassName="ReactModal__Overlay"
        // className="ReactModal__Content"
        // bodyOpenClassName="ReactModal__Body--open"
        ariaHideApp={false}
        shouldFocusAfterRender={true}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        // shouldReturnFocusAfterClose={true}
        // role="dialog"
        // parentSelector={() => document.body}
        // aria={{
        //   labelledby: "heading",
        //   describedby: "full_description"
        // }}
      >
        <div>
          <Grid fluid>
            <Row style={{ boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)', padding: 30 }}>
              <Col xs={12}>
                <Row>
                  <Col
                    xs={4}
                    xsOffset={4}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <div style={styles.header}>Pick Your Team</div>
                    <div style={styles.headerDivider} />
                  </Col>
                  <Col
                    xs={2}
                    xsOffset={2}
                    style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 0 }}
                  >
                    <div onClick={onClose} style={{ cursor: 'pointer' }}>
                      <img alt="close" src={closeIcon} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} style={{ marginTop: 50 }}>
                    <FighterTeam
                      team={team.map(id => _.find(fighters, ({ fighterId }) => fighterId === id))}
                      edit
                      removeFighter={removeFighter}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ padding: 30 }}>
              <Col xs={12}>
                <FighterGallery
                  noAnimation
                  addAction={team.length < 5}
                  disabled={team.length >= 5}
                  fighters={fighters.filter(({ fighterId }) => team.indexOf(fighterId) === -1)}
                  onClickAction={addFighter}
                />
              </Col>
            </Row>
          </Grid>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              left: 0,
              right: 0,
              bottom: 56,
              position: 'fixed',
            }}
          >
            <div style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px 0px' }}>
              {submitIsFight ? (
                <MainAction onClick={onClickFight}>
                  <div className="flex items-center justify-center">
                    <BattleIcon width={20} height={20} color={'#fff'} />
                    <span className="btn-text icon-left">FIGHT</span>
                  </div>
                </MainAction>
              ) : (
                <MainAction onClick={submitTeam}>SUBMIT TEAM</MainAction>
              )}
            </div>
          </div>
        </div>
      </ReactModal>
    )
  }
}

export default FighterTeamModal
