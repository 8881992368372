import React from 'react'
import { Link } from 'react-router-dom'

const styles = {
  bold: {
    fontWeight: 500,
  },
}

const GETTING_STARTED = [
  {
    title: 'What do you need to play CryptoFighters?',
    body: (
      <div>
        {'Use '}
        <a className="link" href="https://www.google.com/chrome/">
          Chrome
        </a>
        {' or '}
        <a className="link" href="https://www.mozilla.org/en-US/firefox/new/">
          Firefox
        </a>
        {' as your browser.'}

        <br />

        <span>
          Install{` `}
          <a className="link" href="https://metamask.io/">
            MetaMask
          </a>
          , a digital wallet used specifically with web apps.
        </span>

        <br />

        <span>Need help installing MetaMask? </span>
        <a className="link" href="https://youtu.be/tfETpi-9ORs">
          Watch this tutorial!
        </a>

        <br />

        <span>Get some Ether! That’s the currency we use to buy and battle on CryptoFighters.</span>
      </div>
    ),
  },
  {
    title: 'What is MetaMask?',
    body:
      'MetaMask is a digital wallet used to hold your money just like a bank account. All you need to do to unlock your MetaMask is type in your password. Make sure not to forget your password or seed words!',
  },
  {
    title: 'What is Ether?',
    body:
      'Ether acts like other currencies; the value changes with the market. The Ethereum network is what the Crypto Fighters are built on. You need to convert your currency (e.g. USD, CAD, GBP, etc.) into ether to pay for CryptoFighters',
  },
  {
    title: 'How do I get a CryptoFighter?',
    body: (
      <div>
        <p>
          Go to the{' '}
          <Link className="link" to="/market">
            Marketplace
          </Link>{' '}
          and look at the CryptoFighter for sale. Do you like his looks, stats and breed? Then get
          it! You guys were meant to be.
        </p>
        <p>Got them! Now what?</p>
        <p>
          Got one? Great! You can buy as many as you’d like. When we will launch the battle
          mechanism, you’ll be able to take them to WAR! Battles will be 5 fighters against 5, you
          will be able to fight with only one fighter but might wanna gear up!
        </p>
      </div>
    ),
  },
  {
    title: 'So, What’s CryptoFighters?',
    body:
      'CryptoFighters is a game centered around cryptographically unique collectible fighters on the Ethereum blockchain. Collect, battle and level up your fighters to win new fighters!',
  },
  {
    title: 'How do I get a CryptoFighter?',
    body:
      'Go to the “Marketplace” and look at the CryptoFighter for sale. Do you like his looks, stats and breed? Then get it! You guys were meant to be.',
  },
  {
    title: 'Got them! Now what?',
    body:
      'Got one? Great! You can buy as many as you’d like. When we will launch the battle mechanism, you’ll be able to take them to WAR! Battles will be 5 fighters against 5, you will be able to fight with only one fighter but might wanna gear up!',
  },
  {
    title: 'What to do if you don’t see your CryptoFighters?',
    body: (
      <p>
        A simple refreshing of the page should do the trick. If needed you can also contact us at{' '}
        <a className="link" href="mailto:hello@cryptofighters.io">
          hello@cryptofighters.io
        </a>
        !
      </p>
    ),
  },
  {
    title: 'What can CryptoFighters do?',
    body:
      'CryptoFighters battle! A battle is when CryptoFighters assemble in groups of up to 5 CryptoFighters owned by the same owner and fight a group of up to 5 CryptoFighters owned by another owner. The award for winning a battle is a new CryptoFighter and improved stats.',
  },
  {
    title: 'How do you win in Battle?',
    body:
      'The winner of the battle is determined by a combination of the CryptoFighters’ rank, level, and attributes of Strength, Vitality, Dexterity, and Luck. Battles are currently disabled to avoid inflating the market.',
  },
  // {
  //   title: 'What is rank?',
  //   body: 'Rank is based on the generation of the CryptoFighter.',
  // },
  // {
  //   title: 'What is level?',
  //   body: 'Level is based on experience that each CryptoFighter earns from fighting in Battles.',
  // },
  {
    title: 'Are there CryptoFighter Clans?',
    body: 'Currently not, but this feature is planned to be released in the coming months.',
  },

  {
    title: 'Can I make money playing CryptoFighters?',
    body:
      `Yes, this is possible. If you buy a fighter for cheap and sell him on for more you'll make money. ` +
      `Conversely, it's also possible to lose money. There is no guarantee someone will purchase a fighter you purchased. ` +
      `Once battles are released you'll also be able to win newly generated fighters on auction which can then be sold on. ` +
      `CryptoFighters are ultimately collectible fighters on the blockchain. We cannot advise on it as an investment and ` +
      `if you choose to view it as one you do so at your own risk.`,
  },

  {
    title: 'How many Gen0 Fighters will be released?',
    body: `There is a hard cap in the smart contract of 25,000 fighters which will be released over the course of the next year.`,
  },

  {
    title: 'How often are Gen0 Fighters created?',
    body: `No more Gen0 fighters will be minted.`,
  },

  {
    title: 'What about higher generation fighters?',
    body:
      `Higher generation fighters are essentially uncapped (a cap of 4 billion). ` +
      `These are awarded as prizes for winning battles. ` +
      `These are less rare than the Gen0 fighters and start off with lower base stats than the Gen0 fighters.`,
  },

  {
    title: 'How does CryptoFighters pay for development costs?',
    body: `We generate revenue from the sale of the Gen0 fighters we create as well as a 3.9% fee on all successful auction sales in our marketplace. The fee is taken from the seller.`,
  },

  {
    title: 'How many races are there?',
    body: `There are five races. Four originals and one special race.`,
  },

  {
    title: 'I have a question, how can I get in contact with you?',
    body: (
      <p>
        {`You can join us on `}
        <a href="https://t.me/cryptofighters" className="link">
          Telegram
        </a>
        {` or `}
        <a href="https://twitter.com/cryptofighters" className="link">
          Twitter
        </a>
        {`, or send an email to `}
        <a href="mailto:hello@cryptofighters.io" className="link">
          hello@cryptofighters.io
        </a>
        {` and we'd be happy to help.`}
      </p>
    ),
  },

  {
    title: 'What gas price and gas limit should I set in MetaMask?',
    body: (
      <p>
        {`The gas price depends on how busy the network is. `}
        {`The higher the gas price, the faster your transaction is likely to go through. `}
        {`You can check current gas prices at `}
        <a href="https://ethgasstation.info/" className="link">
          {'https://ethgasstation.info/'}
        </a>
        {`. `}
        {`We have seen gas prices can fluctuate between 1 and 60 GWei depending on how congested the network is. `}
        {`You shouldn't have to change the gas limit. `}
        {`If you see the expected gas limit showing 7,600,000 this is a bug in MetaMask and we recommend rejecting `}
        {`the transaction and submitting it again or it may stay as pending indefinitely until you submit another transaction in its place.`}
      </p>
    ),
  },
  {
    title: 'What are the Gene Mechanics?',
    body:
      `Each fighter has have its own genes that store valuable information about the appearance and equipment of the fighter. ` +
      `Each gene section (locus) consists of two parts which are both inherited by the fighter's recruiters (Generals are Gen0 and their genes are randomly assigned on birth). ` +
      `Each gene also has a dominance level which defines the rarity of the specific trait. The less dominance, the less likely it will appear on a fighter. ` +
      `For example, a fighter can have a Dragon Glass Weapon (rare low dominance) gene mixed with a Wooden Weapon (common high dominance) gene which will result in a fighter holding a Wooden Weapon but possessing the rare Dragon Glass Weapon gene (that can be randomly passed to its recruits). `,
    // + `Some of the genes have an appearance only affect and some of them will also affect the fighter's abilities in battle.`,
  },
  {
    title: 'What is the Arena?',
    body: (
      <div>
        <p>
          {`Every user can group up his fighters into a team of up to five fighters, and either place them in the arena as defenders or choose to attack other groups already placed there. `}
          {`In the first battle version, the outcome of the match will be determined by an algorithm affected by the following parameters:`}
        </p>
        <ul>
          <li>Group size</li>
          <li>Fighters' ranks (generations)</li>
          <li>Fighters' levels</li>
          <li>Fighters' attributes</li>
          <li>Fighters' genes</li>
          {/* <li>Weaknesses and Strengths</li> */}
          <li>A bit of randomness to spice things up</li>
        </ul>
      </div>
    ),
  },
  {
    title: 'What can I win in battles?',
    body:
      `If the winner's leader isn’t on cooldown, they will recruit a new fighter based on both of the group’s captain’s genes. ` +
      `Also, both teams' fighters will win some experience points. Fighting easy battles will give less experience than tough battles.`,
  },
  {
    title: 'What are the attributes?',
    body: (
      <div>
        <p>Fighters have four attributes which help them in battle:</p>
        <ul>
          <li>
            <span style={styles.bold}>Strength (STR)</span> How strong the fighter is.
          </li>
          <li>
            <span style={styles.bold}>Dexterity (DEX)</span> The character's speed, which determines
            how often this character will have it's move in battle.
          </li>
          <li>
            <span style={styles.bold}>Vitality (VIT)</span> How much pain the fighter can endure.
            Vitality is basically health points.
          </li>
          <li>
            <span style={styles.bold}>Luck (LUK)</span> Your chance of landing a critical hit, it
            may come in handy :)
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: `What's the cooldown system?`,
    body: (
      <div>
        <p>
          We have a cooldown system to avoid flooding the market. You can always fight, but you
          can't always win a prize fighter or gain experience from that fighter.
        </p>
        <p>Cooldowns have no impact on the likelihood of you winning a particular battle.</p>
        <p>We have two types of cooldowns in game:</p>
        <ul>
          <li>
            <span style={styles.bold}>The Recruiting Cooldown</span>
            {
              ' determines whether your captain can win a new fighter in battle. This cooldown has total of seven levels of speed:'
            }
            <ul>
              <li>1 minute</li>
              <li>30 minutes</li>
              <li>2 hours</li>
              <li>6 hours</li>
              <li>12 hours</li>
              <li>1 day</li>
              <li>3 days</li>
            </ul>
            {
              'Every time a fighter wins and recruit a new fighter - the cooldown is moving to the next step.'
            }
          </li>
          <li>
            <span style={styles.bold}>The Experience Cooldown</span>
            {
              ' Fighters go on experience cooldown every 5 fights for 6 hours. Once the 6 hour time period is up, the fighter will begin gaining experience again in battles.'
            }
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: `What does the level of a character indicate?`,
    body:
      `Experience can be earned by winning and losing battles. ` +
      `Everytime a character reaches his max experience, he'll level up and randomly gain an extra point to one of his attributes.`,
  },
]

export { GETTING_STARTED }
