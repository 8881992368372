import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Grid } from 'react-bootstrap'
import _ from 'lodash'
import api from '../../utils/api'
import { FIGHTER_COLORS } from '../../consts'
import { setActiveTabId } from '../../actions'
import BattleHistorySection from '../../components/BattleHistorySection'
import Tabs from '../../components/Tabs'
import BattleStats from '../../components/BattleStats'
import tabsHeader from '../commonTabs'

class BattleHistory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: null,
      stats: {
        wins: 0,
        losses: 0,
        winRate: 0,
      },
      myFighters: [],
      recievedFighters: false,
      recievedMyTeams: false,
      recievedOpponents: false,
      teamsInArena: null,
      totalTeamsFound: null,
      loadingTeams: false,
      loadingMyTeams: false,
      myTeamsCollapsed: false,
    }
  }

  componentDidMount() {
    this.getBattleStats()
    this.getMyTeams()
    this.getTeams()
    this.props.setActiveTabId(1)
  }

  componentWillReceiveProps(nextProps) {
    const user = nextProps.address
    if (user !== this.state.user) {
      this.setState({ user })
    }
  }

  getBattleStats = () => {
    api.getBattleStats(this.props.address).then(data => {
      const battlesFought = data.wins + data.losses

      const winRate = battlesFought ? parseInt((data.wins * 100) / battlesFought, 10) : 0

      data &&
        this.setState({
          stats: {
            wins: data.wins,
            losses: data.losses,
            winRate,
          },
        })
    })
  }

  getTeams = page =>
    Promise.resolve()
      .then(() => this.setState({ loadingTeams: true }))
      .then(() => api.getTeamsInArena(page))
      .then(({ teams, teamsFound }) => {
        this.setState({
          totalTeamsFound: teamsFound,
          teamsInArena: _.sortBy(
            teams.map(team => ({
              ...team,
              fighters: team.fighters.map(f => ({
                ...f,
                color: FIGHTER_COLORS[f.color],
              })),
              isOwner: team.owner === this.state.user,
              removing: false,
            })),
            'isOwner'
          ),
          loadingTeams: false,
        })
      })
      .then(() => this.setState({ loadingTeams: false, recievedOpponents: true }))

  getMyTeams = () =>
    Promise.resolve()
      .then(() => this.setState({ loadingMyTeams: true }))
      .then(() => api.getUsersTeams(this.state.user))
      .then(teams => {
        if (teams && teams.length) {
          const formattedTeams = teams.map(team => ({
            ...team,
            fighters: team.fighterIds.map(fId =>
              _.find(this.state.myFighters, ({ fighterId }) => fighterId === fId)
            ),
            isOwner: team.owner === this.state.user,
            removing: false,
          }))
          this.props.setTeams && this.props.setTeams(formattedTeams)
        }
      })
      .then(() =>
        this.setState({
          loadingMyTeams: false,
          recievedMyTeams: true,
          myTeamsCollapsed: !this.props.myTeams.length,
        })
      )

  // renderBattles() {
  //   const { battles } = this.props

  //   if (!battles.length) return <p>No battles have been fought yet.</p>

  //   return battles.map(battle => {
  //     console.log(battle)

  //     const { transactionHash, winnerAddress, loserAddress } = battle

  //     return (
  //       <div key={transactionHash} style={{ padding: 20 }}>
  //         <div>
  //           <Link to={`/my-fighters/${winnerAddress}`}>{winnerAddress}</Link>
  //           {` vs `}
  //           <Link to={`/my-fighters/${loserAddress}`}>{loserAddress}</Link>
  //         </div>
  //         Tx: <Link to={`/arena/results/${transactionHash}`}>{transactionHash}</Link>
  //       </div>
  //     )
  //   })
  // }

  render() {
    const { address, battles } = this.props
    const { stats, recievedOpponents, recievedMyTeams, loading, user } = this.state
    return (
      <section className="battle-history-main-wrapper">
        <Grid>
          <Tabs address={address} className="tabs-center" tabsHeader={tabsHeader} />
          <BattleStats stats={stats} />
          <BattleHistorySection
            recievedTeams={recievedOpponents || recievedMyTeams}
            loadingHistory={loading}
            history={battles}
            userAddr={user}
          />
        </Grid>
      </section>
    )
  }
}

BattleHistory.props = {
  loading: PropTypes.bool.isRequired,
  battles: PropTypes.arrayOf(PropTypes.object),
  setActiveTabId: PropTypes.func.isRequired,
}

const mapStateToProps = ({ app, fights, tabs }) => ({
  page: app.arenaPage,
  teamFighters: fights.tempTeam.fighterIds,
  teamLeader: fights.tempTeam.leaderId,
  teamInArena: fights.tempTeam.inArena,
  teamSubmitted: fights.tempTeam.submitted,
  currentTeamId: fights.tempTeam.teamId,
  myFightersInTeams: Object.keys(fights.teams).reduce(
    (arr, teamId) => arr.concat(fights.teams[teamId].fighterIds),
    []
  ),
  myTeams: Object.keys(fights.teams).map(teamId => fights.teams[teamId]),
})

export default connect(mapStateToProps, {
  setActiveTabId,
})(BattleHistory)
