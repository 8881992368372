import { app as appTypes } from './types'

const initializeApp = () => ({
  type: appTypes.initializeApp,
})

const setArenaPage = page => ({
  type: appTypes.setArenaPage,
  payload: { page },
})

export { initializeApp, setArenaPage }
