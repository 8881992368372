const HERO = {
  title: [{ main: 'Collect' }, { main: 'Train' }, { main: 'Fight', prefix: '& ' }],
  subtitle: 'Digital fighters on the blockchain.',
  mainAction: 'START NOW',
}
const FIRST_CONTENT = {
  header: { main: 'What is CryptoFighters', suffix: '?' },
  body:
    'CryptoFighters is a game centered around cryptographically unique collectible fighters on the Ethereum blockchain. Collect, battle and level up your fighters to win new fighters!',
  button: { label: 'LEARN MORE' },
}
const LAST_CONTENT = {
  header: { main: 'Ready for the fight? Time to be victorious ⚔' },
  button: { label: 'FIGHT NOW' },
}
const CONTENT = [
  {
    header: { main: 'Unique Fighters' },
    body:
      'Each fighter is unique, some are more rare then others. Weapons, skin color, clothes & stats - everything will be specific to your fighter. Find the rarest and collect them.',
    image: require('../../images/landing/unique-fighters.png'),
  },
  {
    header: { main: 'Arena' },
    body:
      'Compose your team of different fighters and prepare for the arena! Optimize them, fight other players, win rewards and level up your fighters every day.',
    image: require('../../images/landing/arena.png'),
  },
  {
    header: { main: 'Recruiting' },
    body:
      'By winning fights you recruit new fighters. Based on the fighting teams, your new fighter will have unique characteristics. You have to be the very best.',
    image: require('../../images/landing/recruiting.png'),
  },
  {
    header: { main: 'Buying & Selling' },
    body:
      'Buy and sell your fighters on the marketplace. Place your bids to acquire the rarest fighters in the game and collect them all.',
    image: require('../../images/landing/buy-sell.png'),
  },
]

export { HERO, FIRST_CONTENT, LAST_CONTENT, CONTENT }
