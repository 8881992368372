import React from 'react'
import PropTypes from 'prop-types'

const BattleIcon = ({ color, height, width }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 19">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v19.202H0z" />
      <path
        fill={color}
        fillRule="nonzero"
        d="M18.922 1.036a.853.853 0 0 0-.725-.223l-5 .8a.84.84 0 0 0-.53.31l-6.715 8.591 1.106 1.062 7.917-7.6.884.849-7.917 7.6 1.106 1.061L18 7.042a.794.794 0 0 0 .323-.51l.833-4.8a.783.783 0 0 0-.233-.696z"
      />
      <path
        fill={color}
        fillRule="nonzero"
        d="M9.327 14.966L4.412 10.25a.794.794 0 0 1-.245-.567v-1.68H2.5v1.68c0 .63.267 1.25.732 1.698l1.035.992-3.19 3.062A.783.783 0 0 0 .833 16c0 .212.088.415.244.565l1.667 1.6a.846.846 0 0 0 .59.236.846.846 0 0 0 .588-.236l3.19-3.063 1.036.995c.473.454 1.101.704 1.769.704h1.75v-1.6h-1.75a.842.842 0 0 1-.59-.235z"
      />
    </g>
  </svg>
)

BattleIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
}

BattleIcon.defaultProps = {
  width: 15,
  height: 15,
  color: '#000',
}

export default BattleIcon
