import React from 'react'
import { Row, Col } from 'react-bootstrap'
import BounceLoader from '../loaders/BounceLoader'
import BattleHistoryRow from '../BattleHistoryRow'
import './style.css'

export default class BattleHistorySection extends React.Component {
  renderBattles() {
    const { history, userAddr } = this.props

    return (
      <div>
        {history && history.history && history.history.length ? (
          history.history.map(battle => {
            return <BattleHistoryRow key={battle.transactionHash} userAddr={userAddr} {...battle} />
          })
        ) : (
          <p>You have no battle history.</p>
        )}
      </div>
    )
  }

  renderLoading() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 20,
          marginBottom: 18,
        }}
      >
        <BounceLoader size={60} />
      </div>
    )
  }

  render() {
    const { recievedTeams, loadingHistory, history } = this.props

    if (!recievedTeams) return null

    return (
      <div className="battle-history-section">
        <Row
          style={{
            marginTop: 56,
          }}
        >
          <Col sm={12}>
            <div className="page-title">Battle History</div>
          </Col>
        </Row>
        {!loadingHistory && history ? this.renderBattles() : this.renderLoading()}
      </div>
    )
  }
}
