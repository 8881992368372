import React, { Component } from 'react'

const colors = {
  active: '#2196f3',
  disabled: '#bdbdbd',
}

class NextArrow extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="10" viewBox="0 0 20 10">
        <path
          fill={this.props.disabled ? colors.disabled : colors.active}
          fillRule="nonzero"
          d="M14.707.293l-1.414 1.414L15.586 4H0v2h15.586l-2.293 2.293 1.414 1.414L19.414 5z"
        />
      </svg>
    )
  }
}

export default NextArrow
