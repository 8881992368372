import React from 'react'

const styles = {
  text: {
    fontSize: 24,
    textAlign: 'left',
    color: '#030f23',
  },
}

const SubTitle = ({ children }) => <div style={styles.text}>{children}</div>

export default SubTitle
