import React from 'react'

const MyFightersIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 17">
    <g fill="none" fillRule="evenodd">
      <path d="M-2-.998h20v19.2H-2z" />
      <path
        fill={color}
        fillRule="nonzero"
        d="M11.333.6H8C6.16.6 4.667 2.033 4.667 3.8v4.8H3v-4h-.833C1.247 4.6.5 5.316.5 6.2v2.4c0 2.4 1.667 4 3.333 4V15c0 .884.747 1.6 1.667 1.6h5.833c.92 0 1.667-.716 1.667-1.6v-2.844c.992-.554 1.667-1.574 1.667-2.756V3.8c0-1.767-1.493-3.2-3.334-3.2zm0 14.4H5.5v-2.4h5.833V15z"
      />
    </g>
  </svg>
)

export default MyFightersIcon
