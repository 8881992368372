import React, { Component } from 'react'
import Tooltip from '../../Tooltip'
import ExhaustedCooldownIcon from './ExhaustedCooldownIcon'

class ExhaustedCooldown extends Component {
  constructor() {
    super()
    this.state = {
      showTooltip: false,
    }
  }

  toggleTooltip(bool) {
    this.setState({ showTooltip: bool })

    // If tooltip is close to the edge of the screen, show in the opp. direction
    if (this.tooltip.getBoundingClientRect().x + 300 > window.innerWidth) {
      this.tooltip.style.right = '0px'
    }
  }

  render() {
    return (
      <div>
        {this.props.show ? (
          <div style={{ position: 'relative' }}>
            <div
              onMouseEnter={() => this.toggleTooltip(true)}
              onMouseLeave={() => this.toggleTooltip(false)}
              style={{
                width: '30px',
                height: '30px',
                padding: '6px',
                borderRadius: '50%',
                background: '#fff',
                boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
              }}
            >
              <ExhaustedCooldownIcon color={this.props.color} />
            </div>
            <div
              ref={ref => (this.tooltip = ref)}
              className="cooldown"
              style={{ position: 'absolute', top: 12, left: -232, width: 270 }}
            >
              <Tooltip title="Exhausted" hide={!this.state.showTooltip}>
                This fighter won’t receive any exp<br />
                for {this.props.timeLeft}.
              </Tooltip>
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
    )
  }
}

export default ExhaustedCooldown
