import React, { Component } from 'react'
import FighterCard from '../../components/FighterCard'
import Stats from '../../components/ViewFighter/Stats'
import Level from '../../components/ViewFighter/Level'
import { getLevel } from '../../utils/fighter'
import './style.css'

class FightResults extends Component {
  static propTypes = {}

  constructor(props) {
    super(props)
    this.state = {
      fightResult: props.fightResult,
      fighters: props.fighters,
    }
  }

  componentDidMount() {
    //request to API
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.fighters !== this.props.fighters) {
      this.setState({ fighters: nextProps.fighters })
    }
  }

  renderLeftSection = fighter => {
    const { strength, luck, dexterity, vitality, color, experience, expGained } = fighter
    const { level, maxExp } = getLevel(experience)

    const MAX_STAT = 50

    return (
      <div className="fighter-statisctic-block">
        <div className="text-center">
          <Level
            color={color.primary}
            level={level}
            exp={experience}
            maxExp={maxExp}
            expGained={expGained}
            showTextLevel={false}
            showBattleResultExp={false}
          />
        </div>
        <Stats
          statsClass="stats-sm"
          stats={[
            { stat: 'STR', amount: strength, max: MAX_STAT },
            { stat: 'VIT', amount: vitality, max: MAX_STAT },
            { stat: 'DEX', amount: dexterity, max: MAX_STAT },
            { stat: 'LUK', amount: luck, max: MAX_STAT },
          ]}
          color={color.primary}
          statsLabelText=""
        />
      </div>
    )
  }

  render() {
    const { fighters } = this.state
    return fighters && fighters.length ? (
      <div className="fighters-wrapper">
        {fighters.map(fighter => (
          <div key={fighter.id}>
            <FighterCard
              showStats={false}
              showPrice={false}
              autoHeight
              starSize={10}
              cardSizeXs
              showName={fighter.name}
              showGenLevel={false}
              noAnimation
              {...fighter}
            />
            {this.renderLeftSection(fighter)}
          </div>
        ))}
      </div>
    ) : null
  }
}

export default FightResults
