import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FIGHTER_COLORS } from '../../consts'
import api from '../../utils/api'
import getWeb3 from '../../utils/getWeb3'
import FighterCard from '../../components/FighterCard'
import AuctionForm from '../../components/AuctionForm'
import MainAction from '../../components/buttons/MainAction'
import { createAuction } from '../../utils/web3Auctions'
import { isOwner } from '../../utils/fighter'
import Banner from '../../components/Banner'

const styles = {
  header: {
    fontWeight: 'bold',
    color: '#030f23',
    display: 'inline-block',
    borderBottomStyle: 'solid',
    paddingBottom: 15,
    borderBottomWidth: 2,
  },
  link: {
    textDecoration: 'none',
  },
}

class Fighter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fighter: null,
      startPrice: 1,
      endPrice: 0.1,
      duration: 24,
      auctionCreated: null,
      auctionLoading: null,
    }
  }

  componentDidMount() {
    const fighterId = parseInt(this.props.match.params.id, 10)
    getWeb3()
      .then(
        web3 =>
          new Promise((resolve, reject) =>
            web3.eth.getAccounts((error, accounts) => (error ? reject(error) : resolve(accounts)))
          )
      )
      .then(accounts => this.setState({ currentUser: accounts[0] }))
      .then(() => api.getFighter(fighterId))
      .then(fighter => {
        this.setState({
          fighter: {
            ...fighter,
            color: FIGHTER_COLORS[fighter.color],
            isOwner: isOwner(fighter, this.state.currentUser),
            id: fighter.fighterId,
          },
        })
      })
  }

  createAuction = () => {
    const fighterId = parseInt(this.props.match.params.id, 10)
    Promise.resolve()
      .then(() => this.setState({ auctionLoading: true }))
      .then(() =>
        createAuction(fighterId, this.state.startPrice, this.state.endPrice, this.state.duration)
      )
      .then(success => {
        if (success) {
          this.setState({ auctionCreated: true, auctionLoading: false })
          toast.success('Your fighter is now on auction!', {
            className: `toast-${this.state.fighter.color.name}`,
          })
        } else {
          this.setState({ auctionLoading: false, auctionCreated: false })
          toast.error(
            'There was a problem with your transaction. Your fighter is not on auction...',
            {
              className: 'toast-error',
            }
          )
        }
      })
      .catch(() => {
        this.setState({ auctionLoading: false, auctionCreated: false })
        toast.error(
          'There was a problem with your transaction. Your fighter is not on auction...',
          {
            className: 'toast-error',
          }
        )
      })
  }

  render() {
    if (this.state.fighter) {
      if (this.state.fighter.isOwner && this.state.auctionCreated) {
        return (
          <div className="container">
            <div style={{ textAlign: 'center' }}>
              <h2
                style={{ ...styles.header, borderBottomColor: this.state.fighter.color.secondary }}
              >
                Fighter On Auction!
              </h2>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
              <FighterCard {...this.state.fighter} disabled />
              <div style={{ margin: 'auto' }}>
                <Link to={'/market'} style={styles.link}>
                  <MainAction color={this.state.fighter.color.primary}>MARKET</MainAction>
                </Link>
              </div>
            </div>
          </div>
        )
      } else if (this.state.fighter.isOwner) {
        return (
          <div className="container">
            <div style={{ marginBottom: 30 }}>
              <Banner>
                To consolidate sales please sell your fighters on{' '}
                <a href="https://opensea.io/collection/cryptofighters">OpenSea</a> instead
              </Banner>
            </div>

            <div style={{ textAlign: 'center' }}>
              <h2
                style={{ ...styles.header, borderBottomColor: this.state.fighter.color.secondary }}
              >
                Sell Fighter
              </h2>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ marginRight: 20, width: 250 }}>
                <FighterCard {...this.state.fighter} disabled />
              </div>
              <div
                style={{
                  width: 250,
                  marginLeft: 20,
                  display: 'flex',
                }}
              >
                <AuctionForm
                  startPrice={this.state.startPrice}
                  onChangeStartPrice={e => this.setState({ startPrice: e.target.value })}
                  endPrice={this.state.endPrice}
                  onChangeEndPrice={e => this.setState({ endPrice: e.target.value })}
                  duration={this.state.duration}
                  onChangeDuration={e => this.setState({ duration: e.target.value })}
                />
              </div>
            </div>
            <div
              style={{
                marginTop: 40,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <MainAction
                color={this.state.fighter.color.primary}
                // onClick={this.createAuction}
                onClick={() => {
                  alert(`We've disabled listing new items on our marketplace for now.`)
                }}
                loading={this.state.auctionLoading}
              >
                SELL FIGHTER
              </MainAction>
              {this.state.auctionLoading ? (
                <div style={{ fontSize: 18, paddingTop: 20 }}>
                  Waiting for blockchain... Check back in a few minutes{' '}
                  <span role="img" aria-label="Timer">
                    ⏳
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        )
      } else {
        return (
          <div className="container">
            <div style={{ textAlign: 'center' }}>
              <h2
                style={{ ...styles.header, borderBottomColor: this.state.fighter.color.secondary }}
              >
                You Don't Own This Fighter
              </h2>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
              <FighterCard {...this.state.fighter} disabled />
              <div style={{ margin: 'auto' }}>
                <Link to={'/my-fighters'} style={styles.link}>
                  <MainAction color={this.state.fighter.color.primary}>MY FIGHTERS</MainAction>
                </Link>
              </div>
            </div>
          </div>
        )
      }
    } else {
      return null
    }
  }
}

export default Fighter
