import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Grid, Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { withRouter /* , Link */ } from 'react-router-dom'
import qs from 'query-string'
import { FIGHTER_COLORS, SOLD_LABEL } from '../../consts'
import api from '../../utils/api'
import getWeb3 from '../../utils/getWeb3'
import { getCurrentAuctionPrice } from '../../utils/web3Auctions'
import { setMarketPage, setMarketSort, setMarketGen } from '../../actions'
import Pager from '../../components/Pager'
import FighterGallery from '../../components/FighterGallery'
// import SearchBar from '../../components/SearchBar'
import Dropdown from '../../components/Dropdown'
import BounceLoader from '../../components/loaders/BounceLoader'
import { getTimeTillCooldown, isOwner } from '../../utils/fighter'

const DEFAULT_SORT = 2

const sortMethods = [
  {
    value: 0,
    label: 'Newest',
  },
  {
    value: 1,
    label: 'Oldest',
  },
  {
    value: 2,
    label: 'Cheapest',
  },
  {
    value: 3,
    label: 'Most Expensive',
  },
  // {
  //   value: 4,
  //   label: 'Name (A-Z)',
  // },
  // {
  //   value: 5,
  //   label: 'Name (Z-A)',
  // },
]

const queryParamsToSort = {
  newest: 0,
  oldest: 1,
  cheapest: 2,
  priciest: 3,
}

const tabs = [
  { name: 'All', filter: 'all', gen: null },
  { name: 'Gen 0', filter: 'gen0', gen: 0 },
  { name: 'Gen 1', filter: 'gen1', gen: 1 },
  { name: 'Gen 2', filter: 'gen2', gen: 2 },
]

// const tabs = ['For Sale', 'Gen 0', 'All Fighters']

class MarketPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: 0,
      search: '',
      fighters: null,
      totalFighters: 0,
      gen: null,
    }
  }

  componentWillMount() {
    this.setSort(true)
  }

  componentDidMount() {
    getWeb3()
      .then(
        web3 =>
          new Promise((resolve, reject) =>
            web3.eth.getAccounts((error, accounts) =>
              error
                ? reject(error)
                : web3.eth.getBlockNumber((error, blockNumber) =>
                    error ? reject(error) : resolve({ user: accounts[0], blockNumber })
                  )
            )
          )
      )
      .then(({ user, blockNumber }) => this.setState({ user, blockNumber }))
      .then(this.getFighters)
  }

  getSort() {
    const { location } = this.props
    const query = qs.parse(location.search)
    const sort = (query && query.sort && queryParamsToSort[query.sort]) || DEFAULT_SORT

    return sort
  }

  setSort = init => {
    const sort = this.getSort()

    if (init && sort === 0 && this.props.sort !== 0) {
      const sortString = _.findKey(queryParamsToSort, v => v === this.props.sort)
      const search = qs.stringify({ sort: sortString })
      search && this.props.history.push({ search })
    } else {
      this.props.setMarketSort(sort)
    }
  }

  getFighters = (page, sort, gen) => {
    console.log(page, sort, gen)

    return getWeb3().then(web3 =>
      api
        .getFightersOnSale(
          page == null ? this.props.page : page,
          sort == null ? this.props.sort : sort,
          gen == null ? this.props.gen : gen
        )
        .then(({ data, count }) =>
          Promise.all(
            data.map(async a => {
              const price = await getCurrentAuctionPrice(a.fighterId)
              const fighter = {
                ...a.fighter,
                auction: a,
                price: price && web3.utils.fromWei(price, 'ether'),
                isOwner: isOwner(a.fighter, this.state.currentUser),
                id: a.fighterId,
                color: FIGHTER_COLORS[a.fighter.color],
                timeTillBattleCooldown: getTimeTillCooldown(
                  a.fighter.battleCooldownEndTime,
                  this.state.blockNumber
                ),
                timeTillPrizeCooldown: getTimeTillCooldown(
                  a.fighter.prizeCooldownEndTime,
                  this.state.blockNumber
                ),
              }
              let formattedPrice
              if (fighter.price && _.isString(fighter.price)) {
                formattedPrice = Number(fighter.price).toFixed(4)
              } else if (fighter.price && fighter.price.toFixed) {
                formattedPrice = fighter.price.toFixed(4)
              }
              fighter.displayPrice =
                !formattedPrice || formattedPrice === '0.0000'
                  ? SOLD_LABEL
                  : `${formattedPrice} ETH`
              return fighter
            })
          ).then(fighters => this.setState({ fighters, totalFighters: count }))
        )
    )
  }

  onChangeSort = sort => {
    const page = 0
    const sortString = _.findKey(queryParamsToSort, v => v === sort)
    const search = qs.stringify({ sort: sortString })
    search && this.props.history.push({ search })

    this.props.setMarketSort(sort)
    this.props.setMarketPage(page)
    this.setState({ fighters: null })
    this.getFighters(page, sort, this.state.gen)
  }

  onChangePage = page => {
    this.props.setMarketPage(page)
    this.setState({ fighters: null })
    this.getFighters(page, null, this.state.gen)
  }

  onChangeTab = (event, gen, tabIndex) => {
    const page = 0

    this.props.setMarketGen(gen)
    this.props.setMarketPage(page)
    this.setState({ fighters: null, activeTab: tabIndex, gen })

    const sort = this.getSort()

    this.getFighters(page, sort, gen)
  }

  onChangeSearch = event => this.setState({ search: event.target.value })

  getSortedFighters = () =>
    _.sortBy(this.state.fighters, f => {
      switch (this.props.sort) {
        case 0:
          return f.id * -1
        case 1:
          return f.id
        case 2:
          return f.price
        case 3:
          return f.price * -1
        // case 4:
        //   return f.id
        // case 5:
        //   return f.id * -1
        default:
          return f.id
      }
    })

  getPages() {
    const pages = []
    const totalPages = Math.ceil(this.state.totalFighters / 12)
    for (let i = 0; i < totalPages; i += 1) {
      pages.push(i)
    }
    return pages
  }

  // renderSearchBar() {
  //   return (
  //     <div style={{ width: '50%', display: 'inline-block' }}>
  //       <SearchBar style={{ width: '100%' }} placeholder="Search for a fighter..." />
  //     </div>
  //   )
  // }

  renderTabs() {
    return (
      <div
        style={{
          position: 'relative',
          display: 'inline-block',
        }}
      >
        {tabs.map(({ name, filter, gen }, i) => {
          const active = i === this.state.activeTab

          const borderBottom = active ? '2px solid #2196f3' : '2px solid #eeeeee'

          return (
            <div
              key={name}
              // to={`?filter=${filter}`}
              onClick={e => this.onChangeTab(e, gen, i)}
              style={{
                width: 112,
                // height: 30,
                padding: '12px 0',
                borderBottom,
                display: 'inline-block',
              }}
            >
              <div
                style={{
                  height: 18,
                  fontSize: 18,
                  fontWeight: 'normal',
                  fontStyle: 'normal',
                  fontStretch: 'normal',
                  lineHeight: 1,
                  letterSpacing: 'normal',
                  textAlign: 'center',
                  color: '#030f23',
                  // paddingBelow: 11,
                }}
              >
                {name}
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  renderDropDown() {
    return (
      <div
        style={{
          position: 'relative',
          display: 'inline-block',
          verticalAlign: 'top',
          height: 0,
          zIndex: 1,
          float: 'right',
        }}
      >
        <Dropdown
          sort={this.props.sort}
          onChangeSort={this.onChangeSort}
          sortMethods={sortMethods}
        />
      </div>
    )
  }

  renderFightersFound() {
    return (
      <p style={{ fontStyle: 'italic', color: '#9B9B9B' }}>
        {`${this.state.totalFighters} fighters found`}
      </p>
    )
  }

  render() {
    return (
      <div>
        <Grid>
          <Row>
            <Col sm={12}>
              {/* May use at a later date */}
              {/* {this.renderSearchBar()} */}
              {/* WIP: */}
              {this.renderTabs()}
              {this.renderDropDown()}
              {this.renderFightersFound()}
            </Col>
          </Row>
          {this.state.fighters && this.state.fighters.length ? (
            <FighterGallery fighters={this.getSortedFighters()} />
          ) : (
            <div
              style={{
                display: 'flex',
                height: 400,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {this.state.fighters ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <div style={{ fontSize: 22 }}>
                    The market is empty{' '}
                    <span role="img" aria-label="Sad face">
                      😢
                    </span>
                  </div>
                  <div style={{ fontSize: 18, marginTop: 15 }}>Try different filters</div>
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <BounceLoader size={60} />
                  <div style={{ marginTop: 30, color: 'rgb(204, 204, 204)', fontSize: 16 }}>
                    Loading Fighters
                  </div>
                </div>
              )}
            </div>
          )}
        </Grid>
        {this.state.totalFighters ? (
          <div style={{ marginBottom: 50 }}>
            <Pager
              pages={this.getPages()}
              activePage={this.props.page}
              onChangePage={this.onChangePage}
            />
          </div>
        ) : null}
      </div>
    )
  }
}

MarketPage.propTypes = {
  activeTab: PropTypes.number,
  search: PropTypes.string,
  fighters: PropTypes.array,
  totalFighters: PropTypes.number,
  stats: PropTypes.object,
}

const mapStateToProps = ({ market }) => ({
  page: market.page,
  sort: market.sort,
})

const MarketWithRouter = withRouter(MarketPage)

export default connect(mapStateToProps, {
  setMarketPage,
  setMarketSort,
  setMarketGen,
})(MarketWithRouter)
