import React, { Component } from 'react'
import FAQSidebarTitle from './FAQSidebarTitle'
import scrollToElement from 'scroll-to-element'

const styles = {
  container: {
    width: '100%',
    padding: 32,
    borderRadius: 8,
    backgroundColor: '#fff',
    border: 'solid 1px #eeeeee',
  },
  text: {
    cursor: 'pointer',
  },
}

class FAQSidebar extends Component {
  constructor() {
    super()
    this.state = {
      selectedLink: 0,
    }
  }

  render() {
    return (
      <div style={styles.container}>
        <FAQSidebarTitle>Getting Started</FAQSidebarTitle>
        {this.props.links.map((link, i) => (
          <p
            key={i}
            style={{
              ...styles.text,
              color: this.state.selectedLink === i ? '#000' : '#9b9b9b',
              fontWeight: this.state.selectedLink === i ? 500 : 400,
            }}
            onClick={() => {
              this.setState({ selectedLink: i })
              scrollToElement('#faq-getting-started-' + i, {
                offset: -20,
                ease: 'inQuad',
                duration: 400,
              })
            }}
          >
            {link.title}
          </p>
        ))}
      </div>
    )
  }
}

export default FAQSidebar
