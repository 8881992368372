import React, { Component } from 'react'
import Star from './components/Star'

class Stars extends Component {
  getStars() {
    let stars = []

    for (let i = 0; i < this.props.amount; i++) {
      stars.push(<Star key={i} color={this.props.color} size={this.props.size} />)
    }

    return stars
  }

  render() {
    return <div>{this.getStars()}</div>
  }
}

export default Stars
