import React from 'react'
import { PageButton, StepButton } from './'
import { NextArrow, PreviousArrow } from './Arrows'

const Pager = ({ pages, activePage, onChangePage }) => {
  // Function that keeps pager clean by only showing 5 pages
  const cleanPager = () => {
    let pagesShown = 5
    if (pages.length > pagesShown - 1) {
      if (activePage === 0 || activePage === 1) {
        pages = pages.slice(0, pagesShown)
      } else if (activePage === pages.length - 2 || activePage === pages.length - 1) {
        pages = pages.slice(pages.length - pagesShown, pages.length)
      } else {
        pages = pages.slice(activePage - 2, activePage + 3)
      }
    }
  }

  cleanPager()

  return (
    <div style={{ textAlign: 'center' }} className="noselect">
      <div className="hidden-xs" style={{ display: 'inline-block' }}>
        <StepButton disabled={activePage === 0} onClick={() => onChangePage(activePage - 1)}>
          <PreviousArrow disabled={activePage === 0} />
        </StepButton>
      </div>
      <div style={{ flex: 1, display: 'inline-block' }}>
        {pages.map(page => (
          <PageButton
            key={page}
            active={activePage === page}
            onClick={() => onChangePage(page)}
            label={page + 1}
          />
        ))}
      </div>
      <div className="hidden-xs" style={{ display: 'inline-block' }}>
        <StepButton
          disabled={activePage === pages[pages.length - 1]}
          onClick={() => onChangePage(activePage + 1)}
        >
          <NextArrow disabled={activePage === pages[pages.length - 1]} />
        </StepButton>
      </div>
    </div>
  )
}

export default Pager
