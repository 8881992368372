import React from 'react'
import MainAction from '../buttons/MainAction'
import { Title, SubTitle } from './'

const styles = {
  largeSpace: {
    marginTop: 32,
    marginBottom: 32,
  },
}

const Content = ({ title, subtitle, mainAction, xs }) => (
  <div>
    {title.map(({ main, suffix, prefix }, i) => (
      <div key={main}>
        <Title suffix={suffix} prefix={prefix}>
          {main}
        </Title>
      </div>
    ))}
    <div style={styles.largeSpace}>
      <SubTitle>{subtitle}</SubTitle>
    </div>
    <MainAction
      style={xs ? { margin: 'auto' } : null}
      color="#2196f3"
      onClick={mainAction.onClick ? mainAction.onClick : () => {}}
    >
      {mainAction.label}
    </MainAction>
  </div>
)

export default Content
