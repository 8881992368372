import React from 'react'

const AddIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 18 19">
    <g fill="none" fillRule="evenodd">
      <path d="M-3-1.999h24v23.783H-3z" />
      <path
        fill={color}
        fillRule="nonzero"
        d="M18 8.902h-8V.974H8v7.928H0v1.982h8v7.927h2v-7.927h8z"
      />
    </g>
  </svg>
)

export default AddIcon
