import React from 'react'
import moment from 'moment'
import ExhaustedCooldown from '../ExhaustedCooldown'
import PrizeCooldown from '../PrizeCooldown'

const CooldownMenu = ({ color, timeTillBattleCooldown, timeTillPrizeCooldown }) => (
  <div style={{ position: 'absolute', right: '5px', top: '5px' }}>
    <ExhaustedCooldown
      show={timeTillBattleCooldown > 0}
      timeLeft={moment(Date.now() + timeTillBattleCooldown).fromNow(true)}
      color={color}
    />
    <div style={{ height: '5px' }} />
    <PrizeCooldown
      show={timeTillPrizeCooldown > 0}
      timeLeft={moment(Date.now() + timeTillPrizeCooldown).fromNow(true)}
      color={color}
    />
  </div>
)

export default CooldownMenu
