import _ from 'lodash'
import uuid from 'uuid'
import { fights as fightsTypes } from '../actions/types'

const DEFAULT_TEAM = {
  fighterIds: [],
  // leaderId: null,
  inArena: false,
  teamId: null,
  submitted: false,
  removing: false,
}

const INIT_FIGHTS_STATE = {
  tempTeam: {
    ...DEFAULT_TEAM,
  },
  teams: {},
}

const actions = {
  [fightsTypes.addFighterToTeam]: (state, payload) => ({
    ...state,
    tempTeam: {
      ...state.tempTeam,
      // leaderId: payload.isLeader ? payload.fighterId : t.leaderId,
      submitted: false,
      fighterIds: _.uniq(state.tempTeam.fighterIds.concat([payload.fighterId])),
    },
  }),

  [fightsTypes.removeFighterFromTeam]: (state, payload) => ({
    ...state,
    tempTeam: {
      ...state.tempTeam,
      // leaderId: payload.isLeader ? payload.fighterId : t.leaderId,
      submitted: false,
      fighterIds: _.pull(state.tempTeam.fighterIds, payload.fighterId).slice(),
    },
  }),

  [fightsTypes.setTeam]: (state, payload) => ({
    ...state,
    teams: {
      ...state.teams,
      [payload.team.teamId.toString()]: {
        ...payload.team,
        submitted: true,
      },
    },
  }),

  [fightsTypes.setTeams]: (state, payload) => {
    const newState = { ...state }
    payload.teams.forEach(team => {
      newState.teams[team.teamId.toString()] = {
        ...team,
        submitted: true,
      }
    })
    return newState
  },

  [fightsTypes.enterTeamInArena]: state => {
    const newState = { ...state }
    newState.teams[uuid.v4()] = { ...state.tempTeam }
    newState.tempTeam = { ...DEFAULT_TEAM }
    return newState
  },

  [fightsTypes.removeTeamFromArena]: (state, payload) => {
    const newState = { ...state }
    delete newState.teams[payload.teamId]
    return newState
  },

  [fightsTypes.removingTeamFromArena]: (state, payload) => ({
    ...state,
    teams: {
      ...state.teams,
      [payload.teamId]: {
        ...state.teams[payload.teamId],
        removing: payload.undo ? false : true,
      },
    },
  }),

  [fightsTypes.resetTempTeam]: (state, payload) => ({
    ...state,
    tempTeam: { ...DEFAULT_TEAM },
  }),
}

const fights = (state = INIT_FIGHTS_STATE, action) => {
  if (actions[action.type]) {
    return actions[action.type](state, action.payload)
  }
  return state
}

export default fights
