import React from 'react'
import Cart from '../Cart'
import { SOLD_LABEL } from '../../../../consts'

const styles = {
  priceTag: {
    padding: '3px 10px',
    width: 'fit-content',
    borderRadius: 5,
    position: 'absolute',
    bottom: -10,
    left: '50%',
    transform: 'translateX(-50%)',
    background: '#fff',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1)',
  },
  price: {
    fontSize: 14,
    fontWeight: '500',
    color: '#757575',
  },
  cartIcon: {
    marginRight: 10,
  },
}

const PriceTag = ({ price, color }) => {
  const sold = price === SOLD_LABEL
  const priceStyle = sold
    ? { ...styles.price, color: color, paddingLeft: 15, paddingRight: 15 }
    : { ...styles.price, color: color }
  return (
    <div style={styles.priceTag}>
      {!sold && (
        <span style={styles.cartIcon}>
          <Cart color={color} />
        </span>
      )}
      <span style={priceStyle}>{price}</span>
    </div>
  )
}

export default PriceTag
