import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Chart from 'chart.js'
import './style.css'

class CircleChart extends PureComponent {
  componentDidMount() {
    const {
      borderColor,
      currentExperience,
      nextLevelExperience,
      previousLevelExperience,
    } = this.props

    const fill =
      (currentExperience - previousLevelExperience) /
      (nextLevelExperience - previousLevelExperience) *
      100

    const ctx = document.getElementById('doughnut').getContext('2d')

    new Chart(ctx, {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: [fill, 100 - fill],
            backgroundColor: [borderColor, 'rgba(0, 0, 0, 0)'],
            borderColor: 'rgba(0, 0, 0, 0)',
            borderWidth: 0,
          },
        ],
      },
      options: {
        cutoutPercentage: 99,
        tooltips: {
          enabled: false,
        },
        title: {
          display: false,
          text: '',
        },
      },
    })
  }

  render() {
    const { width, height, className } = this.props

    return (
      <canvas
        id="doughnut"
        width={width}
        height={height}
        className={`chart chart-doughnut ${className}`}
      />
    )
  }
}

CircleChart.propTypes = {
  className: PropTypes.string,
  borderColor: PropTypes.string.isRequired,
  currentExperience: PropTypes.number.isRequired,
  nextLevelExperience: PropTypes.number.isRequired,
  previousLevelExperience: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}

CircleChart.defaultProps = {
  className: '',
}

export default CircleChart
