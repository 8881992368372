import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Progress from '../../Progress'
import InfoIcon from '../../InfoIcon'
import Tooltip from '../../Tooltip'
import recruitingList from './commonList'
import './style.css'

class RecruitingCooldown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      progress: 0,
      showTooltip: false,
    }
  }

  componentDidMount() {
    const { cooldownIndex, cooldownEndTime } = this.props
    const maxMount = recruitingList[cooldownIndex].amount
    let currentProgress = maxMount - cooldownEndTime
    let timeLeft = cooldownEndTime
    this.setTimer(timeLeft, cooldownIndex)
    this.setState({
      currentProgress,
    })

    this.intervalHandle = setInterval(() => {
      this.setState({
        currentProgress: (currentProgress += 1000),
      })
      this.setTimer((timeLeft -= 1000), cooldownIndex)
    }, 1000)

    this.timeoutHandle = setTimeout(() => {
      clearInterval(this.intervalHandle)
    }, cooldownEndTime)
  }

  componentWillUnmount() {
    clearInterval(this.intervalHandle)
    clearInterval(this.timeoutHandle)
  }

  toggleTooltip(bool) {
    this.setState({ showTooltip: bool })
  }

  setTimer = (timeLeft, cooldownIndex) => {
    // if (timeLeft < 0) return this.setState({ timeLeft: '00:00:00' })
    if (timeLeft <= 0) return

    const time = moment.duration(timeLeft)
    const hours = time._data.hours < 10 ? `0${time._data.hours}` : time._data.hours
    const minutes = time._data.minutes < 10 ? `0${time._data.minutes}` : time._data.minutes
    const seconds = time._data.seconds < 10 ? `0${time._data.seconds}` : time._data.seconds

    const formatTime =
      cooldownIndex > 1 ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`

    this.setState({
      timeLeft: formatTime,
    })
  }

  render() {
    const { color, cooldownIndex } = this.props
    const { currentProgress, timeLeft } = this.state
    const maxAmount = recruitingList[cooldownIndex].amount
    return (
      <div className="recruiting-cooldown">
        <div
          className="fighter-subtitle text-center"
          style={{ color, margin: 0, marginBottom: 10 }}
        >
          <span className="recruiting-cooldown-label">RECRUITMENT SPEED</span>

          <div
            className="recruting-cooldown-tooltip dib"
            onMouseEnter={() => this.toggleTooltip(true)}
            onMouseLeave={() => this.toggleTooltip(false)}
          >
            <InfoIcon className="tooltip-arrow" />
            <Tooltip
              className="recruting-cooldown-tooltip-wrp fz-sm"
              wrapClassName="recruting-cooldown"
              hide={!this.state.showTooltip}
            >
              <TooltipContent cooldownIndex={cooldownIndex} />
            </Tooltip>
          </div>
        </div>
        <div className="noselect stat-row">
          <div className="stat-label-row flex justify-between">
            {recruitingList.map(
              ({ id, title }) =>
                cooldownIndex === id ? (
                  <p key={id} className="stat-label">
                    {title}
                  </p>
                ) : null
            )}
            {cooldownIndex >= 6 && (
              <p className="stat-label">{recruitingList[recruitingList.length - 1].title}</p>
            )}
            <p className="stat-sublabel self-end">
              <span>{timeLeft}</span>
            </p>
          </div>
          <Progress color={color} progress={currentProgress} maxAmount={maxAmount} />
        </div>
      </div>
    )
  }
}

const TooltipContent = ({ cooldownIndex }) => (
  <div>
    <strong className="tooltip-title text-default">Recruiting Cooldown</strong>
    <p className="tooltip-intro text-muted">
      The Recruiting Cooldown determines whether your captain can win a new fighter in battle. This
      cooldown has total of seven levels of speed:
    </p>
    <ul className="nav-list">
      {recruitingList.map(({ id, title }) => {
        const className =
          cooldownIndex === id || (cooldownIndex >= 6 && id === 6) ? 'text-primary' : 'text-muted'
        return (
          <li key={id} className={className}>
            {title}
          </li>
        )
      })}
    </ul>
  </div>
)

TooltipContent.propTypes = {
  cooldownIndex: PropTypes.number.isRequired,
}

RecruitingCooldown.propTypes = {
  color: PropTypes.string.isRequired,
  cooldownIndex: PropTypes.number,
}

RecruitingCooldown.defaultProp = {
  cooldownIndex: 0,
}

export default RecruitingCooldown
