import React from 'react'
import { Row, Col } from 'react-bootstrap'
import MainAction from '../buttons/MainAction'
import { HeaderText } from './'

const styles = {
  container: {
    borderRadius: 8,
  },
  xsContainer: {
    marginLeft: 20,
    marginRight: 20,
    textAlign: 'center',
  },
  bodyText: {
    fontSize: 18,
    lineHeight: 1.44,
    color: '#555555',
    paddingTop: 16,
  },
  image: {
    width: '100%',
    objectFit: 'contain',
  },
}

const LandingContent = ({ dark, textAlign, header, body, image, buttons }) => (
  <Row style={{ marginTop: 15, marginBottom: 15 }}>
    <Col sm={8} smOffset={2} xsHidden>
      <div
        style={{
          ...styles.container,
          backgroundColor: dark ? '#f9f9f9' : '#fff',
          textAlign,
        }}
      >
        <Row style={{ paddingTop: 56, paddingBottom: 56, alignItems: 'center', display: 'flex' }}>
          {image && textAlign === 'right' ? (
            <Col sm={5}>
              <img style={styles.image} src={image} alt="" />
            </Col>
          ) : null}

          <Col sm={textAlign === 'center' ? 10 : 7} smOffset={textAlign === 'center' ? 1 : 0}>
            <HeaderText suffix={header.suffix}>{header.main}</HeaderText>
            {body ? <div style={styles.bodyText}>{body}</div> : null}
            {buttons ? (
              <div style={{ paddingTop: 30, display: 'flex', justifyContent: 'space-around' }}>
                {buttons.map(({ label, onClick }) => (
                  <MainAction key={label} onClick={onClick ? onClick : () => {}}>
                    {label}
                  </MainAction>
                ))}
              </div>
            ) : null}
          </Col>

          {image && textAlign === 'left' ? (
            <Col sm={5}>
              <img style={styles.image} src={image} alt="" />
            </Col>
          ) : null}
        </Row>
      </div>
    </Col>

    <Col xs={12} smHidden mdHidden lgHidden>
      <div
        style={{
          ...styles.container,
          ...styles.xsContainer,
          backgroundColor: dark ? '#f9f9f9' : '#fff',
        }}
      >
        {image ? (
          <Row style={{ paddingTop: 36 }}>
            <Col xs={12}>
              <img style={styles.image} src={image} alt="" />
            </Col>
          </Row>
        ) : null}
        <Row
          style={{
            paddingTop: 36,
            paddingBottom: 36,
            paddingLeft: 15,
            paddingRight: 15,
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Col xs={12}>
            <HeaderText suffix={header.suffix}>{header.main}</HeaderText>
            {body ? <div style={styles.bodyText}>{body}</div> : null}
            {buttons ? (
              <div style={{ paddingTop: 30, display: 'flex', justifyContent: 'space-around' }}>
                {buttons.map(({ label, onClick }) => (
                  <MainAction key={label} onClick={onClick ? onClick : () => {}}>
                    {label}
                  </MainAction>
                ))}
              </div>
            ) : null}
          </Col>
        </Row>
      </div>
    </Col>
  </Row>
)

export default LandingContent
