import React from 'react'

const styles = {
  text: {
    fontSize: 32,
    fontWeight: 'bold',
    color: '#030f23',
  },
  accentText: {
    color: '#2196f3',
  },
}

const HeaderText = ({ children, suffix, prefix }) => (
  <div style={styles.text}>
    {prefix ? <span style={styles.accentText}>{prefix}</span> : null}

    <span>{children}</span>

    {suffix ? <span style={styles.accentText}>{suffix}</span> : null}
  </div>
)

export default HeaderText
