import React from 'react'
import PropTypes from 'prop-types'
import Level from '../../components/ViewFighter/Level'
import { getLevel } from '../../utils/fighter'

const RenderLevelSection = props => {
  const { color, experience } = props.fighter
  const { level, maxExp } = getLevel(experience)

  return (
    <div className="fighter-statisctic-block">
      <div className="text-center">
        <Level
          color={color.primary}
          level={level}
          exp={experience}
          maxExp={maxExp}
          showTextLevel={false}
          showExp={false}
        />
      </div>
    </div>
  )
}

RenderLevelSection.propTypes = {
  fighter: PropTypes.instanceOf(Object),
}

export default RenderLevelSection
