import React from 'react'
// import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { getLevel } from '../../utils/fighter'
import MainAction from '../../components/buttons/MainAction'
import BattleIcon from '../../components/Header/components/Icons/BattleIcon'
import RankTooltip from '../RankTooltip'
import TooltipContent from './TooltipContent'
import './style.css'

function hexToRgbA(hex, a) {
  var c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${a})`
  }
  console.error('Bad hex', hex, a)
  // throw new Error('Bad Hex')
}

const TeamRow = ({
  onClickFight,
  removeTeam,
  owner,
  fighters,
  teamId,
  leaderId,
  inArena,
  streak,
  isOwner,
  removing,
  fighting,
}) => {
  // const recordArray = streak ? streak.toLowerCase().split('') : []
  const opponentsRowClass = isOwner ? 'my-arena' : ''
  // const filterByChar = record => {
  //   return recordArray.map((char, i) => {
  //     if (char.charCodeAt() === 108) {
  //       return (
  //         <span key={i} className="text-danger">
  //           {char}
  //         </span>
  //       )
  //     }
  //     return (
  //       <span key={i} className="text-success">
  //         {char}
  //       </span>
  //     )
  //   })
  // }

  // TODO: make sure everything is good for different fighter images
  return fighters && fighters.length ? (
    <div className={`opponents-row ${opponentsRowClass}`}>
      <div className="opponents-image-block">
        {fighters.map((fighter, i) => {
          if (fighter) {
            const { fighterId, attr, /*level,*/ image, experience, color } = fighter
            return (
              <div
                key={fighterId}
                className={`opponent-block opponent-type-${attr[0]} opponent-${
                  fighterId === leaderId ? 'captain' : 'team'
                }`}
              >
                <div
                  className="opponent-img-wrapper"
                  style={{
                    backgroundColor: color.secondary,
                    borderColor: color.star || color.primary,
                  }}
                >
                  <img src={image} alt={attr[0]} />
                </div>
                <div
                  className="opponent-level"
                  style={{
                    backgroundColor:
                      i === 0 ? hexToRgbA(color.star, 1) : hexToRgbA(color.star, 0.8),
                  }}
                >
                  <span
                    style={{
                      width: '100%',
                      position: 'absolute',
                      bottom: 1,
                      textAlign: 'center',
                      paddingLeft: 5,
                    }}
                  >
                    {getLevel(experience).level}
                  </span>
                </div>
              </div>
            )
          }
          return null
        })}
      </div>
      <RankTooltip
        className="tooltip-section no-events shadow-xs"
        tooltipPosition="top"
        tooltipSize="lg"
        tooltipResponsive
        noEvents
        tooltipArrow
        arrowPossition="left"
      >
        <TooltipContent fighters={fighters} />
      </RankTooltip>
      <div className="opponents-right-wrapper grid-xs grid-column-two-xs">
        {isOwner && (
          <div className="opponent-block-record">
            <label className="opponent-record-label text-uppercase text-muted fz-xs">status</label>
            <div className="opponent-record-text fz-md">This team is in the arena</div>
          </div>
        )}

        {/* {!isOwner && (
          <div className="opponent-block-record">
            <label className="opponent-record-label text-uppercase text-muted fz-xs">record</label>
            <div className="opponent-record-text fz-md text-uppercase">
              {filterByChar().length > 0 ? filterByChar() : <span className="text-muted">--</span>}
            </div>
          </div>
        )} */}

        {!isOwner && (
          <div className="opponent-block-owner">
            <Link to={`/my-fighters/${owner}`} style={{ textDecoration: 'none', color: '#000' }}>
              <label className="opponent-record-label text-uppercase text-muted fz-xs">owner</label>
              <div className="opponent-record-text cut-text cut-text-xs fz-md">{owner}</div>
            </Link>
          </div>
        )}

        <div className="opponent-block-action grid-row-landing-xs">
          {!isOwner && (
            <MainAction onClick={onClickFight} loading={fighting}>
              <div className="flex items-center justify-center">
                <BattleIcon width={20} height={20} color={'#fff'} />
                <span className="btn-text icon-left">FIGHT</span>
              </div>
            </MainAction>
          )}
          {isOwner && (
            <MainAction onClick={removeTeam} color={'#ccc'} loading={removing}>
              REMOVE
            </MainAction>
          )}
        </div>
      </div>
    </div>
  ) : null
}

export default TeamRow
