import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import getWeb3 from './utils/getWeb3'
import Router from './Router'
import store from './store'
import { initializeApp } from './actions'
import { AUTO_CLOSE_TOAST_TIMEOUT } from './consts'

import './css/noselect.css'
import './css/roboto.css'
import './css/pure-min.css'
import './css/animations.css'
import './App.css'
import './index.css'

class App extends Component {
  componentDidMount() {
    getWeb3()
    store.dispatch(initializeApp())
  }

  render() {
    return (
      <div style={{ overflowX: 'hidden' }}>
        <Provider store={store}>
          <div>
            <Router />
            <ToastContainer autoClose={AUTO_CLOSE_TOAST_TIMEOUT} />
          </div>
        </Provider>
      </div>
    )
  }
}

export default App
