import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import BuyChart from '../BuyChart'
import Button from '../buttons/MainAction'
import getWeb3, { fromWei } from '../../utils/getWeb3'

const Stat = styled.div`
  align-self: center;
  margin-right: 40px;
`
const StatValue = styled.div`
  font-size: 16px;
  line-height: 1;
  text-align: left;
  color: #030f23;
`
const GreyText = styled.div`
  font-size: 13px;
  line-height: 1.14;
  text-align: left;
  color: #9b9b9b;
`
const Header = GreyText.extend`
  margin-bottom: 4px;
  margin-top: 1px;
`
const BottomPriceValue = styled.span`
  color: #4a4a4a;
`
const ForSaleHeader = styled.div`
  font-size: 14px;
  line-height: 1;
  text-align: center;
  margin-bottom: 10px;
  color: ${props => props.color};
`
const PriceRange = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
`
const WaitingMessage = styled.div`
  font-size: 18px;
  padding-top: 20px;
`
const SaleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
`

class BuySection extends PureComponent {
  componentDidMount() {
    getWeb3().then(
      web3 =>
        new Promise((resolve, reject) =>
          web3.eth.getAccounts((error, accounts) => {
            if (error) return reject(error)

            web3.eth.getBalance(accounts[0], (error, balance) => {
              if (error) return reject(error)

              this.setState({ user: accounts[0], balance })
            })
          })
        )
    )
  }

  renderStat = (header, value) => (
    <Stat>
      <Header>{header}</Header>
      <StatValue>{value}</StatValue>
    </Stat>
  )

  renderBottomPrice = (label, value) => (
    <GreyText>
      <span>{label}</span>
      <BottomPriceValue>{value}</BottomPriceValue>
    </GreyText>
  )

  render() {
    const {
      primaryColor,
      secondaryColor,
      endAt,
      // currentPriceEth,
      currentPrice,
      startingPrice,
      endingPrice,
      onClick,
      bidLoading,
      isOwner,
      onClickCancel,
      xs,
      disableChart,
    } = this.props

    const currentPriceEth = Number(fromWei(currentPrice, 'ether')).toFixed(4)
    const displayStartingPrice = startingPrice
      ? Number(fromWei(startingPrice, 'ether')).toFixed(4)
      : null
    const displayEndingPrice = endingPrice ? Number(fromWei(endingPrice, 'ether')).toFixed(4) : null

    return (
      <div>
        <ForSaleHeader color={primaryColor}>FOR SALE</ForSaleHeader>
        <SaleWrapper>
          <div style={{ display: 'flex' }}>
            {this.renderStat('Buy now price', `${currentPriceEth} ETH`)}
            {this.renderStat('Time left', moment(endAt * 1000).fromNow(true))}
          </div>
          <Button
            color={primaryColor}
            onClick={isOwner ? onClickCancel : onClick}
            loading={bidLoading}
            style={xs ? { zIndex: 999 } : null}
          >
            {isOwner ? 'CANCEL AUCTION' : 'BUY FIGHTER'}
          </Button>
        </SaleWrapper>
        {bidLoading ? (
          <WaitingMessage>
            Waiting for blockchain... Check back in a few minutes{' '}
            <span role="img" aria-label="Timer">
              ⏳
            </span>
          </WaitingMessage>
        ) : (
          <div>
            {!disableChart && (
              <BuyChart
                {...this.props}
                backgroundColor={primaryColor}
                secondaryColor={secondaryColor}
              />
            )}
            <PriceRange>
              {this.renderBottomPrice('Starting @ ', displayStartingPrice)}
              {this.renderBottomPrice('Ending @ ', displayEndingPrice)}
            </PriceRange>
          </div>
        )}
      </div>
    )
  }
}

BuySection.propTypes = {
  primaryColor: PropTypes.string.isRequired,
  secondaryColor: PropTypes.string.isRequired,
  currentPrice: PropTypes.object.isRequired,
  startingPrice: PropTypes.number.isRequired,
  endingPrice: PropTypes.number.isRequired,
  endAt: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default BuySection
