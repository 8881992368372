import axios from 'axios'

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

if (!API_ENDPOINT) console.error('ERROR: API_ENDPOINT is not set')

// GET /fighters/all?page=1&sort={{enum}}
// - Returns all fighters mapped to address
const getAllFighters = (page, sort) =>
  axios.get(`${API_ENDPOINT}/fighters/all?page=${page}&sort=${sort}`).then(({ data }) => data)

// GET /fighters/:fighter_id
// - Gets a fighter id and returns it's details
const getFighter = id => axios.get(`${API_ENDPOINT}/fighters/${id}`).then(({ data }) => data)

// GET /owns/:address
// - Returns all fighters owned by user
const getMyFighters = address =>
  axios.get(`${API_ENDPOINT}/owns/${address && address.toLowerCase()}`).then(({ data }) => data)

// GET /auctions/all?page=1&sort={{enum}}&return_fighter=true
// - Returns all the auctions
const getFightersOnSale = (page, sort, gen) => {
  const filters = gen === 0 || gen ? `&filters=gen:${gen}` : ''

  return axios
    .get(`${API_ENDPOINT}/auctions/all?page=${page}&sort=${sort}&return_fighter=true${filters}`)
    .then(({ data }) => data)
}

// GET /auctions/all?page=1&sort={{enum}}
// - Returns all the auctions
const getAllActions = (page, sort) =>
  axios.get(`${API_ENDPOINT}/auctions/all?page=${page}&sort=${sort}`).then(({ data }) => data)

// GET /auctions/:auctionId
// - Returns a JSON object of the auction details
const getAuction = id => axios.get(`${API_ENDPOINT}/auctions/${id}`).then(({ data }) => data)

// GET /arena/live
// - Returns all teams currently in arena
const getTeamsInArena = (page = 0) =>
  axios.get(`${API_ENDPOINT}/arena/live?page=${page}`).then(({ data }) => data)

// GET /owns/teams?address={{address}}
// - Returns all teams owned by address
const getUsersTeams = address =>
  axios
    .get(`${API_ENDPOINT}/owns/teams?address=${address && address.toLowerCase()}`)
    .then(({ data }) => data)

// GET stats/{{address}}/
// - Returns battle stats for a given user address
const getUserBattleStats = address =>
  axios.get(`${API_ENDPOINT}/stats/${address && address.toLowerCase()}`).then(({ data }) => data)

// GET battles/result/{{txHash}}
// - Returns results of battle with txHash
const getBattleResult = txHash =>
  axios
    .get(`${API_ENDPOINT}/battles/result/${txHash && txHash.toLowerCase()}`)
    .then(({ data }) => data)

// GET /battles/user/{{address}}
// - Returns battle history of the given user address
const getBattleHistory = address =>
  axios
    .get(`${API_ENDPOINT}/battles/user/${address && address.toLowerCase()}`)
    .then(({ data }) => data)

// GET stats/{{address}}
// - Returns battle stats fora given address
const getBattleStats = address =>
  axios.get(`${API_ENDPOINT}/stats/${address && address.toLowerCase()}`).then(({ data }) => data)

const getLeaderboard = (address, page = 0) => {
  let qs = `?page=${page}`

  if (address) qs += `&address=${address}}`

  return axios.get(`${API_ENDPOINT}/leaderboard/${qs}`).then(({ data }) => data)
}

const getRarity = () => axios.get(`${API_ENDPOINT}/rarity/`).then(({ data }) => data)

export default {
  getAllFighters,
  getFighter,
  getMyFighters,
  getFightersOnSale,
  getAllActions,
  getAuction,
  getTeamsInArena,
  getUsersTeams,
  getUserBattleStats,
  getBattleResult,
  getBattleHistory,
  getBattleStats,
  getLeaderboard,
  getRarity,
}
