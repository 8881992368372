import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  pageButton: {
    margin: theme.spacing.unit,
    minWidth: 'unset',
    padding: '10px 15px',
    borderRadius: 4,
    display: 'inline-block',
    cursor: 'pointer',
  },
})

const PageButton = ({ active, onClick, label, classes }) => (
  <div
    className={classes.pageButton}
    style={{
      color: active ? '#fff' : null,
      background: active ? '#2196f3' : null,
    }}
    onClick={onClick}
  >
    {label}
  </div>
)

export default withStyles(styles)(PageButton)
