import React from 'react'

const styles = {
  text: {
    fontSize: 80,
    fontWeight: 900,
    textAlign: 'left',
    color: '#030f23',
  },
  accentText: {
    color: '#2196f3',
  },
}

const Title = ({ children, prefix, suffix }) => (
  <div style={styles.text}>
    {prefix ? <span style={styles.accentText}>{prefix}</span> : null}

    <span>{children}</span>

    {suffix ? <span style={styles.accentText}>{suffix}</span> : null}
  </div>
)

export default Title
