import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { setActiveTabId } from '../../actions'
import './style.css'

class Tabs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // state
    }
  }

  onClick(id) {
    this.props.setActiveTabId(id)
  }

  getAddresUrl = (url, address, tabKey) =>
    address && tabKey === 'battle-history' ? `${url}/${address}` : url

  render() {
    const { tabsHeader, tabsContent, className, activeTabId, address } = this.props
    return (
      <div className={`tabs-wrapper ${className}`}>
        <div className="tabs-header">
          <ul className="tabs-list text-uppercase list-inline">
            {tabsHeader.map(({ id, link, text, key }) => {
              const isActive = id === activeTabId ? 'tab-active' : ''
              return (
                <li key={id} className={`tabs-list-item-wrapper ${isActive}`}>
                  <Link
                    to={this.getAddresUrl(link, address, key)}
                    onClick={() => {
                      this.onClick(id)
                    }}
                    className="tabs-list-item"
                  >
                    {text}
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="tabs-content">{tabsContent}</div>
      </div>
    )
  }
}

Tabs.propsTypes = {
  className: PropTypes.string,
  tabsHeader: PropTypes.arrayOf(PropTypes.object),
  tabsContent: PropTypes.elemnt,
  active: PropTypes.bool,
  setActiveTabId: PropTypes.func.isRequired,
}

Tabs.defaultProps = {
  tabsHeader: [
    {
      id: 0,
      link: '/route-1',
      text: 'tab 1',
    },
    {
      id: 1,
      link: '/route-2',
      text: 'tab 2',
    },
    {
      id: 2,
      link: '/route-3',
      text: 'tab 3',
    },
  ],
  tabsContent: null,
  className: '',
  active: false,
}

const mapStateToProps = ({ tabs }) => ({
  activeTabId: tabs.activeTabId,
  tabs,
})

const TabsWithRouter = withRouter(Tabs)

export default connect(mapStateToProps, {
  setActiveTabId,
})(TabsWithRouter)
