import React from 'react'
import { Link } from 'react-router-dom'
import FighterSubtitle from './FighterSubtitle'

const styles = {
  img: {
    display: 'inline-block',
    borderRadius: '50%',
    width: 30,
    height: 30,
  },
  link: {
    textDecoration: 'none',
    color: '#000',
  },
  username: {
    // marginLeft: 10,
    // display: 'inline-block',
    // position: 'relative',
    // top: '50%',
    // transform: 'translateY(-50%)',
    fontWeight: 500,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginLeft: 20,
    marginRight: 20,
    // wordWrap: 'break-word',
  },
}

const Owner = ({ color, username /*, imgSrc */ }) => (
  <div className="fadeInLeft">
    <Link to={`/my-fighters/${username}`} style={styles.link}>
      <FighterSubtitle color={color}>OWNER</FighterSubtitle>
      {/* <img style={styles.img} src={imgSrc} /> */}
      <p style={styles.username}>{username}</p>
    </Link>
  </div>
)

export default Owner
