import React from 'react'
import SocialLink from './SocialLink'

const SocialLinks = () => (
  <div>
    <a href="https://discord.gg/e2ZndMA" target="_blank" rel="noopener noreferrer">
      <img
        style={{ maxHeight: 25, marginRight: 10 }}
        src={require('../../images/social/discord.png')}
        alt="Discord"
      />
    </a>
    <SocialLink
      href="https://www.facebook.com/CryptoFighters"
      src={require('../../images/social/facebook.svg')}
      alt="Facebook"
    />
    <SocialLink
      href="https://www.reddit.com/r/CryptoFighters"
      src={require('../../images/social/reddit.svg')}
      alt="Reddit"
    />
    <SocialLink
      href="https://t.me/CryptoFighters"
      src={require('../../images/social/telegram.svg')}
      alt="Telegram"
    />
    <SocialLink
      href="https://twitter.com/CryptoFighters"
      src={require('../../images/social/twitter.svg')}
      alt="Twitter"
    />
  </div>
)

export default SocialLinks
