import React, { Component } from 'react'

const colors = {
  active: '#2196f3',
  disabled: '#bdbdbd',
}

class PreviousArrow extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="10" viewBox="0 0 20 10">
        <path
          fill={this.props.disabled ? colors.disabled : colors.active}
          fillRule="nonzero"
          d="M20 4H4.414l2.293-2.293L5.293.293.586 5l4.707 4.707 1.414-1.414L4.414 6H20z"
        />
      </svg>
    )
  }
}

export default PreviousArrow
