import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import HeaderLink from './components/HeaderLink'
import '../../css/fredoka-one.css'
import HeaderLogo from '../../images/img-logo.svg'

const headerHeight = 80

const styles = {
  rootXS: {
    width: '100%',
    height: 70,
    background: '#093272',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
  },
  root: {
    position: 'relative',
    // padding: '18px',
    width: '100%',
    height: headerHeight,
    background: '#093272',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
  },
  title: {
    color: '#fff',
    fontFamily: 'Fredoka One',
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: headerHeight,
    display: 'inline-block',
  },
  link: {
    textDecoration: 'none',
    display: 'inline-block',
  },
  headerLinksXS: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerLinks: {
    position: 'absolute',
    right: 0,
    display: 'inline-block',
    height: '100%',
  },
  headerLogo: {
    height: headerHeight - 10 - 10,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
}

const Header = props => (
  <header className="header">
    <Row>
      <Col xs={12} smHidden mdHidden lgHidden>
        <Row>
          <Col xs={12}>
            <div style={{ ...styles.rootXS, display: 'flex', justifyContent: 'center' }}>
              <img style={styles.headerLogo} src={HeaderLogo} alt="CryptoFighters" />
            </div>
          </Col>
          <Col xs={12}>
            <div style={styles.rootXS}>
              <div style={styles.headerLinksXS}>
                <HeaderLink
                  to="/my-fighters"
                  active={props.page === 'my-fighters'}
                  icon="my-fighters"
                  text="MY FIGHTERS"
                />
                <HeaderLink
                  to="/market"
                  active={props.page === 'market'}
                  icon="market"
                  text="MARKETPLACE"
                />
                {/* <HeaderLink
                  to="/profile"
                  active={props.page === 'profile'}
                  icon="profile"
                  text="MY PROFILE"
                /> */}
                <HeaderLink
                  to="/arena"
                  active={props.page === 'arena'}
                  // style={{ background: '#2196f3', color: 'rgba(255, 255, 255, 0.5)' }}
                  icon="battle"
                  text="ARENA"
                  xs
                />
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Col sm={12} xsHidden>
        <div style={styles.root}>
          <Link to="/" style={styles.link}>
            <img
              style={{ ...styles.headerLogo, marginLeft: '60px' }}
              src={HeaderLogo}
              alt="CryptoFighters"
            />
          </Link>
          <div style={styles.headerLinks}>
            <HeaderLink
              to="/my-fighters"
              active={props.page === 'my-fighters'}
              icon="my-fighters"
              text="MY FIGHTERS"
            />
            <HeaderLink
              to="/market"
              active={props.page === 'market'}
              icon="market"
              text="MARKETPLACE"
            />
            {/* <HeaderLink
              to="/profile"
              active={props.page === 'profile'}
              icon="profile"
              text="MY PROFILE"
            /> */}
            <HeaderLink
              to="/arena"
              active={props.page === 'arena'}
              // style={{ background: '#2196f3', color: 'rgba(255, 255, 255, 0.5)' }}
              icon="battle"
              text="ARENA"
            />
          </div>
        </div>
      </Col>
    </Row>
  </header>
)

export default Header
