import React, { Component } from 'react'
import api from '../../utils/api'
import BattleHistory from './BattleHistory'

export default class BattleHistoryContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      battles: null,
      loading: true,
    }
  }

  componentDidMount() {
    const { match: { params: { address } } } = this.props

    api
      .getBattleHistory(address)
      .then(battles => {
        this.setState({ battles, loading: false })
      })
      .catch(error =>
        console.error(`Error getting battle history for address: ${address}. Error: ${error}`)
      )
  }

  render() {
    const { match: { params: { address } } } = this.props
    const { battles, loading } = this.state

    return <BattleHistory address={address} battles={battles} loading={loading} />
  }
}
