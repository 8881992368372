import React from 'react'
import Hider from '../../Hider'
import closeIcon from '../../../images/close-icon.svg'

const styles = {
  closeModal: {
    position: 'absolute',
    right: '20px',
    top: '20px',
    cursor: 'pointer',
  },
}

const CloseModal = ({ hide, close }) => (
  <Hider hide={hide}>
    <div style={styles.closeModal} onClick={close}>
      <img alt="close" src={closeIcon} />
    </div>
  </Hider>
)

export default CloseModal
