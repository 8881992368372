import React from 'react'

const PrizeCooldownIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 14 14">
    <path
      fill={color}
      fillRule="nonzero"
      d="M12.6 4.186h-2.18c.318-.16.61-.368.865-.617a2.107 2.107 0 0 0 0-2.973C10.49-.2 9.109-.2 8.315.596 7.17 1.743 7.014 3.903 7 4.146c-.014-.244-.17-2.403-1.315-3.55-.794-.795-2.177-.794-2.97 0A2.092 2.092 0 0 0 2.1 2.083c0 .561.22 1.09.615 1.486.25.251.55.452.866.617H1.4c-.773 0-1.4.628-1.4 1.402V7.69a.7.7 0 0 0 .7.7v4.207C.7 13.37 1.327 14 2.1 14h9.8c.773 0 1.4-.629 1.4-1.402V8.392a.7.7 0 0 0 .7-.701V5.588c0-.774-.627-1.402-1.4-1.402zm-8.4 8.412H2.1V8.392h2.1v4.206zm0-5.608H1.4V5.588h2.8V6.99zm-.495-4.412a.697.697 0 0 1-.205-.495.697.697 0 0 1 .7-.7c.188 0 .363.072.495.205.413.413.654 1.154.783 1.773-.616-.13-1.362-.37-1.773-.783zM8.4 12.598H5.6V8.392h2.8v4.206zm0-5.608H5.6V5.588h2.8V6.99zm.905-5.402a.696.696 0 0 1 .99 0 .703.703 0 0 1 0 .99c-.414.415-1.155.656-1.773.785.129-.62.37-1.362.783-1.775zm2.595 11.01H9.8V8.392h2.1v4.206zm.7-5.608H9.8V5.588h2.8V6.99z"
    />
  </svg>
)

export default PrizeCooldownIcon
