import React from 'react'

const styles = {
  heading: {
    marginTop: '40px',
    marginBottom: '10px',
  },
}

const DocHeading = ({ children }) => <h3 style={styles.heading}>{children}</h3>

export default DocHeading
