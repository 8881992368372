import React from 'react'

const BattleIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 16">
    <g fill="none" fillRule="evenodd">
      <path d="M0-2h20v20H0z" />
      <path
        fill={color}
        fillRule="nonzero"
        d="M19.1 6.838l-2.5-5.833A.833.833 0 0 0 15.832.5H4.167a.832.832 0 0 0-.766.505L.9 6.838A.832.832 0 0 0 1.667 8H2.5v6.667c0 .46.373.833.833.833h8.334V9.667H15V15.5h1.667c.46 0 .833-.373.833-.833V8h.833a.832.832 0 0 0 .766-1.162zM9.166 13H5V9.667h4.167V13zm7.902-6.667h-2.274l-1.19-4.166h1.68l1.784 4.166zm-10.13 0l1.19-4.166h1.038v4.166H6.938zm3.894-4.166h1.039l1.19 4.166h-2.229V2.167zm-6.117 0h1.679l-1.19 4.166H2.93l1.786-4.166z"
      />
    </g>
  </svg>
)

export default BattleIcon
