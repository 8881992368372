import React from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { getLevel } from '../../utils/fighter'
import { FIGHTER_COLORS } from '../../consts'
import '../TeamRow/style.css'

const styles = {
  wrapper: {
    width: '100%',
    borderRadius: 8,
    backgroundColor: '#fff',
    borderColor: '#eee',
    borderStyle: 'solid',
    borderWidth: 1,
    padding: '12px 24px',
    marginBottom: 4,
    cursor: 'pointer',
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  contentRow: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  status: {
    fontSize: 14,
    fontWeight: 'bold',
    marginRight: '3%',
    width: 35,
  },
  seperator: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#030f23',
  },
  rightSpace: {
    marginLeft: '3%',
    width: 35,
  },
  ownerLabel: {
    fontSize: 12,
    color: '#9b9b9b',
    paddingTop: 14,
    paddingBottom: 5,
    width: 130,
    textAlign: 'center',
  },
  ownerValue: {
    fontSize: 16,
    color: '#030f23',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: 130,
    textAlign: 'center',
  },
  link: {
    textDecoration: 'none',
    color: '#000',
  },
}

function hexToRgbA(hex, a) {
  var c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${a})`
  }
  console.error('Bad hex', hex, a)
  // throw new Error('Bad Hex')
}

const FighterGroup = ({ fighter, total, index }) => {
  if (fighter) {
    const { fighterId, attr, image, experience } = fighter

    const color = FIGHTER_COLORS[fighter.color]

    return (
      <div
        key={fighterId}
        className={`opponent-block opponent-type-${attr[0]} opponent-${
          index === 0 && total > 1 ? 'captain' : 'team'
        }`}
      >
        <div
          className="opponent-img-wrapper"
          style={{
            backgroundColor: color.secondary,
            borderColor: color.star || color.primary,
          }}
        >
          <img src={image} alt={attr[0]} />
        </div>
        <div
          className="opponent-level"
          style={{
            backgroundColor: index === 0 ? hexToRgbA(color.star, 1) : hexToRgbA(color.star, 0.8),
          }}
        >
          <span
            style={{
              width: '100%',
              position: 'absolute',
              bottom: 1,
              textAlign: 'center',
              paddingLeft: 5,
            }}
          >
            {getLevel(experience).level}
          </span>
        </div>
      </div>
    )
  }
  return null
}

const BattleHistoryRow = props => {
  const {
    userAddr,
    attackerWon,
    attackerFighters,
    defenderFighters,
    winnerAddress,
    loserAddress,
    transactionHash,
    history,
  } = props

  const isWinner =
    userAddr === winnerAddress || userAddr.toLowerCase() === winnerAddress.toLowerCase()
  const isLoser = !isWinner
  let myFighters, opponentFighters, opponentAddress
  if (isWinner) {
    myFighters = attackerWon ? attackerFighters : defenderFighters
    opponentFighters = attackerWon ? defenderFighters : attackerFighters
    opponentAddress = loserAddress
  } else if (isLoser) {
    myFighters = attackerWon ? defenderFighters : attackerFighters
    opponentFighters = attackerWon ? attackerFighters : defenderFighters
    opponentAddress = winnerAddress
  }

  if (!myFighters) {
    console.log('My Fighters is empty')
    return null
  }

  return (
    <div
      style={styles.wrapper}
      onClick={() => {
        history.push(`/arena/results/${transactionHash}`)
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ ...styles.status, color: isWinner ? '#2ecc71' : '#e74c3c' }}>
          {isWinner ? 'WIN' : 'LOSS'}
        </div>
        <div style={styles.mainContent}>
          <div style={{ ...styles.contentRow, justifyContent: 'space-between' }}>
            <div
              className="opponents-image-block"
              style={{ width: '45%', display: 'flex', justifyContent: 'flex-end' }}
            >
              {myFighters.map((fighter, i) => {
                return (
                  <FighterGroup
                    key={fighter.fighterId}
                    fighter={fighter}
                    total={myFighters.length}
                    index={i}
                  />
                )
              })}
            </div>
            <div style={styles.seperator}>VS</div>
            <div
              className="opponents-image-block"
              style={{ width: '45%', display: 'flex', justifyContent: 'flex-start' }}
            >
              {opponentFighters.map((fighter, i) => {
                return (
                  <FighterGroup
                    key={fighter.fighterId}
                    fighter={fighter}
                    total={opponentFighters.length}
                    index={i}
                  />
                )
              })}
            </div>
          </div>
          <div style={{ ...styles.contentRow, justifyContent: 'space-between' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                width: '45%',
              }}
            >
              <div style={{ ...styles.ownerLabel, textAlign: 'right' }}>OWNER</div>
              <div style={{ ...styles.ownerValue, textAlign: 'right' }}>YOU</div>
            </div>
            <div />
            <Link
              to={`/my-fighters/${opponentAddress}`}
              style={{
                ...styles.link,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                width: '45%',
              }}
            >
              <div style={{ ...styles.ownerLabel, textAlign: 'left' }}>OWNER</div>
              <div style={{ ...styles.ownerValue, textAlign: 'left' }}>{opponentAddress}</div>
            </Link>
          </div>
        </div>
        <div style={styles.rightSpace} />
      </div>
    </div>
  )
}

export default withRouter(BattleHistoryRow)
