import { app as appTypes } from '../actions/types'

const INIT_APP_STATE = {
  initialized: false,
  arenaPage: 0,
}

const actions = {
  [appTypes.initializeApp]: (state, payload) => ({
    ...state,
    initialized: true,
  }),

  [appTypes.setArenaPage]: (state, payload) => ({
    ...state,
    arenaPage: payload.page,
  }),
}

const app = (state = INIT_APP_STATE, action) => {
  if (actions[action.type]) {
    return actions[action.type](state, action.payload)
  }
  return state
}

export default app
