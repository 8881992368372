import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Content } from './'

const styles = {
  container: {
    marginTop: 24,
  },
  image: {
    width: '100%',
    minWidth: 820,
    minHeight: 527,
    objectFit: 'contain',
    userSelect: 'none',
  },
  xsContent: {
    marginLeft: 20,
    marginRight: 20,
    zIndex: 999,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
  },
  xsImageContainer: {
    position: 'absolute',
    top: -24,
    left: -120,
  },
  xsImage: {
    opacity: 0.2,
  },
}

const Hero = ({ title, subtitle, mainAction, image }) => (
  <Row style={styles.container}>
    <Col sm={5} md={4} smOffset={1} xsHidden>
      <Content title={title} subtitle={subtitle} mainAction={mainAction} />
    </Col>
    <Col sm={6} md={7} xsHidden>
      <img style={styles.image} src={image} alt="CryptoFighters" />
    </Col>

    <Col xs={12} smHidden mdHidden lgHidden>
      <div style={styles.xsContent}>
        <Content title={title} subtitle={subtitle} mainAction={mainAction} xs />
      </div>
      <div style={styles.xsImageContainer}>
        <img style={{ ...styles.image, ...styles.xsImage }} src={image} alt="CryptoFighters" />
      </div>
    </Col>
  </Row>
)

export default Hero
