import React, { Component } from 'react'

const styles = {
  container: {
    margin: '0px 15px',
  },
  profilePic: {
    borderRadius: '50%',
    width: 30,
    height: 30,
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: 5,
    position: 'relative',
    top: '-2px',
  },
  username: {
    color: '#BDBDBD',
    fontWeight: 'bold',
    display: 'inline-block',
  },
}

const profilePic =
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvXdRy31NQ1zdN8xb21umz-GWoe8XpF6gRPlrhGINdMPY4x2Ge'

class CurrentUser extends Component {
  render() {
    return (
      <div style={styles.container}>
        <img style={styles.profilePic} src={profilePic} alt="profile" />
        <p style={styles.username}>USER89765</p>
      </div>
    )
  }
}

export default CurrentUser
