import React from 'react'
import InfoIcon from '../../../InfoIcon'
import RankTooltip from '../../../RankTooltip'
import './style.css'

const styles = {
  container: {},
  name: {
    color: '#000',
    margin: 0,
    fontWeight: 500,
  },
  gen: {
    margin: 0,
    marginLeft: 5,
    fontSize: 14,
    color: '#9B9B9B',
    letterSpacing: 0,
  },
  info: {
    position: 'relative',
    top: 1,
    display: 'inline-block',
  },
}

const FighterText = ({ name, level, rank, generation, showGenLevel }) => {
  return (
    <div className="fighter-text" style={styles.container}>
      <p style={styles.name}>{name}</p>
      <div className="fighter-gen">
        <div style={{ display: 'inline-block' }}>
          <InfoIcon />
          <div className="rankToolTip">
            <RankTooltip rank={rank} generation={generation} />
          </div>
        </div>
        <p
          style={{
            display: 'inline-block',
            margin: 0,
            marginLeft: '5px',
            color: '#9B9B9B',
          }}
        >
          {showGenLevel && <span>{`${rank} (Lv ${level})`}</span>}
          {!showGenLevel && <span>{`${rank}`}</span>}
        </p>
      </div>

      {/*
        <div>
          <div style={styles.info}>
            <Info />
          </div>
          <span style={styles.gen}>{'Gen ' + this.props.gen + ' • BR 4927'}</span>
        </div>
      */}
    </div>
  )
}

export default FighterText
