import React from 'react'
import { Row, Col } from 'react-bootstrap'
import FighterCard from '../FighterCard'

const getDelay = i => {
  let delay = 0

  if (i / 4 >= 1) {
    delay = i * 0.1 - Math.floor(i / 4) * 0.2
  } else {
    delay = i * 0.1 + 0.1
  }

  return delay
}

const FighterGallery = ({
  fighters = [],
  noFighters,
  noAnimation,
  onClickAction,
  addAction,
  disabled,
  enableLink,
  showOnAuction,
}) => (
  <div style={{ width: '100%', overflow: 'hidden', padding: 20 }}>
    <Row>
      {fighters.map((fighter, i) => (
        <Col lg={noFighters ? 4 : 3} md={4} sm={noFighters ? 12 : 6} xs={12} key={fighter.id}>
          <FighterCard
            {...fighter}
            addAction={addAction || (showOnAuction && fighter.onAuction)}
            enableLink={enableLink}
            onClickAction={onClickAction}
            fadeInDelay={noAnimation ? null : getDelay(i)}
            noAnimation={noAnimation}
            disabled={disabled}
          />
        </Col>
      ))}
    </Row>
  </div>
)

export default FighterGallery
