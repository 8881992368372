import React from 'react'

const styles = {
  title: {
    color: '#2196f3',
    fontWeight: 500,
  },
  seperator: {
    display: 'inline-block',
    height: '2px',
    width: '40px',
    background: '#2196f3',
  },
}

const FAQSidebarTitle = ({ children }) => (
  <div>
    <div style={styles.title}>{children}</div>
    <div style={styles.seperator} />
  </div>
)

export default FAQSidebarTitle
