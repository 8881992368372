import React from 'react'
import { Row, Col } from 'react-bootstrap'
import FighterTeamMember from '../FighterTeamMember'

const FighterTeam = ({
  team,
  onClickEmpty,
  onClickPopulated,
  edit,
  removeFighter,
  submittingTeam,
}) => {
  const teamMembers = []
  for (let i = 0; i < 5; i++) {
    if (team && team[i]) {
      teamMembers.push(team[i])
    } else {
      teamMembers.push(null)
    }
  }
  return (
    <Row>
      <Col md={12} xsHidden smHidden>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {teamMembers.map((fighter, i) => (
            <FighterTeamMember
              key={`team-${i}`}
              fighter={fighter}
              onClickEmpty={onClickEmpty}
              onClickPopulated={onClickPopulated}
              noLeftSpace={i === 0}
              noRightSpace={i === 4}
              removeFighter={removeFighter}
              first={i === 0}
              edit={edit}
              disabled={submittingTeam}
            />
          ))}
        </div>
      </Col>
      <Col sm={12} xsHidden mdHidden lgHidden>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 8,
            }}
          >
            {teamMembers
              .slice(0, 3)
              .map((fighter, i) => (
                <FighterTeamMember
                  key={`team-${i}`}
                  fighter={fighter}
                  onClickEmpty={onClickEmpty}
                  onClickPopulated={onClickPopulated}
                  noLeftSpace={i === 0}
                  noRightSpace={i === 2}
                  removeFighter={removeFighter}
                  first={i === 0}
                  small
                  edit={edit}
                />
              ))}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
            {teamMembers
              .slice(3, 5)
              .map((fighter, i) => (
                <FighterTeamMember
                  key={`team-${i}`}
                  fighter={fighter}
                  onClickEmpty={onClickEmpty}
                  onClickPopulated={onClickPopulated}
                  noLeftSpace={i === 0}
                  noRightSpace={i === 1}
                  removeFighter={removeFighter}
                  small
                  edit={edit}
                />
              ))}
          </div>
        </div>
      </Col>
      <Col xs={12} smHidden mdHidden lgHidden>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {teamMembers.map((fighter, i) => (
            <FighterTeamMember
              key={`team-${i}`}
              fighter={fighter}
              onClickEmpty={onClickEmpty}
              onClickPopulated={onClickPopulated}
              noLeftSpace
              noRightSpace
              removeFighter={removeFighter}
              first={i === 0}
              small
              edit={edit}
            />
          ))}
        </div>
      </Col>
    </Row>
  )
}

export default FighterTeam
