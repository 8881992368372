const FIGHTER_COLORS = [
  // blue
  {
    primary: '#2196f3',
    secondary: '#dbeefd',
    star: '#7ac0f8',
    name: 'blue',
  },
  // green
  {
    primary: '#4caf50',
    secondary: '#dff1e0',
    star: '#80c884',
    name: 'green',
  },
  // gray
  {
    primary: '#757575',
    secondary: '#eeeeee',
    star: '#b1b1b1',
    name: 'gray',
  },
  // pink
  {
    primary: '#e91e63',
    secondary: '#fde3ed',
    star: '#f684b0',
    name: 'pink',
  },
  // purple
  {
    primary: '#6b3fb9',
    secondary: '#eee9f7',
    star: '#b49edc',
    name: 'purple',
  },

  // lime
  {
    primary: '#9e9d24',
    secondary: '#f7f9e0',
    star: '#cbd835',
    name: 'lime',
  },
  // orange
  {
    primary: '#ffa000',
    secondary: '#fff4d5',
    star: '#ffc82b',
    name: 'orange',
  },
]

const RACE_NAMES = ['Half-Elf', 'Orc', 'Succubus', 'Mage', 'Death Knight']

// const UPDATE_AUCTION_PRICE_INTERVAL = 15000 // update every 15 seconds
const UPDATE_AUCTION_PRICE_INTERVAL = 60000 // update every 60 seconds
const AUTO_CLOSE_TOAST_TIMEOUT = 7000

const SOLD_LABEL = 'SOLD'

export {
  FIGHTER_COLORS,
  RACE_NAMES,
  UPDATE_AUCTION_PRICE_INTERVAL,
  AUTO_CLOSE_TOAST_TIMEOUT,
  SOLD_LABEL,
}
