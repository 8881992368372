import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import api from '../../utils/api'
import getWeb3 from '../../utils/getWeb3'
import { Grid, Row, Col } from 'react-bootstrap'
import FighterGallery from '../../components/FighterGallery'
import BounceLoader from '../../components/loaders/BounceLoader'
import MainAction from '../../components/buttons/MainAction'
import BattleStats from '../../components/BattleStats'
import { FIGHTER_COLORS } from '../../consts'
import { getTimeTillCooldown, isOwner } from '../../utils/fighter'

const styles = {
  link: {
    textDecoration: 'none',
  },
}

class MyFightersPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 0,
      sort: 0,
      search: '',
      page: 0,
      fighters: [],
      numberOfFighters: 0,
      recievedFighters: false,
      marketFighters: null,
    }
  }

  componentDidMount() {
    const {
      match: {
        params: { addr },
      },
    } = this.props

    getWeb3()
      .then(
        web3 =>
          new Promise((resolve, reject) =>
            web3.eth.getAccounts((error, accounts) =>
              error
                ? reject(error)
                : web3.eth.getBlockNumber((error, blockNumber) =>
                    error ? reject(error) : resolve({ user: accounts[0], blockNumber })
                  )
            )
          )
      )
      .then(({ user, blockNumber }) =>
        user == null
          ? this.redirectToLocked()
          : this.setState({ user, pageAddr: addr || user, blockNumber })
      )
      .then(() => (this.state.user == null ? this.redirectToLocked() : Promise.resolve()))
      .then(this.getBattleStats)
      .then(this.getFighters)
  }

  getBattleStats = () => {
    api.getBattleStats(this.state.pageAddr).then(data => {
      if (!data) return

      const battlesFought = data.wins + data.losses

      const winRate = battlesFought ? parseInt((data.wins * 100) / battlesFought, 10) : 0

      this.setState({
        stats: {
          wins: data.wins,
          losses: data.losses,
          winRate,
        },
      })
    })
  }

  getFighters = () => {
    api.getMyFighters(this.state.pageAddr).then(fightersArr => {
      const fighters = fightersArr.map(f => ({
        ...f,
        isOwner: f.owner === this.state.user,
        id: f.fighterId,
        color: FIGHTER_COLORS[f.color],
        timeTillBattleCooldown: getTimeTillCooldown(
          f.battleCooldownEndTime,
          this.state.blockNumber
        ),
        timeTillPrizeCooldown: getTimeTillCooldown(f.prizeCooldownEndTime, this.state.blockNumber),
      }))
      this.setState({ fighters, recievedFighters: true })

      if (!fighters.length) {
        api.getFightersOnSale(this.state.page, this.state.sort).then(({ data }) => {
          const marketFighters = data.map(a => ({
            ...a.fighter,
            auction: {
              auctionId: a.auctionId,
              duration: a.duration,
              endingPrice: a.endingPrice,
              startTime: a.startTime,
              startingPrice: a.startingPrice,
            },
            price: a.price,
            isOwner: isOwner(a.fighter, this.state.user),
            id: a.fighterId,
            color: FIGHTER_COLORS[a.fighter.color],
            timeTillBattleCooldown: getTimeTillCooldown(
              a.fighter.battleCooldownEndTime,
              this.state.blockNumber
            ),
            timeTillPrizeCooldown: getTimeTillCooldown(
              a.fighter.prizeCooldownEndTime,
              this.state.blockNumber
            ),
          }))
          this.setState({ marketFighters })
        })
      }
    })
  }

  getMainContent() {
    if (this.state.recievedFighters) {
      if (this.state.fighters.length) {
        return <FighterGallery fighters={this.state.fighters} showOnAuction enableLink />
      } else {
        if (this.state.marketFighters) {
          return (
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <h1>You have no fighters</h1>
              <p>
                <span>Fear not, you can get some like these at the </span>
                <Link className="link" to="/market">
                  Market!
                </Link>
              </p>
              {this.state.marketFighters.length ? (
                <FighterGallery fighters={this.state.marketFighters.slice(0, 3)} noFighters />
              ) : null}
              <div style={{ marginTop: 20 }}>
                <Link to={'/market'} style={styles.link}>
                  <MainAction color={FIGHTER_COLORS[0].primary}>MARKET</MainAction>
                </Link>
              </div>
            </div>
          )
        }
      }
    }
    return (
      <div
        style={{
          display: 'flex',
          height: 400,
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <BounceLoader size={60} />
        <div style={{ marginTop: 30, color: 'rgb(204, 204, 204)', fontSize: 16 }}>
          Loading Fighters
        </div>
      </div>
    )
  }

  redirectToLocked = () =>
    this.props.history.push('/locked', { from: this.props.location.pathname })

  render() {
    return (
      <div style={{ marginBottom: 90 }}>
        <Grid>
          {this.state.pageAddr && this.state.recievedFighters && this.state.stats ? (
            <Row style={{ paddingBottom: 20 }}>
              <Col xs={12}>
                {this.state.user !== this.state.pageAddr && this.props.match.params.addr && (
                  <h1 className="cut-text cut-text-xxl block-center">
                    Fighters of {this.props.match.params.addr}
                  </h1>
                )}
                <BattleStats marginTop={10} stats={this.state.stats} />
              </Col>
            </Row>
          ) : null}
          {this.state.fighters.length ? (
            <p style={{ fontStyle: 'italic', color: '#9B9B9B' }}>
              {this.state.fighters.length + ' fighters found'}
            </p>
          ) : null}
          {this.getMainContent()}
        </Grid>
      </div>
    )
  }
}

export default MyFightersPage
