import { market as marketTypes } from '../actions/types'

const INIT_MARKET_STATE = {
  page: 0,
  sort: 0,
  gen: 0,
}

const actions = {
  [marketTypes.setMarketPage]: (state, payload) => ({
    ...state,
    page: payload.page,
  }),

  [marketTypes.setMarketSort]: (state, payload) => ({
    ...state,
    sort: payload.sort,
  }),

  [marketTypes.setMarketGen]: (state, payload) => ({
    ...state,
    gen: payload.gen,
  }),
}

const market = (state = INIT_MARKET_STATE, action) => {
  if (actions[action.type]) {
    // console.log('===============', state, actions)
    return actions[action.type](state, action.payload)
  }
  return state
}

export default market
