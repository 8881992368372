import React, { Component } from 'react'
import Document from '../../components/Document'
import DocHeading from '../../components/Document/DocHeading'

class TermsOfService extends Component {
  render() {
    return (
      <Document title="Terms of Service" date="January 21, 2018">
        <p>
          CryptoFighters is a distributed application that runs on the Ethereum network, using
          specially-developed smart contracts (each, a “Smart Contract”) to enable users to own,
          transfer, create, battle with unique digital fighters, which can then be visualized on a
          website that the user can interact with (the “Site”). The Smart Contracts and the Site are
          collectively referred to in these Terms as the “App”. Using the App, users can view their
          fighters and use the Smart Contracts to acquire, trade, and battle with other App users
          via clock auction.
        </p>
        <p>
          CryptoFighters ("CryptoFighters", "we", or "us") is making the App available to you.
          Before you use the App, the Smart Contracts, or the Site, however, you will need to agree
          to these Terms of Use (these "Terms").
        </p>
        <p>
          PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE APP, THE SMART CONTRACTS, OR THE SITE.
          THESE TERMS GOVERN YOUR USE OF THE APP, THE SMART CONTRACTS, AND THE SITE, UNLESS WE HAVE
          EXECUTED A SEPARATE WRITTEN AGREEMENT WITH YOU FOR THAT PURPOSE. WE ARE ONLY WILLING TO
          MAKE THE APP, THE SMART CONTRACTS, AND THE SITE AVAILABLE TO YOU IF YOU ACCEPT ALL OF
          THESE TERMS. BY USING THE APP, THE SMART CONTRACTS, THE SITE, OR ANY PART OF THEM, OR BY
          CLICKING “I ACCEPT” BELOW OR INDICATING YOUR ACCEPTANCE IN AN ADJOINING BOX, YOU ARE
          CONFIRMING THAT YOU UNDERSTAND AND AGREE TO ALL OF THESE TERMS. IF YOU ARE ACCEPTING THESE
          TERMS ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE LEGAL
          AUTHORITY TO ACCEPT THESE TERMS ON THAT ENTITY’S BEHALF, IN WHICH CASE “YOU” WILL MEAN
          THAT ENTITY. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT ACCEPT ALL OF THESE
          TERMS, THEN WE ARE UNWILLING TO MAKE THE APP, THE SMART CONTRACTS, OR THE SITE AVAILABLE
          TO YOU.
        </p>
        <DocHeading>1. Our CryptoFighters</DocHeading>
        <p>
          A. We have utilized Smart Contracts to develop our initial generation of CryptoFighters
          (“Legendary Fighters”). We will release a certain number of Legendary Fighters (and make
          them available for purchase) on the day we first launch the App. We will release an
          additional Legendary Fighters into the wild approximately every 15-30 minutes over course
          of the next year (subject to change), and we will make each newly-released Legendary
          Fighters available for purchase via a Dutch auction. Our Smart Contracts enforce a hard
          limit of 25,000 Legendary Fighters.
        </p>
        <p>
          B. As users fights their CryptoFighters, new and unforeseen generations of CryptoFighters
          will come into existence! Using our proprietary genetic combination algorithm that we have
          designed as part of the App, each newly recruited CryptoFighters will have some of the
          genetic traits of its parents. At the same time, other, new genetic traits may be
          expressed. Unique and surprising traits may be expressed when CryptoFighters fight in the
          right combinations. The Smart Contracts have total control over the pseudo-random elements
          of our fighting process, which means that we cannot manipulate or influence them.
        </p>
        <DocHeading>2. The App</DocHeading>
        <p>
          A. To most easily use the App, you must first install the Google Chrome web browser. Once
          you have installed Chrome, you will need to install a browser extension called MetaMask.
          MetaMask is an electronic wallet, which allows you to purchase (either directly via
          Coinbase if you are in the United States, or via other third party sites), store, and
          engage in transactions using Ethereum cryptocurrency. You will not be able to engage in
          any transactions on the App other than through MetaMask (or other Ethereum-compatible
          browsers). The App will only recognize you as a user, and you will only be able to
          interact with the App, if your Ethereum electronic wallet is connected and unlocked
          through your MetaMask account. There is no other way to sign up as a user, or to interact
          directly with the App.
        </p>
        <p>
          B. Transactions that take place on the App are managed and confirmed via the Ethereum
          blockchain. You understand that your Ethereum public address will be made publicly visible
          whenever you engage in a transaction on the App.
        </p>
        <p>
          C. We neither own nor control MetaMask, Coinbase, Google Chrome, the Ethereum network, or
          any other third party site, product, or service that you might access, visit, or use for
          the purpose of enabling you to use the various features of the App. We will not be liable
          for the acts or omissions of any such third parties, nor will we be liable for any damage
          that you may suffer as a result of interacting with any such third parties.
        </p>
        <p>
          D. It is possible for you to interact with the Smart Contracts we have created directly
          via the Ethereum network, without using the App as a portal. The process for doing so can
          be quite complex, however. If you do not already know how to do it, you’re probably better
          off using the Site.
        </p>
        <p>
          E. You must provide accurate and complete registration information when you create an
          account for the App. You are responsible for the security of your MetaMask wallet (and
          other Ethereum wallets) and for any use of your account, whether you knew about it or not.
          If you become aware of any unauthorized use of your password or of your account, you agree
          to notify us immediately at hello@CryptoFighters.io
        </p>
        <DocHeading>3. Fees and Payment</DocHeading>
        <p>
          A. If you elect to purchase, trade, or fight on the App, or with or from other users via
          the App, any financial transactions that you engage in will be conducted through the
          Ethereum network via MetaMask. We will have no insight into or control over these payments
          or transactions, nor do we have the ability to reverse any transactions. With that in
          mind, we will have no liability to you or to any third party for any claims or damages
          that may arise as a result of any transactions that you engage in via the App, or
          independently using the Smart Contracts.
        </p>
        <p>
          B. Ethereum requires the payment of a transaction fee (a “Gas Fee”) for every transaction
          that occurs on the Ethereum network. The Gas Fee funds the network of computers that run
          the decentralized Ethereum network. This means that, even if you elect to interact
          directly via the Ethereum network without using the App as a portal, you will still need
          to pay a Gas Fee for each transaction that occurs via the Ethereum network.
        </p>
        <p>
          C. In addition to the Gas Fee, each time you utilize a Smart Contract to conduct a
          transaction with another user via the App, you authorize us to collect a commission of
          3.9% of the total value of that transaction (each, a “Commission”). You acknowledge and
          agree that the Commission will be transferred directly to us through the Ethereum network
          as part of your payment. We will not collect a Commission for interactions that do not
          involve our App marketplace.
        </p>
        <p>
          D. As between us, you will be solely responsible to pay any and all sales, use,
          value-added and other taxes, duties, and assessments (except taxes on our net income) now
          or hereafter claimed or imposed by any governmental authority (collectively, “Taxes”)
          associated with your use of the App (including, without limitation, any Taxes that may
          become payable as the result of your ownership, transfer, or fighting of any of your
          CryptoFighters). Except for income taxes levied on CryptoFighters, you: (i) will pay or
          reimburse us for all national, federal, state, local or other taxes and assessments of any
          jurisdiction, including value added taxes and taxes as required by international tax
          treaties, customs or other import or export taxes, and amounts levied in lieu thereof
          based on charges set, services performed or payments made hereunder, as are now or
          hereafter may be imposed under the authority of any national, state, local or any other
          taxing jurisdiction; and (ii) shall not be entitled to deduct the amount of any such
          taxes, duties or assessments from payments made to us pursuant to these Terms.
        </p>
        <DocHeading>4. Ownership; Restrictions</DocHeading>
        <p>
          A. You acknowledge and agree that we (or, as applicable, our licensors) own all legal
          right, title and interest in and to all elements of the App, and all intellectual property
          rights therein. The visual interfaces, graphics (including, without limitation, all art
          and drawings associated with the CryptoFighters), design, systems, methods, information,
          computer code, software, services, “look and feel”, organization, compilation of the
          content, code, data, and all other elements of the App (collectively, the “CryptoFighters
          Assets”) are owned by CryptoFighters, and are protected by copyright, trade dress, patent,
          and trademark laws, international conventions, other relevant intellectual property and
          proprietary rights, and applicable laws. All CryptoFighters Assets are the copyrighted
          property of CryptoFighters or its licensors, and all trademarks, service marks, and trade
          names contained in the CryptoFighters Asset are proprietary to CryptoFighters or its
          licensors. Except as expressly set forth herein, your use of the App does not grant you
          ownership of or any other rights with respect to any content, code, data, or other
          materials that you may access on or through the App. We reserve all rights in and to the
          CryptoFighters Assets not expressly granted to you in the Terms. For the sake of clarity,
          you understand and agree: (i) that your “purchase” of a CryptoFighters, whether via the
          App or otherwise, does not give you any rights or licenses in or to the CryptoFighters
          Assets (including, without limitation, our copyright in and to the art and drawings
          associated with that CryptoFighter) other than those expressly contained in these Terms;
          and (ii) that you do not have the right to reproduce, distribute, or otherwise
          commercialize the CryptoFighters Asset (including, without limitation, our copyright in
          and to the art and drawings associated with that CryptoFighters) in any way without our
          prior written consent in each case, which consent we may withhold in our sole and absolute
          discretion.
        </p>
        <p>
          B. You may choose to submit comments, bug reports, ideas or other feedback about the App,
          including without limitation about how to improve the App (collectively, “Feedback”). By
          submitting any Feedback, you agree that we are free to use such Feedback at our discretion
          and without additional compensation to you, and to disclose such Feedback to third parties
          (whether on a non-confidential basis, or otherwise). You hereby grant us a perpetual,
          irrevocable, nonexclusive, worldwide license under all rights necessary for us to
          incorporate and use your Feedback for any purpose.
        </p>
        <p>
          C. You agree that you are responsible for your own conduct while accessing or using the
          App, and for any consequences thereof. You agree to use the App only for purposes that are
          legal, proper and in accordance with these Terms and any applicable laws or regulations.
          By way of example, and not as a limitation, you may not, and may not allow any third party
          to: (i) send, upload, distribute or disseminate any unlawful, defamatory, harassing,
          abusive, fraudulent, obscene, or otherwise objectionable content; (ii) distribute viruses,
          worms, defects, Trojan horses, corrupted files, hoaxes, or any other items of a
          destructive or deceptive nature; (iii) impersonate another person (via the use of an email
          address or otherwise); (iv) upload, post, transmit or otherwise make available through the
          App any content that infringes the intellectual proprietary rights of any party; (v) use
          the App to violate the legal rights (such as rights of privacy and publicity) of others;
          (vi) engage in, promote, or encourage illegal activity; (vii) interfere with other users’
          enjoyment of the App; (viii) exploit the App for any unauthorized commercial purpose; (ix)
          modify, adapt, translate, or reverse engineer any portion of the App; (x) remove any
          copyright, trademark or other proprietary rights notices contained in or on the App or any
          part of it; (xi) reformat or frame any portion of the App; (xii) display any content on
          the App that contains any hate-related or violent content or contains any other material,
          products or services that violate or encourage conduct that would violate any criminal
          laws, any other applicable laws, or any third party rights; (xiii) use any robot, spider,
          site search/retrieval application, or other device to retrieve or index any portion of the
          App or the content posted on the App, or to collect information about its users for any
          unauthorized purpose; (xiv) create user accounts by automated means or under false or
          fraudulent pretenses; or (xv) access or use the App for the purpose of creating a product
          or service that is competitive with any of our products or services.
        </p>
        <DocHeading>5. Termination</DocHeading>
        <p>
          You may terminate these Terms at any time by canceling your account on the App and
          discontinuing your access to and use of the App. You will not receive any refunds if you
          cancel your account, or otherwise terminate these Terms. You agree that we, in our sole
          discretion and for any or no reason, may terminate these Terms and suspend and/or
          terminate your account(s) for the App. You agree that any suspension or termination of
          your access to the App may be without prior notice, and that we will not be liable to you
          or to any third party for any such suspension or termination. If we terminate these Terms
          or suspend or terminate your access to or use of the App due to your breach of these Terms
          or any suspected fraudulent, abusive, or illegal activity, then termination of these Terms
          will be in addition to any other remedies we may have at law or in equity. Upon any
          termination or expiration of these Terms, whether by you or us, you may no longer have
          access to information that you have posted on the App or that is related to your account,
          and you acknowledge that we will have no obligation to maintain any such information in
          our databases or to forward any such information to you or to any third party. Sections
          2.C and 3 through 14 will survive the termination or expiration of these Terms for any
          reason.
        </p>
        <DocHeading>6. Disclaimer of Warranties</DocHeading>
        <p>
          YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR ACCESS TO AND USE OF THE APP IS AT YOUR SOLE
          RISK, AND THAT THE APP IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY
          KIND, WHETHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE
          LAW, WE, OUR SUBSIDIARIES, AFFILIATES, AND LICENSORS MAKE NO EXPRESS WARRANTIES AND HEREBY
          DISCLAIM ALL IMPLIED WARRANTIES REGARDING THE APP AND ANY PART OF IT (INCLUDING, WITHOUT
          LIMITATION, THE SITE, ANY SMART CONTRACT, OR ANY EXTERNAL WEBSITES), INCLUDING THE IMPLIED
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT,
          CORRECTNESS, ACCURACY, OR RELIABILITY. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING,
          WE, OUR SUBSIDIARIES, AFFILIATES, AND LICENSORS DO NOT REPRESENT OR WARRANT TO YOU THAT:
          (I) YOUR ACCESS TO OR USE OF THE APP WILL MEET YOUR REQUIREMENTS, (II) YOUR ACCESS TO OR
          USE OF THE APP WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR, (III) USAGE DATA
          PROVIDED THROUGH THE APP WILL BE ACCURATE, OR (III) THE APP OR ANY CONTENT, SERVICES, OR
          FEATURES MADE AVAILABLE ON OR THROUGH THE APP ARE FREE OF VIRUSES OR OTHER HARMFUL
          COMPONENTS.
        </p>
        <DocHeading>7. Limitation of Liability</DocHeading>
        <p>
          A. YOU UNDERSTAND AND AGREE THAT WE, OUR SUBSIDIARIES, AFFILIATES, AND LICENSORS WILL NOT
          BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
          CONSEQUENTIAL, OR EXEMPLARY DAMAGES WHICH YOU MAY INCUR, HOWSOEVER CAUSED AND UNDER ANY
          THEORY OF LIABILITY, INCLUDING, WITHOUT LIMITATION, ANY LOSS OF PROFITS (WHETHER INCURRED
          DIRECTLY OR INDIRECTLY), LOSS OF GOODWILL OR BUSINESS REPUTATION, LOSS OF DATA, COST OF
          PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR ANY OTHER INTANGIBLE LOSS, EVEN IF WE HAVE
          BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </p>
        <p>
          B. YOU AGREE THAT OUR TOTAL, AGGREGATE LIABILITY TO YOU FOR ANY AND ALL CLAIMS ARISING OUT
          OF OR RELATING TO THESE TERMS OR YOUR ACCESS TO OR USE OF (OR YOUR INABILITY TO ACCESS OR
          USE) ANY PORTION OF THE APP, WHETHER IN CONTRACT, TORT, STRICT LIABILITY, OR ANY OTHER
          LEGAL THEORY, IS LIMITED TO THE GREATER OF (A) THE AMOUNTS YOU ACTUALLY PAID US UNDER
          THESE TERMS IN THE 12 MONTH PERIOD PRECEDING THE DATE THE CLAIM AROSE, OR (B) $100.
        </p>
        <p>
          C. YOU ACKNOWLEDGE AND AGREE THAT WE HAVE MADE THE APP AVAILABLE TO YOU AND ENTERED INTO
          THESE TERMS IN RELIANCE UPON THE WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY SET
          FORTH HEREIN, WHICH REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN THE PARTIES
          AND FORM AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN US. WE WOULD NOT BE ABLE TO PROVIDE THE
          APP TO YOU WITHOUT THESE LIMITATIONS.
        </p>
        <DocHeading>8. Indemnification</DocHeading>
        <p>
          You agree to hold harmless and indemnify CryptoFighters and its subsidiaries, affiliates,
          officers, agents, employees, advertisers, licensors, suppliers or partners from and
          against any claim, liability, loss, damage (actual and consequential) of any kind or
          nature, suit, judgment, litigation cost, and attorneys’ fees arising out of or in any way
          related to (i) your breach of these Terms, or (ii) your violation of applicable laws,
          rules or regulations in connection with your access to or use of the App.
        </p>
        <DocHeading>9. External Sites</DocHeading>
        <p>
          The App may include hyperlinks to other web sites or resources (collectively, “External
          Sites”), which are provided solely as a convenience to our users. We have no control over
          any External Sites. You acknowledge and agree that we are not responsible for the
          availability of any External Sites, and that we do not endorse any advertising, products
          or other materials on or made available from any External Sites. Furthermore, you
          acknowledge and agree that we are not liable for any loss or damage which may be incurred
          as a result of the availability or unavailability of the External Sites, or as a result of
          any reliance placed by you upon the completeness, accuracy or existence of any
          advertising, products or other materials on, or made available from, any External Sites.
        </p>
        <DocHeading>10. Changes to the Terms</DocHeading>
        <p>
          We may make changes to the Terms from time to time. When we make changes, we will make the
          updated Terms available on the App and update the “Last Updated” date at the beginning of
          these Terms accordingly. Please check these Terms periodically for changes. Any changes to
          the Terms will apply on the date that they are made, and your continued access to or use
          of the App after the Terms have been updated will constitute your binding acceptance of
          the updates.
        </p>
        <DocHeading>11. Changes to the App</DocHeading>
        <p>
          We are constantly innovating the App to help provide the best possible experience. You
          acknowledge and agree that the form and nature of the App, and any part of it, may change
          from time to time without prior notice to you, and that we may add new features and change
          any part of the App at any time without notice.
        </p>
        <DocHeading>12. Children</DocHeading>
        <p>
          You affirm that you are over the age of 13, as the App is not intended for children under
          13. IF YOU ARE 13 OR OLDER BUT UNDER THE AGE OF 18, OR THE LEGAL AGE OF MAJORITY WHERE YOU
          RESIDE IF THAT JURISDICTION HAS AN OLDER AGE OF MAJORITY, THEN YOU AGREE TO REVIEW THESE
          TERMS WITH YOUR PARENT OR GUARDIAN TO MAKE SURE THAT BOTH YOU AND YOUR PARENT OR GUARDIAN
          UNDERSTAND AND AGREE TO THESE TERMS. YOU AGREE TO HAVE YOUR PARENT OR GUARDIAN REVIEW AND
          ACCEPT THESE TERMS ON YOUR BEHALF. IF YOU ARE A PARENT OR GUARDIAN AGREEING TO THE TERMS
          FOR THE BENEFIT OF A CHILD OVER 13, THEN YOU AGREE TO AND ACCEPT FULL RESPONSIBILITY FOR
          THAT CHILD’S USE OF THE APP, INCLUDING ALL FINANCIAL CHARGES AND LEGAL LIABILITY THAT HE
          OR SHE MAY INCUR.
        </p>
        <DocHeading>13. Privacy Policy</DocHeading>
        <p>
          Our Privacy Policy describes the ways we collect, use, store and disclose your personal
          information, and is hereby incorporated by this reference into these Terms. You agree to
          the collection, use, storage, and disclosure of your data in accordance with our Privacy
          Policy.
        </p>
        <DocHeading>14. General</DocHeading>
        <p>
          These Terms constitute the entire legal agreement between you and CryptoFighters, govern
          your access to and use of the App, and completely replace any prior or contemporaneous
          agreements between the parties related to your access to or use of the App, whether oral
          or written. There are no third party beneficiaries to these Terms. The parties are
          independent contractors, and nothing in these Terms create any agency, partnership, or
          joint venture. The language in these Terms will be interpreted as to its fair meaning, and
          not strictly for or against any party. You may not assign any or your rights or
          obligations under these Terms, whether by operation of law or otherwise, without our prior
          written consent. We may assign our rights and obligations under these Terms in our sole
          discretion to an affiliate, or in connection with an acquisition, sale or merger. Should
          any part of these Terms be held invalid or unenforceable, that portion shall be construed
          consistent with applicable law and the remaining portions will remain in full force and
          effect. Our failure to enforce any provision of these Terms will not be deemed a waiver of
          such provision, nor of the right to enforce such provision. These Terms will be governed
          by and construed in accordance with the laws of the province of the state of Delaware,
          United States of America, excluding its conflicts of law rules and principles. Any legal
          action or proceeding arising under these Terms will be brought exclusively in the federal
          or provincial courts located in Delaware, United States of America, and the parties
          irrevocably consent to the personal jurisdiction and venue there. We will not be liable
          for any failure or delayed performance of our obligations that result from any condition
          beyond our reasonable control, including, but not limited to, governmental action, acts of
          terrorism, earthquake, fire, flood, acts of God, labor conditions, power failures,
          Internet disturbances, or acts or omissions of third parties. You agree that we may
          provide you with notices (including, without limitation those regarding changes to these
          Terms) by email, regular mail, or postings on the App. By providing us with your email
          address, you consent to our using the email address to send you any notices required by
          law in lieu of communication by postal mail.
        </p>
      </Document>
    )
  }
}

export default TermsOfService
