import React from 'react'

const styles = {
  container: {
    position: 'absolute',
    bottom: 15,
  },
  stat: {
    margin: 0,
    textAlign: 'left',
    fontSize: 15,
    fontWeight: 500,
  },
}

const FighterStats = ({ stats, colors, right }) => (
  <div style={{ ...styles.container, left: right ? null : 15, right: right ? 15 : null }}>
    {stats.map((stat, i) => (
      <p key={i} style={styles.stat}>
        <span style={{ color: colors[0] }}>{stat.symbol}</span>{' '}
        <span style={{ color: colors[1] }}>{stat.amount}</span>
      </p>
    ))}
  </div>
)

export default FighterStats
