import React from 'react'
import PropTypes from 'prop-types'
import { FIGHTER_COLORS } from '../../consts'

const Info = ({ color, className }) => (
  <div
    className={`infoIcon ${className}`}
    style={{ cursor: 'pointer', position: 'relative', top: 1 }}
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
      <g fill={color} fillRule="nonzero">
        <path d="M6 0C2.7 0 0 2.7 0 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6zm0 10.5A4.513 4.513 0 0 1 1.5 6c0-2.475 2.025-4.5 4.5-4.5s4.5 2.025 4.5 4.5-2.025 4.5-4.5 4.5z" />
        <path d="M5.25 5.25h1.5V9h-1.5z" />
        <circle cx="6" cy="3.75" r="1" />
      </g>
    </svg>
  </div>
)

Info.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
}

Info.defaultProps = {
  className: '',
  color: FIGHTER_COLORS[2].star,
}

export default Info
