import React from 'react'

const styles = {
  container: {
    margin: '0px 4px',
    display: 'inline-block',
  },
}

const Star = ({ color, size }) => (
  <div style={styles.container}>
    <svg
      width={size + 'px'}
      height={size + 'px'}
      viewBox="0 0 10 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="003" transform="translate(-370.000000, -366.000000)" fillRule="nonzero" fill={color}>
          <g id="Group-2" transform="translate(298.000000, 366.000000)">
            <path
              d="M81.4440898,3.19470676 L78.8364922,3.19470676 L77.5038065,0.321571887 C77.4127492,0.125459433 77.2161734,0 76.9999524,0 C76.7837314,0 76.5871556,0.125459433 76.4960983,0.321571887 L75.1634126,3.19470676 L72.5558149,3.19470676 C72.3260992,3.19459303 72.1200073,3.33588095 72.0372885,3.55018661 C71.9545698,3.76449227 72.012288,4.00760695 72.1825074,4.16186218 L74.3956878,6.16672369 L73.5029717,9.29150785 C73.4390507,9.51550786 73.52167,9.75559761 73.7098974,9.89282864 C73.8981248,10.0300597 74.1519786,10.0352826 74.3456913,9.90590986 L76.9999524,8.13658763 L79.6542135,9.90590986 C79.8481825,10.0348467 80.1018848,10.0295289 80.2902806,9.89257744 C80.4783029,9.75531264 80.5607961,9.51537278 80.496933,9.29150785 L79.6042169,6.16672369 L81.8173974,4.16186218 C81.9879412,4.00687288 82.0451594,3.76466739 81.9629429,3.55023776 C81.8801977,3.33580051 81.6739377,3.194466 81.4440898,3.19470676 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
)

export default Star
