import React from 'react'
import PropTypes from 'prop-types'

const BattleIcon = ({ color, height, width }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12 12">
    <g fill={color} fillRule="nonzero">
      <path d="M6 10c-1.077 0-2.064-.44-2.811-1.189L5 7H0v5l1.761-1.761A5.937 5.937 0 0 0 6 12c3.309 0 6-2.69 6-6h-2c0 2.206-1.794 4-4 4zM10.24 1.761A5.941 5.941 0 0 0 6 0C2.691 0 0 2.691 0 6h2c0-2.206 1.794-4 4-4 1.078 0 2.065.44 2.811 1.188L7 5h5V0l-1.76 1.761z" />
    </g>
  </svg>
)

BattleIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
}

BattleIcon.defaultProps = {
  width: 15,
  height: 15,
  color: 'color',
}

export default BattleIcon
