import React from 'react'
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
})

const StepButton = ({ disabled, onClick, classes, children }) => (
  <Button disabled={disabled} color="primary" className={classes.button} onClick={onClick}>
    {children}
  </Button>
)

export default withStyles(styles)(StepButton)
