import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import Stats from './Stats'
import Level from './Level'
import Genes from './Genes'
import MainAction from '../buttons/MainAction'
import FighterDisplay from './FighterDisplay'
import BuySection from './BuySection'
import Owner from './Owner'
import {
  getLevel,
  genesToRace,
  generationToStars,
  generationToRank
 } from '../../utils/fighter'
import { RACE_NAMES } from '../../consts'
import BattleStats from '../../components/BattleStats'
import RecruitingCooldown from '../cooldowns/RecruitingCooldown'
import './styles.css'

const styles = {
  container: {
    textAlign: 'center',
  },
  spacer: {
    height: 200,
  },
  link: {
    textDecoration: 'none',
  },
  bidButton: {
    color: '#fff',
    backgroundColor: '#000',
    width: 200,
    fontSize: 20,
    borderRadius: 16,
    textAlign: 'center',
    cursor: 'pointer',
  },
}

class ViewFighter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stats: props,
    }
  }

  renderLeftSection() {
    const {
      strength,
      luck,
      dexterity,
      vitality,
      color,
      experience,
      prizeCooldownIndex,
      timeTillBattleCooldown,
    } = this.props

    const { level, maxExp, minExp } = getLevel(experience)

    const MAX_STAT = 50

    return (
      <div className="fighter-side-section-wrapper">
        <Stats
          stats={[
            { stat: 'STR', amount: strength, max: MAX_STAT },
            { stat: 'VIT', amount: vitality, max: MAX_STAT },
            { stat: 'DEX', amount: dexterity, max: MAX_STAT },
            { stat: 'LUK', amount: luck, max: MAX_STAT },
          ]}
          color={color.primary}
        />

        <RecruitingCooldown
          cooldownIndex={prizeCooldownIndex}
          cooldownEndTime={timeTillBattleCooldown}
          color={color.primary}
        />

        <div className="level-section">
          <Level color={color.primary} level={level} exp={experience} maxExp={maxExp} minExp={minExp} />
        </div>
      </div>
    )
  }

  renderMiddleSection() {
    const {
      // genes,
      genesHex,
      generation,
      image,
      color,
      experience,
      timeTillPrizeCooldown,
      timeTillBattleCooldown,
    } = this.props;

    const { maxExp, minExp } = getLevel(experience)
    return (
      <div>
        <FighterDisplay
          experience={experience}
          maxExperience={maxExp}
          minExperience={minExp}
          name={RACE_NAMES[genesToRace(genesHex)]}
          color={color}
          img={image}
          stars={generationToStars(generation)}
          rank={generationToRank(generation)}
          generation={generation}
          isShowName={false}
          isShowTooltip={false}
          isShowRank={false}
          timeTillPrizeCooldown={timeTillPrizeCooldown}
          timeTillBattleCooldown={timeTillBattleCooldown}
        />
      </div>
    )
  }

  renderMiddleBottomSection(isXs) {
    const {
      id,
      color,
      isOwner,
      auction,
      currentPrice,
      currentPriceEth,
      placeBid,
      bidLoading,
      bidPlaced,
      cancelAuction,
      disableChart,
    } = this.props;

    return (
      <div>
        {isOwner && !auction ? (
          <div style={{ position: 'relative', bottom: 35, display: 'flex', justifyContent: 'center' }}>
            <Link to={`/fighter/${id}/sell`} style={styles.link}>
              <MainAction color={color.primary}>SELL FIGHTER</MainAction>
            </Link>
          </div>
        ) : null}
        {auction && currentPrice && !bidPlaced ? (
          <div style={{ marginTop: 35 }}>
            <BuySection
              primaryColor={color.primary}
              secondaryColor={color.secondary}
              {...auction}
              currentPrice={currentPrice}
              currentPriceEth={currentPriceEth}
              onClick={placeBid}
              onClickCancel={cancelAuction}
              bidLoading={bidLoading}
              isOwner={isOwner}
              xs={isXs}
              disableChart={disableChart}
            />
          </div>
        ) : null}
        <div style={{ height: 100 }} />
      </div>
    )
  }

  renderRightSection(isXS) {
    const { color, owner, attr } = this.props
    // const marginTop = isXS ? 50 : 100;
    return (
      <div className="fighter-side-section-wrapper">
        <div
          style={{
            position: 'relative',
            display: 'inline-block',
            verticalAlign: 'top',
            marginTop: 0,
          }}
        >
          <Genes color={color.primary} genes={attr} />
        </div>
        <div className="fighter-side-inner-wrapper">
          <Owner color={color.primary} username={owner} />
        </div>
      </div>
    )
  }

  render() {
    const {
      genesHex,
      generation,
      image,
      color,
      stats,
      experience
    } = this.props;
    const { maxExp } = getLevel(experience)

    return (
      <div className="fighter-details-container" style={styles.container}>
        <Row>
          <Col xs={12}>
            <div className="fighter-details-header">
              <FighterDisplay
                experience={experience}
                maxExperience={maxExp}
                name={RACE_NAMES[genesToRace(genesHex)]}
                color={color}
                img={image}
                stars={generationToStars(generation)}
                rank={generationToRank(generation)}
                generation={generation}
                isShowFighter={false}
              />
              {stats ? (
                <BattleStats marginTop={10} stats={stats} />
              ) : null}
            </div>
          </Col>
        </Row>
        <Row className="flex-sm">
          <Col sm={6} smPush={3}>{this.renderMiddleSection()}</Col>
          <Col xs={12} sm={3} smPull={6}>
            {this.renderLeftSection()}
          </Col>
          <Col sm={3} xsHidden>{this.renderRightSection()}</Col>
          <Col xs={12} sm={3} smHidden mdHidden lgHidden>{this.renderRightSection(true)}</Col>
        </Row>
        <Row>
          <Col xs={12} md={6} mdPush={3}>{this.renderMiddleBottomSection(true)}</Col>
        </Row>
      </div>
    )
  }
}

ViewFighter.propTypes = {
  id: PropTypes.number,
  genes: PropTypes.string,
  dexterity: PropTypes.number,
  strength: PropTypes.number,
  vitality: PropTypes.number,
  luck: PropTypes.number,
  gen: PropTypes.number,
  color: PropTypes.object,
  owner: PropTypes.string,
}

ViewFighter.defaultProps = {
  color: {},
}

export default ViewFighter
