import { fights as fightsTypes } from './types'

const addFighterToTeam = (fighterId, isLeader) => ({
  type: fightsTypes.addFighterToTeam,
  payload: { fighterId },
})

const removeFighterFromTeam = (fighterId, isLeader) => ({
  type: fightsTypes.removeFighterFromTeam,
  payload: { fighterId },
})

const removingTeamFromArena = (teamId, undo) => ({
  type: fightsTypes.removingTeamFromArena,
  payload: { teamId, undo },
})

const setTeam = team => ({
  type: fightsTypes.setTeam,
  payload: { team },
})

const setTeams = teams => ({
  type: fightsTypes.setTeams,
  payload: { teams },
})

const enterTeamInArena = teamId => ({
  type: fightsTypes.enterTeamInArena,
  payload: { teamId },
})

const removeTeamFromArena = teamId => ({
  type: fightsTypes.removeTeamFromArena,
  payload: { teamId },
})

const resetTempTeam = () => ({
  type: fightsTypes.resetTempTeam,
})

export {
  addFighterToTeam,
  removeFighterFromTeam,
  setTeam,
  setTeams,
  enterTeamInArena,
  removeTeamFromArena,
  removingTeamFromArena,
  resetTempTeam,
}
